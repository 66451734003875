<template>
  <v-dialog eager v-model="dialog" max-width="600">
    <v-card>
      <v-card-title>
        Crear Examenes de forma masiva
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-select
            clearable
            multiple
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carreras"
            :items="carreras"
            item-text="nombre"
            item-value="id"
            :onemptied="null"
          />
          <v-autocomplete
            v-if="!model.carreras"
            clearable
            multiple
            placeholder="Seleccione Materia"
            outlined
            v-model="model.materias"
            :items="uCurricularCarreras"
            item-text="searchTag"
            item-value="id"
          >
          </v-autocomplete>
          <v-text-field
            outlined
            label="Ingrese Turno Examen"
            v-model="model.turno"
          ></v-text-field>
          <v-text-field
            clearable
            outlined
            label="Ingrese Fecha del Examen"
            type="date"
            v-model="model.date"
            :onemptied="null"
          ></v-text-field>
          <v-text-field
            outlined
            label="Ingrese Hora del Examen"
            type="time"
            v-model="model.hora"
            :onemptied="null"
          ></v-text-field>

          <v-text-field
            outlined
            label="Ingrese Fecha de Inicio de inscripción"
            type="date"
            v-model="model.fecha_inicio_inscripcion"
          ></v-text-field>
          <v-text-field
            outlined
            label="Ingrese Fecha del Fin de la inscripción"
            type="date"
            v-model="model.fecha_fin_inscripcion"
          ></v-text-field>
          <v-switch
            :true-value="1"
            :false-value="0"
            v-model="model.estado"
            :label="model.estado ? 'Activo' : 'Inactivo'"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BulkExamenForm",
  data() {
    return {
      model: {},
      dialog: false,
    };
  },
  computed: {
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("UCurricularCarreraModule", ["materiasPorCarrera"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("ExamenModule", ["errors"]),
    ...mapState("ExamenModule", ["fields", "examen", "record"]),

    materiasPorCarreraFiltered() {
      return this.materiasPorCarrera.filter(
        (el) => el.nombre == this.searchMateria
      );
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    // ...mapActions("UCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("ExamenModule", [
      "getFields",
      "addBulkExamen",
      "getExamen",
      "updateExamen",
      "addExamen",
    ]),

    async guardar() {
      this.addBulkExamen(this.model);
    },

    cerrar() {
      (this.model = {}), (this.dialog = false), this.$emit("close");
    },
  },
  watch: {
    "model.date": function () {
      if (this.model.date) {
        this.model.fecha = this.model.date + " " + this.model.hora;
      } else {
        this.model.fecha = null;
      }
    },
    "model.hora": function () {
      if (this.model.hora) {
        this.model.fecha = this.model.date + " " + this.model.hora;
      } else {
        this.model.fecha = null;
      }
    },
  },
  created() {
    this.getCarreras();
    this.getFields();
    this.getUCurricularCarreras({});
    // if (this.id) {
    //   this.getModel();
    // }
  },
};
</script>

<style scoped></style>
