var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","eager":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('user-form',{ref:"form",attrs:{"id":_vm.id},on:{"close":_vm.cerrar},scopedSlots:_vm._u([{key:"password",fn:function(ref){
var model = ref.model;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Ingrese una contraseña","type":"password"},model:{value:(model.password),callback:function ($$v) {_vm.$set(model, "password", $$v)},expression:"model.password"}})]}}])})],1),_c('v-card',[_c('v-card-title',[_vm._v("Users")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"show-select":"","loading":_vm.loading.status,"items":_vm.users,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_vm._l((_vm.headers),function(filterCol,i){return _c('td',{key:i},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(filterCol.filterable),expression:"filterCol.filterable"}],attrs:{"hide-details":"","filled":"","label":'Buscar por ' + filterCol.text,"clearable":""},on:{"click:clear":_vm.reload},model:{value:(
                  _vm.like[filterCol.table ? filterCol.table : filterCol.value]
                ),callback:function ($$v) {_vm.$set(_vm.like, filterCol.table ? filterCol.table : filterCol.value, $$v)},expression:"\n                  like[filterCol.table ? filterCol.table : filterCol.value]\n                "}})],1)})],2)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.carrerasInscripto",fn:function(ref){
                var item = ref.item;
return _vm._l((item.carrerasInscripto),function(carrera){return _c('div',{key:carrera.id},[_vm._v(" "+_vm._s(carrera.codigo)+" ")])})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }