<template>
  <div>
    <div v-for="(label, field) in filterFields" :key="field">
      <slot :name="field" v-bind:ingresante="ingresante">
        <v-text-field
          :label="'Ingrese ' + label"
          v-model="ingresante[field]"
          :error-messages="errors[field]"
        ></v-text-field>
      </slot>
    </div>
    <slot name="field"></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "IngresanteFormCursado",
  props: {
    ingresanteEdit: Object,
    only: {
      type: Array,
      default: () => {
        return [];
      },
    },
    exclude: {
      type: Array,
      default: () => {
        return [
          "id",
          "cursado_id",
          "examen_id",
          "rol",
          "tipo_inscripcion",
          "matriculado",
          "fecha_ingreso",
          "tiene_usuario",
        ];
      },
    },
  },
  data() {
    return {
      ingresante: { tipo_inscripcion: "cursado" },
    };
  },
  computed: {
    ...mapState("IngresanteModule", ["emptyModel", "fields"]),
    ...mapGetters("IngresanteModule", ["errors"]),
    filterFields() {
      let fields = {};
      if (this.only.length > 0) {
        for (var j in this.only) {
          let attr = this.only[j] in this.fields;
          if (attr) {
            fields[this.only[j]] = this.fields[this.only[j]];
          }
        }
        return fields;
      }
      if (this.exclude.length > 0) {
        for (var i in this.fields) {
          let attr = this.exclude.find((el) => el == i);
          if (!attr) {
            fields[i] = this.fields[i];
          }
        }
        return fields;
      }
      return this.fields;
    },
  },
  watch: {
    ingresanteEdit() {
      this.ingresante = this.ingresanteEdit;
    },
  },
  mounted() {
    if (this.ingresanteEdit) {
      this.ingresante = this.ingresanteEdit;
    }
  },
};
</script>

<style scoped></style>
