<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div>Promocionados</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-btn link>cargar promocionados</v-btn>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 500],
          }"
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCursados"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:u_curricular_carrera.nombre="{}">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="uCurricularCarreras"
                  v-model="filters['cursado.espacio_id']"
                  item-value="id"
                  item-text="searchTag"
                ></v-autocomplete>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.alumno.telefono"></whatsapp-button>
          </template>

          <template v-slot:item.finales="{ item }">
            <div v-for="final in item.finales" :key="final.id">
              <span>{{ final.examen.turno }}</span>
              <v-spacer></v-spacer>
              <span>{{ final.examen.fechaFormateada }}</span>
            </div>
          </template>
          <!--          <template v-slot:item.alumno_cursado.created_at="{ item }">-->
          <!--            {{ item.creado }}-->
          <!--          </template>-->
          <!--          <template v-slot:item.alumno_cursado.id="{ item }">-->
          <!--            {{ item.id }}-->
          <!--          </template>-->
          <template v-slot:item.u_curricular_carrera.nombre="{ item }">
            {{ item.cursado.espacio.searchTag }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
// import AlumnoCursadoForm from "@/viewsNew/alumno-cursado/AlumnoCursadoForm";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";
// import HorariosSuperpuestos from "@/viewsNew/alumno-cursado/HorariosSuperpuestos";
// import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";

export default {
  name: "AlumnoCursadoABM",
  props: {
    cursado_id: [String, Number],
  },
  components: {
    // CalendarioHorario,
    // HorariosSuperpuestos,
    WhatsappButton,
    // AlumnoCursadoForm,
    FilterRow,
  },
  data() {
    return {
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        // itemsPerPage:-1,
        joins: ["alumno", "notaAlumnoCursado2", "cursado"],
      },
      expand: [
        "finales",
        // "userCampus",
        "alumno",
        "cursado",
        // "cursadosSuperpuestos",
        "user",
        // "otrosCursados",
      ],
      filters: {
        condicion: "promocionado",
      },
    };
  },
  computed: {
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("NewCursadoModule", ["cursado"]),
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filename() {
      return (
        this.cursado.espacio.searchTag + "-comision_" + this.cursado.comision
      );
    },

    // campus() {
    //   const data = [];
    //   for (var i in this.alumnoCursados) {
    //     let alumno = this.alumnoCursados[i];
    //     var registro = {
    //       username: alumno.userCampus.usuario,
    //     };
    //     data.push(registro);
    //   }
    //
    //   return data;
    // },

    // excel() {
    //   const data = [];
    //   for (var i in this.alumnoCursados) {
    //     let alumno = this.alumnoCursados[i];
    //     var registro = {
    //       id: alumno.id,
    //       creado: alumno.creado,
    //       nombre: alumno.alumno.nombre,
    //       apellido: alumno.alumno.apellido,
    //       email: alumno.alumno.email,
    //       dni: alumno.alumno.dni,
    //       telefono: alumno.alumno.telefono,
    //       superpuestos: alumno.cursadosSuperpuestos.length,
    //       // carrera: alumno.carrera.codigo,
    //       rol: alumno.alumno.roles,
    //       cursado: alumno.cursado.espacio.searchTag,
    //     };
    //     data.push(registro);
    //   }
    //   return data;
    // },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        // { text: "User Campus", value: "userCampus.usuario", filterable: true },
        // { text: "Email Campus", value: "userCampus.email", filterable: true },
        // {
        //   text: "Creado",
        //   value: "alumno_cursado.created_at",
        //   filterable: true,
        // },
        {
          text: "Espacio",
          value: "u_curricular_carrera.nombre",
          filterable: false,
          nosearch: true,
        },
        { text: "Apellido", value: "user.apellido", filterable: true },
        { text: "Nombre", value: "user.nombre", filterable: true },
        { text: "Finales", value: "finales", filterable: true },
        // { text: "Email", value: "user.email", filterable: true },
        // { text: "Telefono", value: "user.telefono", filterable: true },
        // { text: "Roles", value: "user.roles", filterable: true },
        // {
        //   text: "Superpuesto",
        //   value: "superpuesto",
        //   filterable: false,
        //   nosearch: true,
        // },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("NewCursadoModule", ["getCursado"]),
    ...mapActions("NewAlumnoCursadoModule", [
      "getAlumnoCursados",
      "deleteAlumnoCursado",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCursado(id);
      }
    },

    async reload() {
      // this.getCursado(this.cursado_id);
      this.loading.status = true;
      this.filters.cursado_id = this.cursado_id;
      await this.getAlumnoCursados({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
    verSuperpuestos(item) {
      // this.$refs.superpuestos.dialog = true;
      // this.$refs.superpuestos.cursados = item.cursadosSuperpuestos;
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = [
        item.cursado,
        ...item.cursadosSuperpuestos,
      ];
    },
    verHorarios(item) {
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = item.otrosCursados;
    },
  },
  created() {
    this.getUCurricularCarreras({
      options: { itemsPerPage: -1 },
    });
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
