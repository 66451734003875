var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.cursado.espacio.searchTag))]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('v-select',{staticClass:"mr-1",attrs:{"dense":"","label":"Condición","hide-details":"","outlined":"","items":_vm.condicion},on:{"change":function($event){return _vm.changeItem(_vm.item.notaAlumnoCursado)}},model:{value:(_vm.opcion),callback:function ($$v) {_vm.opcion=$$v},expression:"opcion"}})],1),_c('div',{staticClass:"mx-1"},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.cambiarTodos}},[_vm._v("ejecutar")])],1),_c('div',{staticClass:"mx-1"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.getPlanillas}},[_vm._v("Imprimir")])],1),_c('div',{staticClass:"mx-1"},[_c('v-switch',{staticClass:"mt-0",attrs:{"true-value":1,"false-value":0,"label":"cerrar"},model:{value:(_vm.cerrar),callback:function ($$v) {_vm.cerrar=$$v},expression:"cerrar"}})],1)]),_c('v-data-table',{attrs:{"show-select":"","items":_vm.alumnoCursados,"headers":_vm.headers,"items-per-page":-1,"sort-by":_vm.sort},on:{"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event}},scopedSlots:_vm._u([{key:"item.condicion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col p-0"},[_c('v-select',{staticClass:"mr-1",attrs:{"clearable":"","error":!!item.error,"error-messages":item.error,"disabled":_vm.esDisabled(item),"dense":"","label":"Condición","outlined":"","items":_vm.condicion},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.condicion),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "condicion", $$v)},expression:"item.notaAlumnoCursado.condicion"}})],1),(item.notaAlumnoCursado.condicion == 'abandono')?_c('div',{staticClass:"col p-0"},[_c('v-text-field',{attrs:{"error":!!item.errorAbandono,"error-messages":item.errorAbandono,"disabled":_vm.esDisabled(item),"dense":"","label":"fecha abandono","outlined":"","type":"date"},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.fecha_abandono),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "fecha_abandono", $$v)},expression:"item.notaAlumnoCursado.fecha_abandono"}})],1):_vm._e(),(item.notaAlumnoCursado.condicion == 'promocionado')?_c('div',{staticClass:"col p-0"},[_c('v-select',{attrs:{"error":!!item.errorPromocion,"error-messages":item.errorPromocion,"disabled":_vm.esDisabled(item),"dense":"","label":"Nota","outlined":"","items":_vm.notas},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.nota_promocion),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "nota_promocion", $$v)},expression:"item.notaAlumnoCursado.nota_promocion"}})],1):_vm._e()])]}},{key:"item.observaciones",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('v-switch',{attrs:{"disabled":_vm.esDisabled(item),"dense":""},on:{"change":function($event){return _vm.switchObservaciones(item)}},model:{value:(item.notaAlumnoCursado.observaciones),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "observaciones", $$v)},expression:"item.notaAlumnoCursado.observaciones"}})],1),(_vm.mostrarObservaciones(item))?_c('div',[_c('v-textarea',{attrs:{"disabled":_vm.esDisabled(item),"dense":"","hide-details":"","outlined":"","label":"Observaciones"},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.observaciones),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "observaciones", $$v)},expression:"item.notaAlumnoCursado.observaciones"}})],1):_vm._e()])]}},{key:"item.cerrado",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"true-value":1,"false-value":0,"label":"cerrar"},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.cerrado),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "cerrado", $$v)},expression:"item.notaAlumnoCursado.cerrado"}})]}},{key:"item.notificado",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"true-value":1,"false-value":0,"label":"notificado"},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoCursado)}},model:{value:(item.notaAlumnoCursado.notificado),callback:function ($$v) {_vm.$set(item.notaAlumnoCursado, "notificado", $$v)},expression:"item.notaAlumnoCursado.notificado"}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }