<template>
  <div>
    <horarios-superpuestos ref="superpuestos"></horarios-superpuestos>

    <calendario-horario ref="calendario"></calendario-horario>

    <alumno-cursado-form
      ref="form"
      :id="id"
      @close="cerrar"
    ></alumno-cursado-form>
    <v-card>
      <v-card-title>
        <div>
          <div>Espacio: {{ cursado.espacio.searchTag }}</div>
          <div>Comision: {{ cursado.comision }}</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
          <v-col>
            <div>
              <download-excel :name="filename" :data="excel">
                <v-btn dark color="success">Exportar</v-btn>
              </download-excel>
            </div>
          </v-col>
          <!--          <v-col>-->
          <!--            <div>-->
          <!--              <download-excel :data="campus">-->
          <!--                <v-btn dark color="primary">Exportar Campus</v-btn>-->
          <!--              </download-excel>-->
          <!--            </div>-->
          <!--          </v-col>-->
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 500],
          }"
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCursados"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.alumno.telefono"></whatsapp-button>
          </template>

          <template v-slot:item.superpuesto="{ item }">
            <div v-if="item.cursadosSuperpuestos.length > 0">
              <v-btn @click="verSuperpuestos(item)" color="error">SI</v-btn>
            </div>
            <div v-else>
              <v-btn @click="verHorarios(item)" color="success">No</v-btn>
            </div>
          </template>
          <template v-slot:item.alumno_cursado.created_at="{ item }">
            {{ item.creado }}
          </template>
          <template v-slot:item.alumno_cursado.id="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:item.u_curricular_carrera.nombre="{ item }">
            {{ item.cursado.espacio.searchTag }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
import AlumnoCursadoForm from "@/viewsNew/alumno-cursado/AlumnoCursadoForm";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";
import HorariosSuperpuestos from "@/viewsNew/alumno-cursado/HorariosSuperpuestos";
import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";

export default {
  name: "AlumnoCursadoABM",
  props: {
    cursado_id: [String, Number],
  },
  components: {
    CalendarioHorario,
    HorariosSuperpuestos,
    WhatsappButton,
    AlumnoCursadoForm,
    FilterRow,
  },
  data() {
    return {
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        // itemsPerPage:-1,
        joins: ["alumno"],
      },
      expand: [
        // "userCampus",
        "alumno",
        "cursado",
        "cursadosSuperpuestos",
        "user",
        "otrosCursados",
      ],
      filters: {},
    };
  },
  computed: {
    ...mapState("NewCursadoModule", ["cursado"]),
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filename() {
      return (
        this.cursado.espacio.searchTag + "-comision_" + this.cursado.comision
      );
    },

    // campus() {
    //   const data = [];
    //   for (var i in this.alumnoCursados) {
    //     let alumno = this.alumnoCursados[i];
    //     var registro = {
    //       username: alumno.userCampus.usuario,
    //     };
    //     data.push(registro);
    //   }
    //
    //   return data;
    // },

    excel() {
      const data = [];
      for (var i in this.alumnoCursados) {
        let alumno = this.alumnoCursados[i];
        var registro = {
          id: alumno.id,
          creado: alumno.creado,
          nombre: alumno.alumno.nombre,
          apellido: alumno.alumno.apellido,
          email: alumno.alumno.email,
          dni: alumno.alumno.dni,
          telefono: alumno.alumno.telefono,
          superpuestos: alumno.cursadosSuperpuestos.length,
          // carrera: alumno.carrera.codigo,
          rol: alumno.alumno.roles,
          cursado: alumno.cursado.espacio.searchTag,
        };
        data.push(registro);
      }
      return data;
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "alumno_cursado.id", filterable: true },
        // { text: "User Campus", value: "userCampus.usuario", filterable: true },
        // { text: "Email Campus", value: "userCampus.email", filterable: true },
        {
          text: "Creado",
          value: "alumno_cursado.created_at",
          filterable: true,
        },
        {
          text: "Espacio",
          value: "u_curricular_carrera.nombre",
          filterable: false,
          nosearch: true,
        },
        { text: "DNI", value: "user.dni", filterable: true },
        { text: "Apellido", value: "user.apellido", filterable: true },
        { text: "Nombre", value: "user.nombre", filterable: true },
        { text: "Email", value: "user.email", filterable: true },
        { text: "Telefono", value: "user.telefono", filterable: true },
        {
          text: "Superpuesto",
          value: "superpuesto",
          filterable: false,
          nosearch: true,
        },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewCursadoModule", ["getCursado"]),
    ...mapActions("NewAlumnoCursadoModule", [
      "getAlumnoCursados",
      "deleteAlumnoCursado",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCursado(id);
      }
    },

    async reload() {
      this.getCursado(this.cursado_id);
      this.loading.status = true;
      this.filters.cursado_id = this.cursado_id;
      await this.getAlumnoCursados({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
    verSuperpuestos(item) {
      // this.$refs.superpuestos.dialog = true;
      // this.$refs.superpuestos.cursados = item.cursadosSuperpuestos;
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = [
        item.cursado,
        ...item.cursadosSuperpuestos,
      ];
    },
    verHorarios(item) {
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = item.otrosCursados;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
