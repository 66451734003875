import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import MateriaCorrelativas from "@/components/MateriaCorrelativas";

import AuthLayout from "@/layouts/AuthLayout";
// import Register from "@/views/ingresante/Register";
import authService from "@/services/auth.service";

import SideBarLayout from "@/layouts/SideBarLayout";

import Carreras from "@/views/carrera/Carreras";

import UCurriculars from "@/views/u-curricular/UCurriculars";

import UCurricularCarreras from "@/views/u-curricular-carrera/UCurricularCarreras";
import AlumnoCursados from "@/views/alumno-cursado/AlumnoCursados";
import NuevoCursado from "@/views/cursado/NuevoCursado";
import Correlativass from "@/views/correlativas/Correlativass";
import Login from "@/views/user/Login";
import RecuperarPassword from "@/views/user/RecuperarPassword";
import Perfil from "@/views/user/Perfil";
import IngresantesDocentes from "@/views/ingresante/IngresantesDocentes";
import IngresantesCarrera from "@/views/ingresante/carrera/IngresantesCarrera";
import IngresantesCursado from "@/views/ingresante/cursado/IngresantesCursado";
import AlumnosIngresantes from "@/views/ingresantes/AlumnosIngresantes";
import CorteInscripcionCarreraABM from "@/views/corte-inscripcion-carrera/CorteInscripcionCarreraABM";
import ExamenABM from "@/viewsNew/examen/ExamenABM";
import AlumnoExamenABM from "@/viewsNew/alumno-examen/AlumnoExamenABM";
import UserABM from "@/viewsNew/user/UserABM";
import AlumnoCarreraABM from "@/viewsNew/alumno-carrera/AlumnoCarreraABM";
import PresistemaCarreraABM from "@/viewsNew/alumno-carrera/presistema/PresistemaCarreraABM";
import PorCarreraAlumnoCarreraABM from "@/viewsNew/alumno-carrera/porCarrera/PorCarreraAlumnoCarreraABM";
import TodosAlumnoExamenABM from "@/viewsNew/alumno-examen/todos/TodosAlumnoExamenABM";
import TodosAlumnoCarreraABM from "@/viewsNew/alumno-carrera/todos/TodosAlumnoCarreraABM";
import PorRolUserABM from "@/viewsNew/user/PorRolUser/PorRolUserABM";
import DocenteABM from "@/viewsNew/user/docentes/DocenteABM";
import AlumnoABM from "@/viewsNew/user/alumnos/AlumnoABM";
import UCurricularCarreraABM from "@/viewsNew/u-curricular-carrera/UCurricularCarreraABM";
import CursadoABM from "@/viewsNew/cursado/CursadoABM";
import AlumnoCursadoABM from "@/viewsNew/alumno-cursado/AlumnoCursadoABM";
import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";
import PorAlumnoCursadoABM from "@/viewsNew/alumno-cursado/porAlumno/PorAlumnoCursadoABM";
import PorAlumnoExamenABM from "@/viewsNew/alumno-examen/porAlumno/PorAlumnoExamenABM";
import SuperpuestosAlumnoCursadoABM from "@/viewsNew/alumno-cursado/superpuestas/SuperpuestosAlumnoCursadoABM";
import PorAlumnoCarreraABM from "@/viewsNew/alumno-carrera/porAlumno/PorAlumnoCarreraABM";
import PlanillaDigitalCursadoSecretaria from "@/viewsNew/cursado/planilla-cursado/PlanillaDigitalCursadoSecretaria";
import Promocionados from "@/viewsNew/alumno-cursado/Promocionados";
import ProgramaExamenABM from "@/viewsNew/programa-examen/ProgramaExamenABM";
import HistorialCursadoPorALumnoABM from "@/viewsNew/historial-cursado-por-alumno/HistorialCursadoPorALumnoABM";
import HistorialAcademicoPorAlumno from "@/viewsNew/historial-academico/HistorialAcademicoPorAlumno";
import PlanillaDigitalExamenSecretaria from "@/viewsNew/examen/planilla-digital/PlanillaDigitalExamenSecretaria";
import Profile from "@/viewsNew/user/Profile.vue";
import AllUserABM from "@/viewsNew/user/todos/AllUserABM.vue";
import PeriodoExamenABM from "@/viewsNew/periodo-examen/PeriodoExamenABM.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Default",
    redirect: "/usuarios-por-rol",
    component: SideBarLayout,

    children: [
      {
        path: "/usuarios",
        name: "usuarios",
        component: AllUserABM,
        props: true,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        props: true,
      },
      {
        path: "/historial-academico/:alumno_id",
        name: "historialAcademico",
        component: HistorialAcademicoPorAlumno,
        props: true,
      },
      {
        path: "/historial-cursado/:alumno_id",
        name: "historialCursado",
        component: HistorialCursadoPorALumnoABM,
        props: true,
      },
      {
        path: "/programa-examenes",
        name: "programaExamenes",
        component: ProgramaExamenABM,
        props: true,
      },
      {
        path: "/periodo-examenes",
        name: "periodoExamenes",
        component: PeriodoExamenABM,
        props: true,
      },

      {
        path: "/promocionados",
        name: "promocionados",
        component: Promocionados,
        props: true,
      },
      {
        path: "/planilla-digital-cursado/:cursado_id",
        name: "planillaDigital",
        component: PlanillaDigitalCursadoSecretaria,
        props: true,
      },
      {
        path: "/planilla-digital-examen/:examen_id",
        name: "planillaDigitalExamen",
        component: PlanillaDigitalExamenSecretaria,
        props: true,
      },
      {
        path: "perfil",
        name: "Perfil",
        component: Perfil,
        props: true,
      },
      {
        path: "/correlativas/:materia_id",
        name: "correlativas",
        component: Correlativass,
        props: true,
      },

      {
        path: "/vuex/carreras",
        name: "vuex-carreras",
        component: Carreras,
        props: true,
      },
      {
        path: "/alumnos-carrera/:carrera_id",
        name: "alumnos-por-carrera",
        component: PorCarreraAlumnoCarreraABM,
        props: true,
      },
      {
        path: "/alumnos-carrera",
        name: "alumnos-carrera",
        component: TodosAlumnoCarreraABM,
        props: true,
      },
      {
        path: "/carreras-por-alumno/:user_id",
        name: "carreras-por-alumno",
        component: PorAlumnoCarreraABM,
        props: true,
      },

      {
        path: "/usuarios-por-rol",
        name: "usuarios-por-rol",
        component: PorRolUserABM,
        props: true,
      },
      {
        path: "/docentes",
        name: "docentes",
        component: DocenteABM,
        props: true,
      },
      {
        path: "/espacios",
        name: "espacios",
        component: UCurricularCarreraABM,
        props: true,
      },
      {
        path: "/alumnos",
        name: "alumnos",
        component: AlumnoABM,
        props: true,
      },

      {
        path: "/usuarios",
        name: "usuarios",
        component: UserABM,
        props: true,
      },

      {
        path: "/materias-por-carrera/:carrera_id",
        name: "materias-por-carrera",
        component: UCurricularCarreras,
        // props: (route) => ({
        //   ...route.params
        // })
        props: true,
      },
      {
        path: "/vuex/u-curricular",
        name: "u-curricular",
        component: UCurriculars,
        props: true,
      },
      {
        path: "/vuex/u-curricular-carrera",
        name: "u-curricular-carrera",
        component: UCurricularCarreras,
        props: true,
      },
      {
        path: "/regimen-correlativas/:carrera_id",
        name: "regimen-correlativas",
        component: MateriaCorrelativas,
        props: true,
      },

      {
        path: "/cursadas",
        name: "cursadas",
        component: CursadoABM,
        props: true,
      },

      {
        path: "/nuevo-cursado",
        name: "nuevo-cursado",
        component: NuevoCursado,
        props: true,
      },

      {
        path: "/examenes",
        name: "examenes",
        component: ExamenABM,
        props: true,
      },
      {
        path: "/alumno-examen",
        name: "alumno-examen",
        component: TodosAlumnoExamenABM,
        props: true,
      },
      {
        path: "/alumno-cursado/:cursado_id",
        name: "alumno-cursado",
        component: AlumnoCursadoABM,
        props: true,
      },
      {
        path: "/alumno-cursado",
        name: "alumno-cursado-por-cursado",
        component: AlumnoCursadoABM,
        props: true,
      },
      {
        path: "/alumno-cursado-superpuesto",
        name: "alumno-cursado-superpuesto",
        component: SuperpuestosAlumnoCursadoABM,
        props: true,
      },
      {
        path: "/calendario",
        name: "calendario",
        component: CalendarioHorario,
        props: true,
      },
      {
        path: "/inscripciones",
        name: "inscripciones",
        component: CorteInscripcionCarreraABM,
        props: true,
      },

      {
        path: "/alumno-cursada/:cursada_id",
        name: "AlumnoCursada",
        component: AlumnoCursados,
        props: true,
      },
      {
        path: "/por-alumno-cursada/:alumno_id",
        name: "PorAlumnoCursada",
        component: PorAlumnoCursadoABM,
        props: true,
      },
      {
        path: "/alumno-examen/:examen_id",
        name: "AlumnoExamen",
        component: AlumnoExamenABM,
        props: true,
      },
      {
        path: "/por-alumno-examen/:alumno_id",
        name: "PorAlumnoExamen",
        component: PorAlumnoExamenABM,
        props: true,
      },
      {
        path: "/ingresantesold",
        name: "ingresantesold",
        component: AlumnosIngresantes,
        props: true,
      },
      {
        path: "/ingresantes",
        name: "ingresantes",
        component: AlumnoCarreraABM,
        props: true,
      },
      {
        path: "/ingresantes-carrera",
        name: "ingresantesCarrera",
        component: IngresantesCarrera,
        props: true,
      },
      {
        path: "/ingresantes-cursado",
        name: "ingresantesCursado",
        component: IngresantesCursado,
        props: true,
      },
      {
        path: "/ingresantes-docentes",
        name: "ingresantesDocentes",
        component: IngresantesDocentes,
        props: true,
      },
      {
        path: "/ingresantes-presistema",
        name: "ingresantes-presistema",
        component: PresistemaCarreraABM,
        props: true,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "recuperar",
        name: "recuperar",
        component: RecuperarPassword,
      },

      {
        path: "login",
        name: "login",
        component: Login,
      },
      // {
      //   path: "register",
      //   name: "register",
      //   component: Register,
      // },
    ],
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/recuperar",
    redirect: "/auth/recuperar",
  },
  {
    path: "/register",
    redirect: "/auth/register",
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "recuperar" || to.name === "register") {
    next();
  } else if (to.name !== "login" && !authService.isLoggedIn())
    next({ name: "login" });
  else {
    next();
  }
});
//
const DEFAULT_TITLE = "SECRETARIA | UNES";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
