var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('por-rol-user-form',{ref:"form",attrs:{"id":_vm.id}}),_c('v-card',[_c('v-card-title',[_vm._v("Users")]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1),_c('div',{staticClass:"mx-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":"multi Carrera"},model:{value:(_vm.multicarrera),callback:function ($$v) {_vm.multicarrera=$$v},expression:"multicarrera"}})],1),_c('div',{staticClass:"mx-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":"sin User en Campus"},model:{value:(_vm.sinUserCampus),callback:function ($$v) {_vm.sinUserCampus=$$v},expression:"sinUserCampus"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex mx-2"},[_c('v-select',{attrs:{"label":"Ejecutar en todos","dense":"","outlined":"","hide-details":"","items":_vm.acciones},model:{value:(_vm.accion),callback:function ($$v) {_vm.accion=$$v},expression:"accion"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.ejecutarAccion}},[_vm._v("Ejecutar")])],1),_c('div',[_c('download-excel',{attrs:{"data":_vm.excel}},[_c('v-btn',{attrs:{"dark":"","color":"success"}},[_vm._v("Exportar")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': [
            10,
            20,
            100,
            200,
            500,
            { value: -1, text: 'All' } ],
        },"show-select":"","loading":_vm.loading.status,"items":_vm.usuarios,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('filter-row',{ref:"filterRow",attrs:{"headers":_vm.headers},on:{"reload":_vm.reload},scopedSlots:_vm._u([{key:"roles",fn:function(ref){return [_c('div',[_c('v-select',{attrs:{"clearable":"","dense":"","hide-details":"","multiple":"","label":"Ingrese rol","outlined":"","items":_vm.roles},on:{"change":_vm.reload},model:{value:(_vm.rolesModel),callback:function ($$v) {_vm.rolesModel=$$v},expression:"rolesModel"}})],1)]}},{key:"carrera.codigo",fn:function(ref){return [_c('v-autocomplete',{attrs:{"clearable":"","dense":"","hide-details":"","multiple":"","label":"Ingrese Carrera","outlined":"","items":_vm.carreras,"item-value":"id","item-text":"nombre"},model:{value:(_vm.options.carrera),callback:function ($$v) {_vm.$set(_vm.options, "carrera", $$v)},expression:"options.carrera"}})]}}])})]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]),_c('whatsapp-button',{attrs:{"telefono":item.telefono}})]}},{key:"item.carrera.codigo",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","target":"_blank","href":/carreras-por-alumno/ + item.id}},_vm._l((item.preinscripcion),function(carrera,i){return _c('div',{key:carrera.id},[(i > 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(carrera.codigo)+" ")])}),0)]}},{key:"item.user.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.creado)+" ")]}},{key:"item.cursados",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","target":"_blank","href":'/por-alumno-cursada/' + item.id,"color":"purple"}},[_vm._v("Cursados")])]}},{key:"item.examenes",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","target":"_blank","href":'/por-alumno-examen/' + item.id,"color":"teal"}},[_vm._v("Examenes")])]}},{key:"item.historial",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","target":"_blank","href":'/historial-academico/' + item.id,"color":"info"}},[_vm._v("Historial")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }