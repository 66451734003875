<template>
  <div>
    <v-dialog
      persistent
      eager
      v-model="dialogUsuarios"
      max-width="1200"
      scrollable
    >
      <agregar-usuarios
        @close="dialogUsuarios = false"
        @usuarios="agregarUsuarios"
      ></agregar-usuarios>
    </v-dialog>

    <v-dialog persistent eager v-model="dialog" max-width="1200">
      <alumno-carrera-form
        ref="form"
        :id="id"
        :selected="alumno_id"
        @close="cerrar"
      >
        <template v-slot:inscripcion_id="{ model }">
          <v-select
            outlined
            :items="corteInscripcionCarreras"
            item-text="nombre"
            item-value="id"
            v-model="model.inscripcion_id"
          ></v-select>
        </template>
        <template v-slot:estado="{ model }">
          <v-switch
            :true-value="1"
            :false-value="0"
            v-model="model.estado"
            label="Matriculado"
          ></v-switch>
        </template>
        <template v-slot:condicion="{ model }">
          <v-select
            clearable
            label="Condición"
            outlined
            v-model="model.condicion"
            :items="model.condiciones"
          ></v-select>
        </template>
        <template v-slot:turno_id="{ model }">
          <v-select
            clearable
            label="Turno"
            outlined
            v-model="model.turno_id"
            :items="turnos"
            item-text="nombre"
            item-value="id"
          ></v-select>
        </template>
      </alumno-carrera-form>
    </v-dialog>
    <v-card width="100%">
      <v-card-title> Alumnos de la Carrera {{ carrera.nombre }} </v-card-title>
      <v-card-text>
        <div class="d-flex mis-acciones pb-3">
          <div>
            <v-btn x-large color="primary" @click="dialogUsuarios = true"
              >Agregar</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <div>
            <download-excel :data="excel">
              <v-btn x-large dark color="success">Exportar</v-btn>
            </download-excel>
          </div>
        </div>

        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCarreras"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:carrera.codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <template v-slot:inscripcion.nombre="{}">
                <v-select
                  multiple
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['inscripcion_id']"
                  :items="corteInscripcionCarreras"
                  item-value="id"
                  item-text="nombre"
                ></v-select>
              </template>
              <!--              <template v-slot:u_curricular_carrera.nombre="{}">-->
              <!--                <v-text-field @change="reload" v-model="search.tag" ></v-text-field>-->
              <!--              </template>-->
            </filter-row>
          </template>

          <template v-slot:item.estado="{ item }">
            <v-chip color="success" v-show="item.estado">Matriculado</v-chip>
            <v-chip color="error" v-show="!item.estado">Sin Matricular</v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import AlumnoCarreraForm from "@/viewsNew/alumno-carrera/AlumnoCarreraForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import AgregarUsuarios from "@/viewsNew/user/AgregarUsuarios";

export default {
  name: "PorCarreraAlumnoCarreraABM",
  components: { AgregarUsuarios, FilterRow, AlumnoCarreraForm },
  props: {
    carrera_id: [String, Number],
  },
  data() {
    return {
      dialogUsuarios: false,
      turno: null,
      inscripcion_id: null,
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      alumno_id: null,
      options: {
        joins: ["carrera", "alumno", "inscripcion"],
      },
      expand: ["carrera", "alumno", "turno", "user", "inscripcion"],
    };
  },
  computed: {
    ...mapState("TurnoModule", ["turnos"]),
    ...mapState("CarreraModule", ["carreras", "carrera"]),
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarreras"]),
    ...mapState("NewAlumnoCarreraModule", ["alumnoCarreras", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    excel() {
      const data = [];
      for (var i in this.alumnoCarreras) {
        var registro = {
          cohorte: this.alumnoCarreras[i].inscripcion
            ? this.alumnoCarreras[i].inscripcion.nombre
            : "",
          condicion: this.alumnoCarreras[i].condicion,
          matriculado: this.alumnoCarreras[i].estado,
          nombre: this.alumnoCarreras[i].alumno.nombre,
          apellido: this.alumnoCarreras[i].alumno.apellido,
          email: this.alumnoCarreras[i].alumno.email,
          dni: this.alumnoCarreras[i].alumno.dni,
          telefono: this.alumnoCarreras[i].alumno.telefono,
          carrera: this.alumnoCarreras[i].carrera.codigo,
          rol: this.alumnoCarreras[i].alumno.roles,
          turno: this.alumnoCarreras[i].turno
            ? this.alumnoCarreras[i].turno.searchTag
            : "",
        };
        data.push(registro);
      }
      return data;
    },

    codigos() {
      return this.carreras.map((el) => el.codigo);
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        {
          text: "Email",
          value: "user.email",
          filterable: true,
          table: "user.email",
        },
        {
          text: "Cohorte",
          value: "inscripcion.nombre",
          filterable: true,
          table: "corte_inscripcion_carrera.nombre",
        },
        {
          text: "Nombre",
          value: "user.nombre",
          table: "user.nombre",
          filterable: true,
        },
        {
          text: "Apellido",
          value: "user.apellido",
          table: "user.apellido",
          filterable: true,
        },
        // {
        //   text: "email",
        //   value: "user.email",
        //   table: "user.email",
        //   filterable: true,
        // },
        { text: "Dni", value: "user.dni", table: "user.dni", filterable: true },
        {
          text: "Telefono",
          value: "user.telefono",
          table: "user.telefono",
          filterable: true,
        },
        // {
        //   text: "Carrera",
        //   value: "carrera.codigo",
        //   table: "carrera.codigo",
        //   filterable: true,
        //   nosearch: true,
        // },
        {
          text: "Rol",
          value: "user.roles",
          table: "user.roles",
          filterable: true,
          nosearch: true,
        },
        // { text: "Documentacion", value: "documentacion" },
        { text: "Condicion", value: "condicion", filterable: true },
        { text: "Estado", value: "estado", filterable: true },
        // {
        //   text: "Turno",
        //   value: "turno.searchTag",
        //   filterable: true,
        //   nosearch: true,
        // },
        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        // this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      filters: {
        handler() {
          this.setFilters();
          this.reload();
        },
        deep: true,
      },
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("TurnoModule", ["getTurnos"]),
    ...mapActions("CarreraModule", ["getCarreras", "getCarrera"]),
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("NewAlumnoCarreraModule", [
      "getAlumnoCarreras",
      "deleteAlumnoCarrera",
    ]),
    ...mapActions("AlumnoCarreraModule", [
      "sendMailTurno",
      "updateAlumnoCarrera",
      "addAlumnoCarrera",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    agregarUsuarios(usuarios) {
      // console.log(usuarios);
      for (var i in usuarios) {
        var alumnoCarrera = {
          alumno_id: usuarios[i].id,
          carrera_id: this.carrera_id,
        };
        this.addAlumnoCarrera(alumnoCarrera);
      }
    },

    async borrarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = null;
        await this.updateAlumnoCarrera(this.selectedItems[i]);
        // this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    async asignarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = this.turno;
        var alumnoCarrera = await this.updateAlumnoCarrera(
          this.selectedItems[i]
        );
        this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    matricular() {},

    newItem() {
      this.$refs.form.model = {};
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.alumno_id = item.alumno_id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCarrera(id);
      }
    },

    async reload() {
      this.getTurnos();
      this.loading.status = true;
      await this.getAlumnoCarreras({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: { carrera_id: this.carrera_id, ...this.filters },
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  created() {
    this.getCorteInscripcionCarreras({});
    this.getCarreras();
    this.getCarrera(this.carrera_id);
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped>
.mis-acciones > div {
  margin: 0 0.5rem;
}
</style>
