<template>
  <div>
    <v-dialog eager v-model="dialogDatos" max-width="600">
      <datos-auxiliares-examen
        @close="dialogDatos = false"
        @completado="crearActa"
      ></datos-auxiliares-examen>
    </v-dialog>

    <v-dialog eager v-model="dialog" max-width="600">
      <alumno-examen-form
        ref="form"
        :id="id"
        @close="cerrar"
      ></alumno-examen-form>
    </v-dialog>
    <v-card>
      <v-card-title> Alumnos inscriptos a Examen </v-card-title>
      <v-card-text>
        <div class="subtitle-1">
          <!--          <div>Carrera: {{ examen.carrera.nombre }}</div>-->
          <!--          <div>Examen: {{ examen.materia.nombre }}</div>-->
          <!--          <div>Fecha: {{ examen.fecha }}</div>-->
        </div>
        <v-divider></v-divider>
        <!--        <v-row>-->
        <!--          <v-col align="left">-->
        <!--            <v-btn @click="newItem" color="primary">Nuevo</v-btn>-->
        <!--          </v-col>-->
        <!--          <v-col>-->
        <!--            <download-excel :data="excel">-->
        <!--              <v-btn dark color="success">Exportar</v-btn>-->
        <!--            </download-excel>-->
        <!--          </v-col>-->
        <!--          <v-col>-->
        <!--            <alumno-examen-p-d-f-->
        <!--              ref="pdf"-->
        <!--              :examen="examen"-->
        <!--              :items="alumnoExamens"-->
        <!--            ></alumno-examen-p-d-f>-->
        <!--            <div v-if="$refs.pdf">-->
        <!--              <v-btn outlined color="dark" @click="dialogDatos = true"-->
        <!--                >Acta Examen</v-btn-->
        <!--              >-->
        <!--            </div>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          show-select
          :loading="loading.status"
          :items="alumnoExamens"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ getWithTimeZone(item.created_at) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.wp="{ item }">
            <v-btn @click="whatsapp(item)" color="success" icon>
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapActions, mapState } from "vuex";
import AlumnoExamenForm from "@/viewsNew/alumno-examen/AlumnoExamenForm";
import DatosAuxiliaresExamen from "@/viewsNew/alumno-examen/DatosAuxiliaresExamen";
import FilterRow from "@/viewsNew/utils/FilterRow";

export default {
  name: "TodosAlumnoExamenABM",
  props: {
    examen_id: [String, Number],
  },
  components: {
    FilterRow,
    DatosAuxiliaresExamen,
    AlumnoExamenForm,
  },
  data() {
    return {
      dialogDatos: false,
      dialog: false,
      id: null,
      options: {
        joins: ["alumno", "examen"],
      },
      expand: ["alumno", "examen", "materia"],
    };
  },
  computed: {
    ...mapState("ExamenModule", ["examen"]),
    ...mapState("AlumnoExamenModule", ["alumnoExamens", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),
    excel() {
      const data = [];
      for (var i in this.alumnoExamens) {
        var registro = {
          nombre: this.alumnoExamens[i].alumno.nombre,
          apellido: this.alumnoExamens[i].alumno.apellido,
          email: this.alumnoExamens[i].alumno.email,
          dni: this.alumnoExamens[i].alumno.dni,
          telefono: this.alumnoExamens[i].alumno.telefono,
          condicion: this.alumnoExamens[i].condicion,
          // carrera: this.alumnoExamens[i].examen.carrera.codigo,
          rol: this.alumnoExamens[i].alumno.roles,
        };
        data.push(registro);
      }
      return data;
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        // {text:'Materia',value:'materia.nombre',filterable:true},
        {
          text: "Registro",
          value: "created_at",
          filterable: true,
          table: "alumno_examen.created_at",
        },
        {
          text: "Fecha",
          value: "examen.fecha",
          filterable: true,
          table: "examen.fecha",
        },
        {
          text: "Materia",
          value: "materia.nombre",
          filterable: false,
          table: "u_curricular_carrera.nombre",
        },
        { text: "Condicion", value: "condicion", filterable: true },
        // { text: "DNI", value: "alumno.dni", filterable: true },
        {
          text: "Nombre",
          value: "alumno.nombre",
          filterable: true,
          table: "user.nombre",
        },
        {
          text: "Apellido",
          value: "alumno.apellido",
          filterable: true,
          table: "user.apellido",
        },
        {
          text: "Email",
          value: "alumno.email",
          filterable: true,
          table: "user.email",
        },
        {
          text: "Telefono",
          value: "alumno.telefono",
          filterable: true,
          table: "user.telefono",
        },
        { text: "Roles", value: "alumno.roles", filterable: true },
        { text: "Acciones", value: "actions", filterable: false },
        { text: "WhatsApp", value: "wp", filterable: false },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("ExamenModule", ["getExamen"]),
    ...mapActions("AlumnoExamenModule", [
      "getAlumnoExamens",
      "deleteAlumnoExamen",
      "actaExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    getWithTimeZone(time) {
      if (!time) return "";
      return moment.unix(time).format("DD-MM-YYYY HH:mm");
    },

    whatsapp(item) {
      const phone = "549" + item.alumno.telefono;
      const messageCrudo =
        "Hola soy el referente TIC del IFDC de SAO, me comunico porque necesito que nos informe si el examen de " +
        item.materia.nombre +
        " que rinde el " +
        item.examen.dia +
        " lo rinde en condición de Libre o Regular, ya que el sistema nuevo no lo registró. Muchas gracias";

      const message = encodeURI(messageCrudo);
      var URL = "https://web.whatsapp.com/send?phone=" + phone;
      URL += "&text=" + message;
      console.log(URL);
      window.open(URL, "_blank");
    },

    crearActa() {
      // this.dialogDatos = true;
      this.actaExamen(this.examen);
    },

    newItem() {
      this.$refs.form.model = {};
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      // this.filters.examen_id = this.examen_id;
      await this.getAlumnoExamens({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    // this.getExamen({
    //   id: this.examen_id,
    //   expand: ["materia", "carrera", "alumnos","alumnoExamen"].toString(),
    // });
  },
};
</script>

<style scoped></style>
