<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Correlativass
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="model.correlativa_id"
            :items="materias"
            item-text="searchTag"
            item-value="id"
            label="Seleccione Espacio"
            outlined
          ></v-autocomplete>
          <v-select
            v-model="model.tipo_inscripcion"
            outlined
            label="Seleccione tipo de Inscripción"
            :items="['final', 'cursado']"
          >
          </v-select>
          <v-select
            v-model="model.tipo_id"
            outlined
            label="Seleccione condición"
            :items="tipoCorrelativas"
            item-value="id"
            item-text="nombre"
          >
          </v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "CorrelativasForm",
  props: {
    id: [String, Number],
    espacio: { type: Object, default: () => {} },
  },
  data() {
    return {
      materias: [],
      dialog: false,
      only: ["correlativa_id", "tipo_id", "tipo_inscripcion"],
      exclude: [],
      model: {},
    };
  },
  computed: {
    ...mapState("TipoCorrelativaModule", ["tipoCorrelativas"]),
    // ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapGetters("NewCorrelativasModule", ["errors"]),
    ...mapState("NewCorrelativasModule", ["fields", "correlativas", "record"]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("TipoCorrelativaModule", ["getTipoCorrelativas"]),
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("NewCorrelativasModule", [
      "getFields",
      "getCorrelativas",
      "updateCorrelativas",
      "addCorrelativas",
    ]),
    async getModel() {
      await this.getCorrelativas(this.id);
      this.model = this.correlativas;
    },
    async guardar() {
      this.model.u_curricular_id = this.espacio.id;
      this.model.id
        ? await this.updateCorrelativas(this.model)
        : await this.addCorrelativas(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  async created() {
    this.getFields();
    if (this.id) {
      this.getModel();
    }
    this.materias = await this.getUCurricularCarreras({
      dontcommit: true,
      filters: { carrera_id: this.espacio.carrera_id },
    });
    this.getTipoCorrelativas();
  },
};
</script>

<style scoped></style>
