<template>
  <div class="card">
    <v-row>
      <v-col>
        <user-form :id="selected" @close="cerrar"></user-form>
      </v-col>
      <v-col>
        <v-card>
          <v-alert v-if="record.actualizado" dark color="warning"
            >Registro Actualizado</v-alert
          >
          <v-alert v-if="record.nuevo" dark color="success"
            >Registro Creado</v-alert
          >
          <v-card-title>
            <div>
              <div>Condición del Alumno en Carrera</div>
              <div>{{ model.carrera.nombre }}</div>
            </div>
            <v-btn link icon absolute top right @click="cerrar"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-form>
              <div v-for="(label, field) in filteredFields" :key="field">
                <slot :name="field" :model="model">
                  <v-text-field
                    outlined
                    v-model="model[field]"
                    :label="label"
                    :error-messages="errors[field]"
                  ></v-text-field>
                </slot>
              </div>
              <!--              <v-switch-->
              <!--                :true-value="1"-->
              <!--                :false-value="0"-->
              <!--                v-model="mail"-->
              <!--                label="Enviar Mail"-->
              <!--              ></v-switch>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div>
              <v-btn @click="guardar" color="primary">Guardar</v-btn>
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn @click="cerrar">Cancelar</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import UserForm from "@/viewsNew/user/UserForm";

export default {
  name: "AlumnoCarreraForm",
  components: { UserForm },
  props: {
    id: [String, Number],
    selected: [String, Number],
    inscripcion_id: [String, Number],
  },
  data() {
    return {
      only: [
        "condicion",
        "turno_id",
        "estado",
        "mail",
        "inscripcion_id",
        // ,"carrera_id"
      ],
      exclude: [],
      model: { carrera: {} },
      mail: 0,
    };
  },
  computed: {
    ...mapGetters("NewAlumnoCarreraModule", ["errors"]),
    ...mapState("NewAlumnoCarreraModule", [
      "fields",
      "alumnoCarrera",
      "record",
    ]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("AlumnoCarreraModule", ["sendMailConfirmacion"]),
    ...mapActions("NewAlumnoCarreraModule", [
      "getFields",
      "getAlumnoCarrera",
      "updateAlumnoCarrera",
      "addAlumnoCarrera",
    ]),
    async getModel() {
      await this.getAlumnoCarrera(this.id);
      this.model = this.alumnoCarrera;
    },
    async guardar() {
      // this.model.inscripcion_id = this.inscripcion_id;
      this.model.id
        ? await this.updateAlumnoCarrera(this.model)
        : await this.addAlumnoCarrera(this.model);
      if (this.model.estado && this.mail) {
        this.sendMailConfirmacion(this.model.id);
      }
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  created() {
    this.getFields();
    if (this.id) {
      this.getModel();
    }
  },
};
</script>

<style scoped></style>
