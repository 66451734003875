<template>
  <v-card>
    <v-card-title>
      {{ ingresante.alumno.nombre }} {{ ingresante.alumno.apellido }}
      <v-btn link icon absolute top right @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-select v-model="model.condicion" :items="condiciones"></v-select>
        <v-select
          v-model="model.carrera_id"
          :items="carreras"
          item-value="id"
          item-text="nombre"
        ></v-select>
        <v-select
          v-model="model.turno_id"
          :items="turnos"
          item-value="id"
          item-text="searchTag"
        ></v-select>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="updateAlumnoCarrera(model)"
        >Actualizar</v-btn
      >
      <v-btn @click="$emit('close')">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "IngresanteForm",
  props: {
    ingresante: Object,
  },
  data() {
    return {
      model: {},
      condiciones: [
        "condicional",
        "regular",
        "vocacional",
        "vencido",
        "mayor25",
      ],
    };
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("TurnoModule", ["turnos"]),
  },
  methods: {
    ...mapActions("TurnoModule", ["getTurnos"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("AlumnoCarreraModule", ["updateAlumnoCarrera"]),
  },
  created() {
    this.getTurnos();
    this.getCarreras();
    this.model = this.ingresante;
  },
};
</script>

<style scoped></style>
