<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <div class="mr-3">
        <v-btn @click="agregar" outlined color="primary">Agregar</v-btn>
      </div>
      <div>
        <v-switch v-model="mail" label="Enviar Mail"></v-switch>
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="filteredUsers"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
// import UserForm from "@/views/user/UserForm";
// import MyModal from "@/components/templates/MyModal";
// import ImportExport from "@/views/layouts/ImportExport";

export default {
  name: "AgregarAlumnosCursado",
  // components: {
  //   ImportExport,
  //   MyModal, UserForm
  // },
  props: {
    porCarrera: Boolean,
    carrera_id: [String, Number],
    uploadPath: { type: String, default: "user" },
    // headers: Array,
    only: Array,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "DNI", value: "dni" },
        { text: "email", value: "email" },
        // { text: "Roles", value: "roles" },

        // { text: "Acciones", value: "actions" },
      ],
      options: {},
      mail: false,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("AlumnoCursadoModule", ["addAlumnoCursado", "addBatch"]),
    ...mapActions("UserModule", [
      "getAlumnosPorCarrera",
      "getUsers",
      "getFields",
      "addUser",
      "updateUser",
      "deleteUser",
      "editUser",
      "showDialog",
      "hideDialog",
    ]),
    close() {
      this.hideDialog();
    },
    async agregar() {
      var items = [];
      for (var i in this.selectedItems) {
        items.push({
          alumno_id: this.selectedItems[i].id,
          cursado_id: this.cursado.id,
          mail: this.mail,
        });
      }
      await this.addBatch(items);
      this.selectedItems = [];
      this.$emit("close");
    },
    onSubmit() {
      // this.addUser(this.user);
      this.user.id ? this.updateUser(this.user) : this.addUser(this.user);
    },
    newItem() {
      // this.fetchUser();
      this.editUser({});
      this.showDialog();
    },
    editItem(item) {
      this.editUser(item);
      this.showDialog();
      // this.showUserDialog();
    },
    deleteItem(id) {
      this.deleteUser(id);
    },
    reload() {
      this.getAlumnosPorCarrera({
        options: this.options,
        search: this.search,
        carrera_id: this.cursado.carrera.id,
      });
    },
  },
  computed: {
    ...mapState("CarreraModule", ["carrera"]),
    ...mapState("AlumnoCursadoModule", ["alumnoCursados"]),
    ...mapState("CursadoModule", ["cursado"]),
    ...mapState("UserModule", [
      "pagination",
      "alumnos",
      "users",
      "dialog",
      "user",
      "fields",
    ]),
    filteredUsers() {
      return this.alumnos.filter(
        (a) => !this.alumnoCursados.map((b) => b.alumno_id).includes(a.id)
      );
    },
  },

  created() {
    this.getFields();
    this.reload();
  },
  // mounted() {
  //   this.reload();
  // }
};
</script>

<style scoped></style>
