<template>
  <v-card>
    <v-card-title>
      <div>Datos de Autoridades</div>
      <v-btn link icon absolute top right @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        :items="users"
        label="Ingrese Nombre Presidente"
        v-model="examen.presidente"
        outlined
        item-text="fullname"
        item-value="fullname"
      ></v-autocomplete>
      <v-autocomplete
        :items="users"
        label="Ingrese Nombre Vocal 1"
        v-model="examen.vocal1"
        outlined
        item-text="fullname"
        item-value="fullname"
      ></v-autocomplete>
      <v-autocomplete
        :items="users"
        label="Ingrese Nombre Vocal 2"
        v-model="examen.vocal2"
        outlined
        item-text="fullname"
        item-value="fullname"
      ></v-autocomplete>
      <!--      <v-text-field-->
      <!--        label="Ingrese Vocal 1"-->
      <!--        v-model="examen.vocal1"-->
      <!--        outlined-->
      <!--      ></v-text-field>-->
      <!--      <v-text-field-->
      <!--        label="Ingrese Vocal 2"-->
      <!--        v-model="examen.vocal2"-->
      <!--        outlined-->
      <!--      ></v-text-field>-->
      <!--      <v-text-field-->
      <!--        label="Ingrese Turno"-->
      <!--        v-model="examen.turno"-->
      <!--        outlined-->
      <!--      ></v-text-field>-->
      <!--      <v-select-->
      <!--        label="Ingrese Instancia"-->
      <!--        v-model="examen.instancia"-->
      <!--        outlined-->
      <!--        :items="['Regular', 'Libre']"-->
      <!--      ></v-select>-->
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('completado')">Generar Acta</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DatosAuxiliaresExamen",
  computed: {
    ...mapState("ExamenModule", ["examen"]),
    ...mapState("NewUserModule", ["users"]),
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsersPorRoles"]),
  },
  created() {
    this.getUsersPorRoles({ roles: ["docente"] });
  },
};
</script>

<style scoped></style>
