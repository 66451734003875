<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Cursado Alumno
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carrera.id"
            :items="user.carreras"
            item-text="nombre"
            item-value="id"
            @change="getMaterias"
          />
          <v-autocomplete
            placeholder="Seleccione Materia"
            outlined
            v-model="model.cursado.espacio_id"
            :items="uCurricularCarreras"
            item-text="searchTag"
            item-value="id"
          ></v-autocomplete>

          <v-text-field
            type="date"
            outlined
            v-model="model.cursado.fecha_fin"
            label="ingrese Fecha Fin de Cursado"
          ></v-text-field>
          <v-text-field
            type="date"
            outlined
            v-model="model.cursado.fecha_vencimiento"
            label="ingrese Fecha Vencimiento"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "HistorialCursadoPorALumnoForm",
  props: {
    id: [String, Number],
    alumno_id: [String, Number],
  },
  data() {
    return {
      dialog: false,
      only: [],
      exclude: [],
      model: {
        cursado: {},
        carrera: {},
      },
    };
  },
  computed: {
    ...mapState("NewUserModule", ["user"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("NotaAlumnoCursadoModule", ["errors"]),
    ...mapState("NotaAlumnoCursadoModule", [
      "fields",
      "notaAlumnoCursado",
      "record",
    ]),

    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NotaAlumnoCursadoModule", [
      "addFichaAcademica",
      "getFields",
      "getNotaAlumnoCursado",
      "updateNotaAlumnoCursado",
      "addNotaAlumnoCursado",
      "updateFichaAcademica",
      "getNotaAlumnoCursadoWithParams",
    ]),
    async getModel() {
      await this.getNotaAlumnoCursadoWithParams({
        id: this.id,
        expand: ["cursado", "carrera"].toString(),
      });
      this.model = this.notaAlumnoCursado;
    },
    async guardar() {
      this.model.alumno_id = this.alumno_id;
      this.model.id
        ? await this.updateFichaAcademica(this.model)
        : await this.addFichaAcademica(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.model.carrera.id },
      });
    },
    desarmarFecha(fecha) {
      if (fecha) {
        const [year = "", month = "", day = ""] = fecha.split("-");
        return { anio: year, mes: month, dia: day };
      }
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
    "model.cursado.fecha_fin": function () {
      let { anio, mes } = this.desarmarFecha(this.model.cursado.fecha_fin);
      if (mes <= 7) {
        anio = parseInt(anio) + 2;
        this.model.cursado.fecha_vencimiento = `${anio}-12-31`;
      } else {
        anio = parseInt(anio) + 3;
        this.model.cursado.fecha_vencimiento = `${anio}-02-28`;
      }
    },
  },
  created() {
    // this.getCarreras();
    this.getMaterias();
    // this.getFields();
    // if (this.id) {
    //   this.getModel();
    // }
  },
};
</script>

<style scoped></style>
