<template>
  <div>
    <v-dialog v-model="turnoDialog" max-width="600">
      <turno-form @close="turnoDialog = false"></turno-form>
    </v-dialog>
    <v-dialog v-model="ingresanteDialog" max-width="600">
      <ingresante-form
        :ingresante="ingresante"
        @close="ingresanteDialog = false"
      ></ingresante-form>
    </v-dialog>

    <v-dialog v-model="dialogDocumentacion">
      <imagenes-documentacion-ingresante
        ref="imagenes"
        @close="dialogDocumentacion = false"
        :user="selectedUser"
        :inscripcion_id="selectedUser.id"
      >
      </imagenes-documentacion-ingresante>
    </v-dialog>

    <h3>Ingresantes</h3>

    <div class="d-flex mis-acciones pb-3">
      <div>
        <v-select
          outlined
          hide-details
          :items="corteInscripcionCarreras"
          item-value="id"
          item-text="nombre"
          v-model="inscripcion_id"
        ></v-select>
      </div>
      <div>
        <v-btn @click="matricular" outlined color="info">Matricular</v-btn>
      </div>
      <div>
        <v-btn @click="turnoDialog = true" outlined color="warning"
          >Nuevo turno</v-btn
        >
      </div>
      <div>
        <v-select
          hide-details
          :height="36"
          outlined
          placeholder="Seleccione un turno"
          clearable
          v-model="turno"
          item-text="searchTag"
          item-value="id"
          :items="turnos"
        ></v-select>
      </div>
      <div>
        <v-btn dark color="black" @click="asignarTurno">Asignar Turno</v-btn>
      </div>
      <div>
        <v-btn dark color="error" @click="borrarTurno">Borrar Turno</v-btn>
      </div>
      <div>
        <download-excel :data="excel">
          <v-btn dark color="success">Exportar</v-btn>
        </download-excel>
      </div>
    </div>

    <div>
      <h5>Filtros</h5>
      <div class="d-flex mis-filtros">
        <div>
          <v-select
            outlined
            clearable
            placeholder="Filtrar por carrera"
            v-model="filters.carrera_id"
            :items="carreras"
            item-text="nombre"
            item-value="id"
          ></v-select>
        </div>
        <div>
          <v-select
            outlined
            clearable
            placeholder="Filtrar por turno"
            v-model="filters.turno_id"
            :items="turnos"
            item-text="searchTag"
            item-value="id"
          ></v-select>
        </div>
        <div>
          <v-text-field
            outlined
            @change="reload"
            prepend-inner-icon="mdi-search"
            v-model="filters.search"
            label="Buscar Ingresante"
          >
          </v-text-field>
        </div>
      </div>
    </div>

    <v-data-table
      ref="table"
      show-select
      v-model="selectedItems"
      :items="alumnoCarreras"
      :headers="headers"
      :options.sync="options"
      :server-items-length="pagination.totalCount"
    >
      <template v-slot:item.carreras="{ item }">
        <span v-for="carrera in item.carreras" :key="carrera.id">
          {{ carrera.codigo }},
        </span>
      </template>

      <template v-slot:item.documentacion="{ item }">
        <v-btn outlined color="primary" @click="mostrarDocumentacion(item)" text
          >Documentación</v-btn
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editarIngresante(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="eliminar(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ImagenesDocumentacionIngresante from "@/flavor/alumno/views/Inscripciones/documentacion/ImagenesDocumentacionIngresante";
import TurnoForm from "@/views/turno/TurnoForm";
import IngresanteForm from "@/views/ingresantes/IngresanteForm";

export default {
  name: "AlumnosIngresantes",
  components: { IngresanteForm, TurnoForm, ImagenesDocumentacionIngresante },
  data() {
    return {
      inscripcion_id: null,
      ingresante: {},
      ingresanteDialog: false,
      turnoDialog: false,
      turno: {},
      selectedUser: {},
      dialogDocumentacion: false,
      selectedItems: [],
      options: {},
      search: "",
      filters: {},
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarreras"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("TurnoModule", ["turnos"]),
    ...mapState("UserModule", ["ingresantes"]),
    ...mapState("AlumnoCarreraModule", ["alumnoCarreras", "pagination"]),
    excel() {
      const data = [];
      for (var i in this.alumnoCarreras) {
        var registro = {
          nombre: this.alumnoCarreras[i].alumno.nombre,
          apellido: this.alumnoCarreras[i].alumno.apellido,
          email: this.alumnoCarreras[i].alumno.email,
          dni: this.alumnoCarreras[i].alumno.dni,
          telefono: this.alumnoCarreras[i].alumno.telefono,
          carrera: this.alumnoCarreras[i].carrera.codigo,
          rol: this.alumnoCarreras[i].alumno.roles,
          turno: this.alumnoCarreras[i].turno
            ? this.alumnoCarreras[i].turno.searchTag
            : "",
        };
        data.push(registro);
      }
      return data;
    },
    headers() {
      return [
        { text: "Nombre", value: "alumno.nombre" },
        { text: "Apellido", value: "alumno.apellido" },
        { text: "email", value: "alumno.email" },
        { text: "Dni", value: "alumno.dni" },
        { text: "Telefono", value: "alumno.telefono" },
        { text: "Carrera", value: "carrera.codigo" },
        { text: "Rol", value: "alumno.roles" },
        // { text: "Documentacion", value: "documentacion" },
        { text: "Condicion", value: "condicion" },
        { text: "Turno", value: "turno.searchTag" },
        { text: "Acciones", value: "actions" },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    dialogDocumentacion() {
      if (!this.dialogDocumentacion) {
        this.clearDocumentacion();
      }
    },
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("TurnoModule", ["getTurnos"]),
    ...mapMutations("UserModule", ["clearDocumentacion"]),
    ...mapActions("UserModule", ["getIngresantes", "cambiarRol"]),
    ...mapActions("AlumnoCarreraModule", [
      "updateAlumnoCarrera",
      "getAlumnoCarreras",
      "sendMailConfirmacion",
      "deleteAlumnoCarrera",
      "sendMailTurno",
    ]),
    reload() {
      this.getTurnos();
      // this.getIngresantes({ filters: { rol: "ingresante" } });
      this.getAlumnoCarreras({
        options: this.options,
        filters: { estado: 0, ...this.filters },
        expand: ["carrera", "alumno", "turno"],
      });
    },
    eliminar(item) {
      this.$confirm({
        title:
          "Inscripción de " + item.alumno.nombre + " " + item.alumno.apellido,
        message:
          "Esta seguro de eliminar inscripción a " + item.carrera.nombre + " ?",
        button: {
          yes: "Si",
          no: "Cancelar",
        },
        callback: (confirm) => {
          if (confirm == true) {
            this.deleteAlumnoCarrera(item.id);
          }
        },
      });
    },

    async borrarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = null;
        await this.updateAlumnoCarrera(this.selectedItems[i]);
        // this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    async asignarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = this.turno;
        var alumnoCarrera = await this.updateAlumnoCarrera(
          this.selectedItems[i]
        );
        this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    async matricular() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].estado = 1;
        this.selectedItems[i].inscripcion_id = this.inscripcion_id;
        var alumnoCarrera = await this.updateAlumnoCarrera(
          this.selectedItems[i]
        );
        this.sendMailConfirmacion(alumnoCarrera.id);

        // this.selectedItems[i].alumno.roles = ["alumno"];
        // await this.cambiarRol(this.selectedItems[i].alumno);
      }
      this.selectedItems = [];
      this.reload();
    },
    mostrarDocumentacion(item) {
      this.selectedUser = item;
      this.dialogDocumentacion = true;
    },
    editarIngresante(item) {
      this.ingresanteDialog = true;
      this.ingresante = item;
    },
  },
  async created() {
    this.getCarreras();
    await this.getCorteInscripcionCarreras({
      filters: { activo: 1, tipo: "ingresantes" },
    });
    this.inscripcion_id = this.corteInscripcionCarreras[0].id;
  },
};
</script>

<style scoped>
.mis-acciones > div {
  margin: 0 0.5rem;
}

.mis-filtros > div {
  margin: 0 0.5rem;
}
</style>
