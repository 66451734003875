<template>
  <v-container fluid>
    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"><h3>UCurriculars</h3></template>
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <uCurricular-form :only="only"> </uCurricular-form>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h3>UCurriculars</h3>

    <v-row no-gutters>
      <div>
        <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>
      </div>
      <import-export
        model-class="UCurriculars"
        :path="uploadPath"
        :only="only"
        :model-fields="fields"
        :plantilla="[uCurricular]"
      ></import-export>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="uCurriculars"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
import UCurricularForm from "@/views/u-curricular/UCurricularForm";
import MyModal from "@/components/templates/MyModal";
import ImportExport from "@/views/layouts/ImportExport";

export default {
  name: "UCurricularABM",
  components: {
    ImportExport,
    MyModal,
    UCurricularForm,
  },
  props: {
    uploadPath: { type: String, default: "u-curriculars" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("UCurricularModule", [
      "getUCurriculars",
      "getFields",
      "addUCurricular",
      "updateUCurricular",
      "deleteUCurricular",
      "editUCurricular",
      "showDialog",
      "hideDialog",
    ]),
    close() {
      this.hideDialog();
    },
    onSubmit() {
      // this.addUCurricular(this.uCurricular);
      this.uCurricular.id
        ? this.updateUCurricular(this.uCurricular)
        : this.addUCurricular(this.uCurricular);
    },
    newItem() {
      // this.fetchUCurricular();
      this.editUCurricular({});
      this.showDialog();
    },
    editItem(item) {
      this.editUCurricular(item);
      this.showDialog();
      // this.showUCurricularDialog();
    },
    deleteItem(id) {
      this.deleteUCurricular(id);
    },
  },
  computed: {
    ...mapState("UCurricularModule", [
      "uCurriculars",
      "dialog",
      "uCurricular",
      "fields",
    ]),
    // ...mapGetters("UCurricularModule",["uCurricularList","uCurricularDialog","newUCurricularRecord","uCurricular","uCurricularFields","uCurricularErrors"]),
    // ...mapGetters("UCurricularModule",["errors"]),
  },
  created() {
    this.getUCurriculars();
    this.getFields();
  },
};
</script>

<style scoped></style>
