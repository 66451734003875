import axios from "axios";
import authService from "./auth.service";
import router from "../router";
import Vue from "vue";

/** Default config for axios instance */
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'https://backifdc.atlanticosweb.com';
const API_ENDPOINT =
  process.env.VUE_APP_API_ENDPOINT || "http://localhost:8085";
let config = {
  baseURL: `${API_ENDPOINT}/v1/api/`,
  // headers: {},
  // baseURL: 'https://backifdc.atlanticosweb.com/v1/api/',
};

let loader = {};
let loading = false;

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
// const authInterceptor = config => {
//     config.headers.Authorization = `Bearer ${authService.getToken()}`;
//     loader = showLoader();
//     return config;
// };

// function hideLoader(){
//     loader.hide();
// }

function showLoader() {
  let loader = Vue.$loading.show({
    color: "#017BFF",
    loader: "dots",
    width: 128,
    height: 128,
    backgroundColor: "#ffffff",
    opacity: 0.5,
    zIndex: 999,
    canCancel: true,
    // onCancel: hideLoader(),
  });
  return loader;
}

httpClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${authService.getToken()}`;
  if (!loading) {
    loader = showLoader();
    loading = true;
  }
  return config;
});

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    /** TODO: Add any response interceptors */
    if (response.status === 201) {
      Vue.prototype.$message({
        type: "success",
        message: "registro creado correctamente",
      });
    }
    if (response.status === 204) {
      Vue.prototype.$message({
        type: "warning",
        message: "registro eliminado correctamente",
      });
    }
    loader.hide();
    loading = false;
    return response;
  },
  (error) => {
    // loading?loader.hide():'';
    if (error.response.status === 401) {
      router.push({ name: "login" });
    }
    Vue.prototype.$message({
      type: "error",
      message: error.response.data,
    });

    loader.hide();
    loading = false;
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
  // loader.hide()
);

export { httpClient, API_ENDPOINT, config };
