<template>
  <div>
    <v-dialog
      eager
      ref="docentes"
      v-model="dialogDocentes"
      scrollable
      max-width="600"
    >
      <docente-examen-form
        ref="form"
        :id="id"
        :examen_id="examen.id"
        @close="cerrar"
      >
        <template v-slot:docente_id="{ model }">
          <v-autocomplete
            label="seleccione Docente"
            v-model="model.docente_id"
            :items="users"
            item-text="searchTag"
            item-value="id"
          >
          </v-autocomplete>
        </template>
      </docente-examen-form>
      <v-card>
        <v-card-title v-if="examen.materia">
          Docentes de
          {{ examen.materia.searchTag }}

          <v-btn link icon absolute top right @click="dialogDocentes = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col align="left">
              <v-btn @click="newItem" color="primary">Nuevo</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Buscar por campo"
                v-model="search.tag"
                clearable
                @click:clear="reload"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            v-model="selectedItems"
            show-select
            :loading="loading.status"
            :items="docenteExamens"
            :headers="headers"
            :server-items-length="pagination.totalCount"
            :options.sync="options"
          >
            <template v-slot:body.prepend>
              <filter-row ref="filterRow" :headers="headers" @reload="reload">
              </filter-row>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon color="info" class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="info" @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialogDocentes = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
import DocenteExamenForm from "@/viewsNew/docente-examen/DocenteExamenForm";

export default {
  name: "DocenteExamenABM",
  props: {
    examen: { type: Object, default: () => {} },
    // examen: null,
  },
  components: { DocenteExamenForm, FilterRow },
  data() {
    return {
      dialogDocentes: false,
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: [],
      },
      expand: [],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users"]),
    ...mapState("DocenteExamenModule", ["docenteExamens", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // {text:'Id',value:'id',filterable:true},
        { text: "Nombre", value: "docente.nombre", filterable: true },
        { text: "Apellido", value: "docente.apellido", filterable: true },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialogDocentes() {
      if (!this.dialogDocentes) {
        this.$emit("close");
      }
    },

    examen() {
      this.reload();
    },

    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsersPorRoles"]),
    ...mapActions("DocenteExamenModule", [
      "getDocenteExamens",
      "deleteDocenteExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteDocenteExamen(id);
      }
    },

    async reload() {
      this.filters.examen_id = this.examen.id;
      this.loading.status = true;
      await this.getDocenteExamens({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getUsersPorRoles({ roles: ["docente"] });
  },
};
</script>

<style scoped></style>
