<template>
  <div>
    <v-container fluid>
      <h3>Nuevo Cursado</h3>
      <hr />
      <cursada-form></cursada-form>
      <hr />
      <v-btn outlined color="primary">Guardar</v-btn>
    </v-container>
  </div>
</template>
<script>
import CursadaForm from "@/components/Cursadas/CursadaForm";
export default {
  name: "NuevoCursado",
  components: { CursadaForm },
};
</script>

<style scoped></style>
