<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Datos Usuario
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <div v-for="(label, field) in filteredFields" :key="field">
            <slot :name="field" :model="model">
              <v-text-field
                outlined
                v-model="model[field]"
                :label="label"
                :error-messages="errors[field]"
              ></v-text-field>
            </slot>
          </div>
          <div class="d-flex">
            <v-select
              outlined
              v-model="model.roles"
              multiple
              label="Ingrese roles"
              :items="roles"
            ></v-select>
            <!--            <v-spacer></v-spacer>-->
            <!--            <v-btn  @click="actualizarRoles">Actualizar</v-btn>-->
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "alumnoForm",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      dialog: false,
      roles: [],
      only: [
        "apellido",
        "nombre",
        "email",
        "username",
        "password",
        "dni",
        "telefono",
        "rol",
      ],
      exclude: [],
      model: {},
      // roles: ["secretario", "docente", "alumno", "ingresante","alumno-preexistente"],
    };
  },
  computed: {
    ...mapGetters("NewUserModule", ["errors"]),
    ...mapState("NewUserModule", ["fields", "user", "record"]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUserModule", [
      "getFields",
      "getUser",
      "updateUser",
      "addUser",
      "getRoles",
    ]),
    async getModel() {
      await this.getUser(this.id);
      this.model = this.user;
    },
    async guardar() {
      this.model.id
        ? await this.updateUser(this.model)
        : await this.addUser(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.dialog = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  async created() {
    this.getFields();
    if (this.id) {
      this.getModel();
    }
    this.roles = await this.getRoles();
  },
};
</script>

<style scoped></style>
