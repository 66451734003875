<template>
  <v-container fluid>
    <h3>Correlativas de {{ uCurricularCarrera.nombre }}</h3>

    <v-row no-gutters>
      <v-select
        v-model="correlativaSelected"
        multiple
        :items="filteredMaterias"
        item-text="searchTag"
        item-value="id"
      >
        <template v-slot:prepend-item>
          <div class="p-3">
            <v-text-field label="Search" v-model="filterMateria" />
          </div>
        </template>
      </v-select>
      <div>
        <v-select
          v-model="tipoSelect"
          :items="tipoCorrelativas"
          item-value="id"
          item-text="nombre"
        ></v-select>
      </div>
      <div>
        <v-btn @click="agregarCorrelativas" outlined color="primary"
          >Agregar</v-btn
        >
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="correlativass"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";

export default {
  name: "CorrelativasABM",

  props: {
    uploadPath: { type: String, default: "correlativass" },
    headers: Array,
    only: Array,
    materia_id: [String, Number],
  },
  data() {
    return {
      filterMateria: "",
      tipoSelect: 1,
      correlativaSelected: [],
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("UCurricularCarreraModule", [
      "getUCurricularCarrera",
      "getUCurricularCarreras",
    ]),
    ...mapActions("CorrelativasModule", [
      "getCorrelativasPorMateria",
      "getCorrelativass",
      "getFields",
      "addCorrelativas",
      "updateCorrelativas",
      "deleteCorrelativas",
      "editCorrelativas",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("TipoCorrelativaModule", ["getTipoCorrelativas"]),

    agregarCorrelativas() {
      for (var i in this.correlativaSelected) {
        this.addCorrelativas({
          tipo_id: this.tipoSelect,
          u_curricular_id: this.materia_id,
          correlativa_id: this.correlativaSelected[i],
        });
      }
      this.correlativaSelected = [];
    },

    close() {
      this.hideDialog();
    },
    onSubmit() {
      // this.addCorrelativas(this.correlativas);
      this.correlativas.id
        ? this.updateCorrelativas(this.correlativas)
        : this.addCorrelativas(this.correlativas);
    },
    newItem() {
      // this.fetchCorrelativas();
      this.editCorrelativas({});
      this.showDialog();
    },
    editItem(item) {
      this.editCorrelativas(item);
      this.showDialog();
      // this.showCorrelativasDialog();
    },
    deleteItem(id) {
      this.deleteCorrelativas(id);
    },
    removeAccents(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
  },
  computed: {
    ...mapState("TipoCorrelativaModule", ["tipoCorrelativas"]),
    ...mapState("UCurricularCarreraModule", [
      "uCurricularCarrera",
      "materiasPorCarrera",
    ]),
    ...mapState("CorrelativasModule", [
      // "correlativass",
      "dialog",
      "correlativas",
      "fields",
    ]),
    correlativass() {
      return this.uCurricularCarrera.correlativas;
    },
    // ...mapGetters("CorrelativasModule",["correlativasList","correlativasDialog","newCorrelativasRecord","correlativas","correlativasFields","correlativasErrors"]),
    // ...mapGetters("CorrelativasModule",["errors"]),
    filteredMaterias() {
      // return this.materiasPorCarrera;
      var filtered = this.materiasPorCarrera.filter(
        (el) => el.id != this.uCurricularCarrera.id
      );
      // filtered = filtered.filter(a => !this.correlativass.map(b=>b.correlativa_id).includes(a.id));
      return filtered.filter(
        (el) =>
          this.removeAccents(el.searchTag.toLowerCase()).indexOf(
            this.filterMateria
          ) !== -1
      );
    },
  },
  watch: {
    materia_id() {
      this.getUCurricularCarrera(this.materia_id);
      // this.getCorrelativasPorMateria(this.materia_id);
      // this.getFields();
      this.getUCurricularCarreras(this.uCurricularCarrera.carrera_id);
    },
  },
  async created() {
    this.getTipoCorrelativas();
    await this.getUCurricularCarrera(this.materia_id);
    // this.getCorrelativasPorMateria({filters:{u_curricular_id:this.materia_id}});
    // this.getFields();
    this.getUCurricularCarreras(this.uCurricularCarrera.carrera_id);
  },
};
</script>

<style scoped></style>
