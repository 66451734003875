<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-text-field
          v-model="mensaje"
          label="Ingrese mensaje"
          outlined
        ></v-text-field>
        <v-btn @click="enviar">Enviar</v-btn>
      </v-card>
    </v-dialog>
    <v-btn @click="enviar" color="success" icon>
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "WhatsappButton",
  props: {
    telefono: [String, Number],
  },
  data() {
    return {
      mensaje: "",
      dialog: false,
    };
  },
  methods: {
    whatsapp() {
      this.dialog = true;
    },

    formatearTel(tel) {
      if (tel.charAt(0) == 0) {
        console.log("tel", tel);
        tel = tel.slice(1);
        console.log("tel", tel);
      }
      if (tel.length > 10) {
        if (tel.includes("15", 4)) {
          tel = tel.replace("293415", 2934);
          tel = tel.replace("292015", 2934);
          console.log("sin 15", tel);
        }
      }
      return tel;
    },

    enviar() {
      const phone = "549" + this.formatearTel(this.telefono);
      const messageCrudo = this.mensaje;
      const message = encodeURI(messageCrudo);
      var URL = "https://web.whatsapp.com/send?phone=" + phone;
      URL += "&text=" + message;
      console.log(URL);
      window.open(URL, "_blank");
    },
  },
};
</script>

<style scoped></style>
