<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        DocenteExamens
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <div v-for="(label, field) in filteredFields" :key="field">
            <slot :name="field" :model="model">
              <v-text-field
                outlined
                v-model="model[field]"
                :label="label"
                :error-messages="errors[field]"
              ></v-text-field>
            </slot>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "DocenteExamenForm",
  props: {
    id: [String, Number],
    examen_id: [String, Number],
  },
  data() {
    return {
      dialog: false,
      only: ["docente_id"],
      exclude: [],
      model: {},
    };
  },
  computed: {
    ...mapGetters("DocenteExamenModule", ["errors"]),
    ...mapState("DocenteExamenModule", ["fields", "docenteExamen", "record"]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("DocenteExamenModule", [
      "getFields",
      "getDocenteExamen",
      "updateDocenteExamen",
      "addDocenteExamen",
    ]),
    async getModel() {
      await this.getDocenteExamen(this.id);
      this.model = this.docenteExamen;
    },
    async guardar() {
      this.model.examen_id = this.examen_id;
      this.model.id
        ? await this.updateDocenteExamen(this.model)
        : await this.addDocenteExamen(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  created() {
    this.getFields();
    if (this.id) {
      this.getModel();
    }
  },
};
</script>

<style scoped></style>
