import { httpClient } from "@/services/http.service";

const materiaService = {
  create(materia) {
    return httpClient.post("u-curriculars", materia);
  },
  get() {
    return httpClient.get("u-curriculars?per-page=200");
  },
  update(materia) {
    return httpClient.put(`u-curriculars/${materia.id}`, materia);
  },
  delete(materia) {
    return httpClient.delete(`u-curriculars/${materia.id}`);
  },
};

export default materiaService;
