<template>
  <div>
    <v-dialog eager v-model="dialogDatos" max-width="600">
      <datos-auxiliares-examen
        @close="dialogDatos = false"
        @completado="crearActa"
      ></datos-auxiliares-examen>
    </v-dialog>

    <v-dialog eager v-model="dialog" max-width="600">
      <alumno-examen-form ref="form" :id="id" @close="cerrar">
        <template v-slot:examen_id="{ model }">
          <v-select
            outlined
            v-model="model.examen_id"
            :items="[examen]"
            item-value="id"
            item-text="materia.searchTag"
            disabled
          ></v-select>
        </template>

        <template v-slot:alumno_id="{ model }">
          <v-autocomplete
            placeholder="Ingrese Alumno"
            clearable
            :items="users"
            v-model="model.alumno_id"
            item-value="id"
            item-text="searchTag"
            outlined
          ></v-autocomplete>
        </template>
      </alumno-examen-form>
    </v-dialog>
    <v-card>
      <v-card-title> Alumnos inscriptos a Examen </v-card-title>
      <v-card-text>
        <div class="subtitle-1">
          <div>Carrera: {{ examen.carrera.nombre }}</div>
          <div>Examen: {{ examen.materia.nombre }}</div>
          <div>Fecha: {{ examen.fecha }}</div>
        </div>
        <v-divider></v-divider>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
          <v-col>
            <download-excel :data="excel">
              <v-btn dark color="success">Exportar</v-btn>
            </download-excel>
          </v-col>
          <v-col>
            <alumno-examen-p-d-f
              ref="pdf"
              :examen="examen"
              :items="alumnoExamens"
            ></alumno-examen-p-d-f>
            <div v-if="$refs.pdf">
              <v-btn outlined color="dark" @click="dialogDatos = true"
                >Acta Examen</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :loading="loading.status"
          :items="alumnoExamens"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <tr>
              <td v-for="(filterCol, i) in headers" :key="i">
                <v-text-field
                  v-show="filterCol.filterable"
                  hide-details
                  filled
                  :label="'Buscar por ' + filterCol.text"
                  v-model="filters[filterCol.value]"
                  clearable
                  @click:clear="reload"
                ></v-text-field>
              </td>
            </tr>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.creado }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import AlumnoExamenForm from "@/viewsNew/alumno-examen/AlumnoExamenForm";
import AlumnoExamenPDF from "@/viewsNew/alumno-examen/pdf/AlumnoExamenPDF";
import DatosAuxiliaresExamen from "@/viewsNew/alumno-examen/DatosAuxiliaresExamen";

export default {
  name: "AlumnoExamenABM",
  props: {
    examen_id: [String, Number],
  },
  components: { DatosAuxiliaresExamen, AlumnoExamenPDF, AlumnoExamenForm },
  data() {
    return {
      dialogDatos: false,
      dialog: false,
      id: null,
      options: {
        joins: ["alumno", "examen"],
      },
      expand: ["alumno", "examen"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users"]),
    ...mapState("ExamenModule", ["examen"]),
    ...mapState("AlumnoExamenModule", ["alumnoExamens", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),
    excel() {
      const data = [];
      for (var i in this.alumnoExamens) {
        var registro = {
          nombre: this.alumnoExamens[i].alumno.nombre,
          apellido: this.alumnoExamens[i].alumno.apellido,
          email: this.alumnoExamens[i].alumno.email,
          dni: this.alumnoExamens[i].alumno.dni,
          telefono: this.alumnoExamens[i].alumno.telefono,
          condicion: this.alumnoExamens[i].condicion,
          // carrera: this.alumnoExamens[i].examen.carrera.codigo,
          rol: this.alumnoExamens[i].alumno.roles,
        };
        data.push(registro);
      }
      return data;
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        {
          text: "Registro",
          value: "created_at",
          filterable: true,
          table: "docente_espacio.created_at",
        },
        // {text:'Materia',value:'materia.nombre',filterable:true},
        { text: "Condicion", value: "condicion", filterable: true },
        { text: "DNI", value: "alumno.dni", filterable: true },
        { text: "Nombre", value: "alumno.nombre", filterable: true },
        { text: "Apellido", value: "alumno.apellido", filterable: true },
        { text: "Email", value: "alumno.email", filterable: true },
        { text: "Telefono", value: "alumno.telefono", filterable: true },
        { text: "Roles", value: "alumno.roles", filterable: true },
        { text: "Acciones", value: "actions", filterable: false },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsersPorRoles"]),
    ...mapActions("ExamenModule", ["getExamen"]),
    ...mapActions("AlumnoExamenModule", [
      "getAlumnoExamens",
      "deleteAlumnoExamen",
      "actaExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    crearActa() {
      // this.dialogDatos = true;
      this.actaExamen(this.examen);
    },

    newItem() {
      this.$refs.form.model = { examen_id: +this.examen_id };
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      this.filters.examen_id = this.examen_id;
      await this.getAlumnoExamens({
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getExamen({
      id: this.examen_id,
      expand: ["materia", "carrera", "alumnos", "alumnoExamen"].toString(),
    });
    this.getUsersPorRoles({ roles: "alumno" });
  },
};
</script>

<style scoped></style>
