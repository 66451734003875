<template>
  <div>
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning">Registro Actualizado</v-alert>
      <v-alert v-if="record.nuevo" dark color="success">Registro Creado</v-alert>
      <v-card-title>
        Turnos
        <v-btn link icon absolute top right @click="cerrar"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <div v-for="(label,field) in filteredFields" :key="field">
            <slot :name="field" :model="model">
            <v-text-field outlined v-model="model[field]" :label="label" :error-messages="errors[field]"></v-text-field>
            </slot>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions >
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar" >Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import  {mapGetters,mapActions,mapState} from "vuex";

export default {
  name: "TurnoForm",
  props:{
    id:[String,Number]
  },
  data(){
    return{
      only:[],
      exclude:[],
      model:{},
    }
  },
  computed:{
    ...mapGetters("NewTurnoModule",["errors"]),
    ...mapState("NewTurnoModule",["fields","turno","record"]),
    filteredFields(){
      let items = []
      items = Object.keys(this.fields)
          .filter(key => !this.only.length>0 || this.only.includes(key))
          .reduce((obj, key) => {
            obj[key] = this.fields[key];
            return obj;
          }, {});
      items = Object.keys(items)
          .filter(key => !this.exclude.includes(key))
          .reduce((obj, key) => {
            obj[key] = items[key];
            return obj;
          }, {});
      return items;
    }

  },
  methods:{
    ...mapActions("NewTurnoModule",["getFields","getTurno","updateTurno","addTurno"]),
    async getModel(){
      await this.getTurno(this.id);
      this.model = this.turno;
    },
    async guardar(){
      this.model.id?await this.updateTurno(this.model):await this.addTurno(this.model);
      // this.$emit('close');
    },
    cerrar(){
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit('close');
    }
  },
  watch:{
    id(){
      if(this.id) {
        this.getModel();
      }
    },
  },
  created() {
    this.getFields();
    if(this.id){this.getModel()}
  }
}
</script>

<style scoped>

</style>
