<template>
  <v-container fluid>
    <my-modal ref="modalform" :dialog="dialogAlumnos" @close="close">
      <template v-slot:header> Agregar Alumnos </template>

      <template v-slot:body>
        <agregar-alumnos-cursado
          :cursado_id="cursado.id"
          @close="close"
        ></agregar-alumnos-cursado>
      </template>

      <template v-slot:footer-btn>
        <!--        <v-btn @click="agregar" color="blue darken-1" text> Agregar </v-btn>-->
      </template>
    </my-modal>

    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"><h3>AlumnoCursados</h3></template>
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <alumnoCursado-form :only="only">
                <template v-slot:fecha_abandono>
                  <div>
                    <vc-date-picker
                      v-if="abandono"
                      class=""
                      v-model="alumnoCursado.fecha_abandono"
                      :model-config="modelConfig"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <v-text-field
                          clearable
                          label="Seleccione Fecha de abandono"
                          :error-messages="errors.fecha_abandono"
                          :value="inputValue"
                          @click="togglePopover"
                          @click:clear="alumnoCursado.fecha_abandono = null"
                        />
                      </template>
                    </vc-date-picker>
                  </div>
                </template>
                <template v-slot:observaciones>
                  <v-textarea
                    label="Ingrese Las Observaciones"
                    v-model="alumnoCursado.observaciones"
                  ></v-textarea>
                </template>
              </alumnoCursado-form>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h4>{{ cursado.carrera.nombre }}</h4>
    <h5>Alumnos de {{ cursado.espacio.nombre }}</h5>
    <div>
      <span>Comision: {{ cursado.comision }}</span>
    </div>
    <div>
      <span v-for="horario in cursado.horarios" :key="horario.id"
        >{{ horario.nombreDia }} de {{ horario.hora_inicio }} hasta
        {{ horario.hora_fin }}</span
      >
    </div>
    <v-row no-gutters align="center">
      <div class="pr-3">
        <v-btn @click="showAgregar" outlined color="primary">Agregar</v-btn>
      </div>
      <div class="pr-3">
        <v-switch label="Editar" v-model="disableNotas" />
      </div>
      <div class="mr-3">
        <alumno-cursado-p-d-f
          ref="pdf"
          :cursado="cursado"
          :items="alumnoCursados"
        ></alumno-cursado-p-d-f>
        <div v-if="$refs.pdf">
          <v-btn outlined color="dark" @click="$refs.pdf.generateReport"
            >Exportar</v-btn
          >
        </div>
      </div>

      <div>
        <v-btn color="warning" @click="enviarMail">Enviar Mail</v-btn>
      </div>

      <import-export
        class="mr-3"
        model-class="AlumnoCursados"
        :path="uploadPath"
        :model-fields="fields"
        :plantilla="[alumnoCursado]"
        :only="importOnly"
        @close="close"
      ></import-export>
      <div class="mr-3">
        <v-btn @click="deleteAll" outlined color="error">Borrar Todo</v-btn>
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :options.sync="options"
      :server-items-length="pagination.totalCount"
      v-model="selectedItems"
      :show-select="true"
      :items="alumnoCursados"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.tp1="{ item }">
        <v-switch
          :disabled="!disableNotas"
          :label="item.tp1 ? 'SI' : 'NO'"
          v-model="item.tp1"
        ></v-switch>
      </template>
      <template v-slot:item.tp2="{ item }">
        <v-switch
          :disabled="!disableNotas"
          :label="item.tp2 ? 'SI' : 'NO'"
          v-model="item.tp2"
        ></v-switch>
      </template>
      <template v-slot:item.tp3="{ item }">
        <v-switch
          :disabled="!disableNotas"
          :label="item.tp3 ? 'SI' : 'NO'"
          v-model="item.tp3"
        ></v-switch>
      </template>
      <template v-slot:item.tp4="{ item }">
        <v-switch
          :disabled="!disableNotas"
          :label="item.tp4 ? 'SI' : 'NO'"
          v-model="item.tp4"
        ></v-switch>
      </template>

      <template v-slot:item.nota_alumno="{ item }">
        <v-text-field
          @change="updateAlumnoCursado(item)"
          :disabled="!disableNotas"
          type="number"
          v-model="item.nota_alumno"
        ></v-text-field>
      </template>

      <template v-slot:item.cursado_aprobado="{ item }">
        <v-switch
          :true-value="1"
          :false-value="0"
          @change="updateAlumnoCursado(item)"
          :disabled="!disableNotas"
          :label="item.cursado_aprobado ? 'SI' : 'NO'"
          v-model="item.cursado_aprobado"
        ></v-switch>
      </template>

      <template v-slot:item.fecha_abandono="{ item }">
        <v-row>
          <v-btn
            @click="editAbandono(item, true)"
            :color="item.fecha_abandono ? 'error' : 'light'"
            >{{
              item.fecha_abandono ? item.fecha_abandono : "registrar"
            }}</v-btn
          >
        </v-row>
      </template>

      <template v-slot:item.observaciones="{ item }">
        <v-icon color="info" class="mr-2" @click="editAbandono(item, false)">
          mdi-pencil
        </v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapGetters,
  mapState,
} from "vuex";
import AlumnoCursadoForm from "@/views/alumno-cursado/AlumnoCursadoForm";
import MyModal from "@/components/templates/MyModal";
import AlumnoCursadoPDF from "@/views/alumno-cursado/pdf/AlumnoCursadoPDF";
import AgregarAlumnosCursado from "@/views/alumno-cursado/AgregarAlumnosCursado";
import ImportExport from "@/views/layouts/ImportExport";
import AlumnoCursado from "@/apis/AlumnoCursado";

export default {
  name: "AlumnoCursadoABM",
  components: {
    AgregarAlumnosCursado,
    AlumnoCursadoPDF,
    ImportExport,
    MyModal,
    AlumnoCursadoForm,
  },
  props: {
    cursada_id: [String, Number],
    uploadPath: { type: String, default: "alumno-cursados" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      abandono: true,
      awaitingSearch: null,
      filters: {},
      options: { itemsPerPage: -1 },
      importOnly: ["alumno_id", "cursado_id"],
      disableNotas: false,
      filterAlumno: "",
      selected: [],
      dialogAlumnos: false,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("AlumnoCarreraModule", ["getAlumnosPorCarrera"]),
    ...mapActions("AlumnoCursadoModule", [
      "sendMailNota",
      "getAlumnoCursadosPorCursado",
      "getAlumnoCursados",
      "getFields",
      "addAlumnoCursado",
      "updateAlumnoCursado",
      "deleteAlumnoCursado",
      "editAlumnoCursado",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("CursadoModule", ["getCursado"]),

    async enviarMail() {
      await this.sendMailNota(this.selectedItems);
      this.reload();
    },

    close() {
      this.hideDialog();
      this.dialogAlumnos = false;
      this.selected = [];
      this.reload();
    },
    showAgregar() {
      this.dialogAlumnos = true;
      this.getAlumnosPorCarrera(this.cursado.carrera.id);
    },
    agregar() {
      for (var i in this.selected) {
        var alumnoCursado = {};
        alumnoCursado.alumno_id = this.selected[i];
        alumnoCursado.cursado_id = this.cursado.id;
        this.addAlumnoCursado(alumnoCursado);
      }
    },
    onSubmit() {
      // this.addAlumnoCursado(this.alumnoCursado);
      this.alumnoCursado.id
        ? this.updateAlumnoCursado(this.alumnoCursado)
        : this.addAlumnoCursado(this.alumnoCursado);
      this.close();
    },
    newItem() {
      // this.fetchAlumnoCursado();
      this.editAlumnoCursado({});
      this.showDialog();
    },
    editItem(item) {
      this.editAlumnoCursado(item);
      this.showDialog();
      // this.showAlumnoCursadoDialog();
    },
    deleteItem(id) {
      this.deleteAlumnoCursado(id);
    },
    async deleteAll() {
      await AlumnoCursado.deleteAll(this.selectedItems);
      this.selectedItems = [];
      this.reload();
    },

    reload() {
      this.getAlumnoCursadosPorCursado({
        filters: this.filters,
        options: this.options,
      });
      this.selectedItems = [];
    },

    editAbandono(item, flag) {
      this.abandono = flag;
      this.editItem(item);
    },
  },

  computed: {
    ...mapGetters("AlumnoCursadoModule", ["errors"]),
    ...mapState("AlumnoCarreraModule", ["alumnoCarreras"]),
    ...mapState("AlumnoCursadoModule", [
      "pagination",
      "alumnoCursados",
      "dialog",
      "alumnoCursado",
      "fields",
    ]),
    ...mapState("CursadoModule", ["cursado"]),
    filteredAlumnos() {
      return this.alumnoCarreras.filter(
        (a) => !this.alumnoCursados.map((b) => b.alumno_id).includes(a.id)
      );
    },
    // ...mapGetters("AlumnoCursadoModule",["alumnoCursadoList","alumnoCursadoDialog","newAlumnoCursadoRecord","alumnoCursado","alumnoCursadoFields","alumnoCursadoErrors"]),
    // ...mapGetters("AlumnoCursadoModule",["errors"]),
  },
  watch: {
    options: {
      handler() {
        this.reload();
        // console.log(this.pagination);
      },
      deep: true,
    },
    filters: {
      handler() {
        this.reload();
        // console.log(this.pagination);
      },
      deep: true,
    },
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.reload();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  created() {
    this.filters.cursado_id = this.cursada_id;
    this.getCursado(this.cursada_id);
    this.getFields();
  },
};
</script>

<style scoped></style>
