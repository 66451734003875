<template>
  <u-curricular-a-b-m :headers="headers"> </u-curricular-a-b-m>
</template>

<script>
import UCurricularABM from "@/views/u-curricular/UCurricularABM";
// import {mapState} from "vuex";

export default {
  name: "uCurriculars",
  components: { UCurricularABM },
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Campo", value: "campo.nombre" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  // computed:{
  //   ...mapState("UCurricularModule",["fields"]),
  // headers(){
  //   var items = [];
  //   for (var i in this.fields){
  //     items.push({
  //       value:i,text:this.fields[i]
  //     })
  //   }
  //   items.push( {text:'Acciones',value:'actions'});
  //   return items;
  // }
  // }
};
</script>

<style scoped></style>
