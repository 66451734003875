<template>
  <div>
    <slot name="form">
      <v-dialog persistent eager v-model="dialog" max-width="600">
        <turno-form ref="form" :id="id" @close="cerrar">
          <template v-slot:fecha="{model}">
            <v-text-field type="date" outlined v-model="model.fecha"></v-text-field>
          </template>
          <template v-slot:hora="{model}">
            <v-text-field type="time" outlined v-model="model.hora"></v-text-field>
          </template>
        </turno-form>
      </v-dialog>
    </slot>

    <v-card>

      <v-card-title>Turnos</v-card-title>
      <v-card-text>
        <slot name="buttons">
          <v-row>
            <v-col align="left">
              <v-btn @click="newItem" color="primary">Nuevo</v-btn>
            </v-col>
          </v-row>
        </slot>

        <v-row>
          <v-col>
            <v-text-field label="Buscar por campo"  v-model="search.tag" clearable @click:clear="reload"></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            v-model="selectedItems"
            show-select
            :loading="loading.status"
            :items="turnos"
            :headers="headers"
            :server-items-length="pagination.totalCount"
            :options.sync="options"
        >
          <template v-slot:body.prepend>
            <tr>
              <td></td>
              <td v-for="(filterCol,i) in headers" :key="i">
                <v-text-field v-show="filterCol.filterable" hide-details  filled  :label="'Buscar por '+filterCol.text"  v-model="like[filterCol.table?filterCol.table:filterCol.value]" clearable @click:clear="reload"></v-text-field>
              </td>
            </tr>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {mapMutations,mapActions,mapState} from "vuex";
import TurnoForm from "@/viewsNew/turno/TurnoForm";

export default {
  name: "TurnoABM",
  components: {TurnoForm},
  data(){
    return{
      selectedItems:[],
      like:{},
      dialog:false,
      id:null,
      options:{
        joins:[],
      },
      expand:[],
    }
  },
  computed:{
    ...mapState("NewTurnoModule",["turnos","pagination"]),
    ...mapState(["search","filters","loading","dialogs"]),

    filterHeaders(){
      return this.headers.filter(el=>el.filterable);
    },

    headers(){
      return[
        {text:'Id',value:'id',filterable:true},
        {text:'Descripción',value:'nombre',filterable:true},
        {text:'Fecha',value:'fecha',filterable:true},
        {text:'Hora',value:'hora',filterable:true},
        {text:'Acciones',value:'actions',filterable:false,nosearch:true},
      ]
    }
  },
  watch: {
    dialog(){
      if(!this.dialog){
        this.$refs.form.model={};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch()
        this.reload();
      },
      deep:true,
    },


    options:{
      handler(){
        this.reload();
      },
      deep:true,
    }
  },
  methods:{
    ...mapActions("NewTurnoModule",["getTurnos","deleteTurno"]),
    ...mapMutations(["clearFilters","setFilters","setSearch"]),

    newItem(){
      this.$refs.form.model={};
      this.dialog = true;
    },

    cerrar(){
      this.dialog= false;
      this.reload();
    },

    editItem(item){
      this.id = item.id
      this.dialog = true;
    },
    async deleteItem(id){
      const res = await this.$dialog.confirm({
        color:'error',
        icon:'mdi-alert',
        text: 'Esta seguro que desea eliminar el registro?',
        title: 'Eliminar Registro',
      });
      if (res) {
        this.deleteTurno(id);
      }
    },

    async reload(){
      this.loading.status = true;
      await this.getTurnos({
        like:this.like,
        options:this.options,
        filters:this.filters,
        expand:this.expand.toString(),
        search:this.search.tag,
        headers:this.headers,
      });
      this.loading.status = false;
    }
  },
  destroyed() {
    this.clearFilters();
  }
}
</script>

<style scoped>

</style>
