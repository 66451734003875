<template>
  <div>
    <docente-form :id="id" ref="form"></docente-form>
    <v-card>
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </div>
          <v-spacer></v-spacer>
          <download-excel :data="excel">
            <v-btn x-large dark color="success">Exportar</v-btn>
          </download-excel>
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          show-select
          :loading="loading.status"
          :items="users"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.telefono"></whatsapp-button>
          </template>
          <template v-slot:item.dictadas="{ item }">
            <div v-for="materia in item.dictadas" :key="materia.id">
              {{ materia.searchTag }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import DocenteForm from "@/viewsNew/user/docentes/DocenteForm";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";
import FilterRow from "@/viewsNew/utils/FilterRow";

export default {
  name: "DocenteABM",
  components: { FilterRow, WhatsappButton, DocenteForm },
  data() {
    return {
      rolesModel: ["docente"],
      like: {},
      dialog: false,
      id: null,
      options: {},
      expand: ["roles", "dictadas"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users", "pagination", "roles"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    excel() {
      let items = [];
      for (var i in this.users) {
        let item = { ...this.users[i] };
        item.dictadas = item.dictadas.map((el) => el.nombre);
        // item.roles = item.roles
        // item.campo = item.campo.nombre;
        // item.docentes = item.docentes.map(el=>el.apellido)
        // item.inscripcionCarrera = item.inscripcionCarrera.map(
        //     (el) => el.nombre
        // );
        items.push(item);
      }
      return items;
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // { text: "Id", value: "id", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Espacios",
          value: "dictadas",
          nosearch: true,
          filterable: true,
        },
        { text: "Roles", value: "roles", nosearch: true, filterable: true },
        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", [
      "getUsers",
      "deleteUser",
      "getUsersPorRoles",
      "getRoles",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsersPorRoles({
        roles: this.rolesModel,
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.getRoles();
    this.clearFilters();
  },
};
</script>

<style scoped></style>
