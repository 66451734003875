<template>
  <div>
    <v-row>
      <v-col>
        <div class="pt-3">
          <h4>Historial Académico de {{ user.apellido }}, {{ user.nombre }}</h4>
          <div class="subtitle-2">DNi: {{ user.dni }}</div>
          <div class="subtitle-2">Email: {{ user.email }}</div>
          <div class="subtitle-2">Telefono: {{ user.telefono }}</div>
          <span class="subtitle-2">
            Carreras:
            <span v-for="(carrera, i) in user.carreras" :key="carrera.id"
              >{{ carrera.nombre }} {{ i > 0 ? "," : "" }}</span
            ></span
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs color="white" v-model="tab" background-color="teal">
      <v-tab> Cursados </v-tab>
      <v-tab> Examenes </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <historial-cursado-por-a-lumno-a-b-m
          :alumno_id="alumno_id"
        ></historial-cursado-por-a-lumno-a-b-m>
      </v-tab-item>
      <v-tab-item>
        <historial-examen-por-a-lumno-a-b-m
          :alumno_id="alumno_id"
        ></historial-examen-por-a-lumno-a-b-m>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import HistorialCursadoPorALumnoABM from "@/viewsNew/historial-cursado-por-alumno/HistorialCursadoPorALumnoABM";
import HistorialExamenPorALumnoABM from "@/viewsNew/historial-examen-por-alumno/HistorialExamenPorALumnoABM";
import { mapActions, mapState } from "vuex";
export default {
  name: "HistorialAcademicoPorAlumno",
  components: { HistorialExamenPorALumnoABM, HistorialCursadoPorALumnoABM },
  props: {
    alumno_id: [String, Number],
  },
  data() {
    return {
      tab: {},
    };
  },
  computed: {
    ...mapState("NewUserModule", ["user"]),
  },
  methods: {
    ...mapActions("NewUserModule", ["getUser"]),
  },
  created() {
    this.getUser(this.alumno_id);
  },
};
</script>

<style scoped></style>
