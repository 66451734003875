var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('horarios-superpuestos',{ref:"superpuestos"}),_c('calendario-horario',{ref:"calendario"}),_c('alumno-cursado-form',{ref:"form",attrs:{"id":_vm.id},on:{"close":_vm.cerrar}}),_c('v-card',[_c('v-card-title',[_c('div',[_c('div',[_vm._v("Espacio: "+_vm._s(_vm.cursado.espacio.searchTag))]),_c('div',[_vm._v("Comision: "+_vm._s(_vm.cursado.comision))])])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1),_c('v-col',[_c('div',[_c('download-excel',{attrs:{"name":_vm.filename,"data":_vm.excel}},[_c('v-btn',{attrs:{"dark":"","color":"success"}},[_vm._v("Exportar")])],1)],1)])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': [10, 20, 50, 100, 200, 500],
        },"show-select":"","loading":_vm.loading.status,"items":_vm.alumnoCursados,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('filter-row',{ref:"filterRow",attrs:{"headers":_vm.headers},on:{"reload":_vm.reload}})]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]),_c('whatsapp-button',{attrs:{"telefono":item.alumno.telefono}})]}},{key:"item.superpuesto",fn:function(ref){
        var item = ref.item;
return [(item.cursadosSuperpuestos.length > 0)?_c('div',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.verSuperpuestos(item)}}},[_vm._v("SI")])],1):_c('div',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.verHorarios(item)}}},[_vm._v("No")])],1)]}},{key:"item.alumno_cursado.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.creado)+" ")]}},{key:"item.alumno_cursado.id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.u_curricular_carrera.nombre",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cursado.espacio.searchTag)+" ")]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }