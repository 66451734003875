<template>
  <div>
    <my-modal :dialog="dialog" @close="dialog = false">
      <template v-slot:body>
        <download-excel
          v-if="modelFields"
          :fields="filterFields"
          type="csv"
          :name="modelClass + '.csv'"
          :data="plantilla"
        >
          <v-btn color="success">Plantilla</v-btn>
        </download-excel>

        <v-file-input v-model="file"></v-file-input>
        <v-btn @click="submitFile">Importar</v-btn>
        <div v-if="importData" class="mt-3">
          <div class="text-warning" v-if="importData.duplicados">
            <h4 class="">Registros duplicados</h4>
            <v-data-table
              class="error-summary"
              :headers="headers"
              :items="importData.duplicados"
            >
              <template v-slot:item.errors="{ item }">
                <div v-for="(error, i) in item.errors" :key="i">
                  {{ error }}
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="text-danger" v-if="importData.errores">
            <h4 class="">Registros con Errores</h4>
            <v-data-table
              class="error-summary"
              :headers="headers"
              :items="importData.errores"
            >
              <template v-slot:item.errors="{ item }">
                <div v-for="(error, i) in item.errors" :key="i">
                  {{ error }}
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="text-success" v-if="importData.saved">
            <h4 class="">Registros Guardados</h4>
            <v-data-table
              class="error-summary"
              :headers="headers"
              :items="importData.saved"
            ></v-data-table>
          </div>
        </div>
      </template>
    </my-modal>
    <v-btn class="ml-3" color="success" outlined @click="dialog = true"
      >Importar</v-btn
    >
  </div>
</template>

<script>
import modelService from "@/services/model.service";
import MyModal from "@/components/templates/MyModal";

export default {
  name: "ImportExport",
  components: { MyModal },
  props: {
    only: Array,
    modelFields: Object,
    modelClass: String,
    // dialog:Boolean,
    plantilla: [Array, Function],
    path: String,
  },
  data() {
    return {
      importData: null,
      file: {},
      dialog: false,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.importData = null;
        this.file = null;
        // this.file={};
      }
    },
  },
  computed: {
    headers() {
      var items = [];
      for (var i in this.filterFields) {
        items.push({
          value: i,
          text: this.modelFields[i],
        });
      }
      items.push({ text: "Errores", value: "errors" });
      return items;
    },
    filterFields() {
      let fields = {};
      if (this.only) {
        for (var j in this.only) {
          let attr = this.only[j] in this.modelFields;
          if (attr) {
            fields[this.only[j]] = this.modelFields[this.only[j]];
          }
        }
        return fields;
      }
      if (this.exclude) {
        for (var i in this.modelFields) {
          let attr = this.exclude.find((el) => el == i);
          if (!attr) {
            fields[i] = this.modelFields[i];
          }
        }
        return fields;
      }
      return this.modelFields;
    },
  },
  methods: {
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      // console.log(">> formData >> ", formData);

      // You should have a server side REST API
      const { status, data } = await modelService.upload(
        formData,
        this.path + "/custom/upload"
      );
      if (status === 200) {
        console.log(data);
        this.importData = data;
        this.$emit("close");
      }
    },
    // async batchInsert() {
    //   let models = this.plantilla();
    //   const { status, data } = await modelService.create(
    //     models,
    //     this.path + "/custom/batch"
    //   );
    //   if (status === 200) {
    //     console.log(data);
    //   }
    // },
  },
};
</script>

<style scoped></style>
