<template>
  <correlativas-a-b-m :headers="headers" :materia_id="materia_id">
  </correlativas-a-b-m>
</template>

<script>
import CorrelativasABM from "@/views/correlativas/CorrelativasABM";

export default {
  name: "correlativass",
  props: {
    materia_id: [String, Number],
  },
  components: { CorrelativasABM },
  data() {
    return {
      headers: [
        // {text:'Id',value:'id'},
        { text: "Nombre Materia", value: "correlativa.nombre" },
        { text: "Tipo", value: "tipo.nombre" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
};
</script>

<style scoped></style>
