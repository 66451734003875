<template>
  <div>
    <h3>{{ cursado.espacio.searchTag }}</h3>
    <div class="d-flex">
      <div>
        <v-select
          dense
          label="Condición"
          class="mr-1"
          hide-details
          outlined
          v-model="opcion"
          :items="condicion"
          @change="changeItem(item.notaAlumnoCursado)"
        ></v-select>
      </div>
      <div class="mx-1">
        <v-btn color="info" @click="cambiarTodos">ejecutar</v-btn>
      </div>
      <div class="mx-1">
        <v-btn color="success" @click="getPlanillas">Imprimir</v-btn>
      </div>
      <div class="mx-1">
        <v-switch
          :true-value="1"
          :false-value="0"
          v-model="cerrar"
          class="mt-0"
          label="cerrar"
        ></v-switch>
      </div>
    </div>
    <v-data-table
      v-model="selectedItems"
      show-select
      :items="alumnoCursados"
      :headers="headers"
      :items-per-page="-1"
      :sort-by.sync="sort"
    >
      <template v-slot:item.condicion="{ item }">
        <div class="d-flex">
          <div class="col p-0">
            <v-select
              clearable
              :error="!!item.error"
              :error-messages="item.error"
              :disabled="esDisabled(item)"
              dense
              label="Condición"
              class="mr-1"
              outlined
              v-model="item.notaAlumnoCursado.condicion"
              :items="condicion"
              @change="changeItem(item.notaAlumnoCursado)"
            ></v-select>
          </div>
          <div
            class="col p-0"
            v-if="item.notaAlumnoCursado.condicion == 'abandono'"
          >
            <v-text-field
              :error="!!item.errorAbandono"
              :error-messages="item.errorAbandono"
              :disabled="esDisabled(item)"
              dense
              label="fecha abandono"
              outlined
              v-model="item.notaAlumnoCursado.fecha_abandono"
              type="date"
              @change="changeItem(item.notaAlumnoCursado)"
            ></v-text-field>
          </div>
          <div
            class="col p-0"
            v-if="item.notaAlumnoCursado.condicion == 'promocionado'"
          >
            <v-select
              :error="!!item.errorPromocion"
              :error-messages="item.errorPromocion"
              :disabled="esDisabled(item)"
              dense
              label="Nota"
              class=""
              outlined
              v-model="item.notaAlumnoCursado.nota_promocion"
              :items="notas"
              @change="changeItem(item.notaAlumnoCursado)"
            ></v-select>
          </div>
        </div>
      </template>
      <template v-slot:item.observaciones="{ item }">
        <div class="d-flex">
          <div class="">
            <v-switch
              :disabled="esDisabled(item)"
              dense
              v-model="item.notaAlumnoCursado.observaciones"
              @change="switchObservaciones(item)"
            ></v-switch>
          </div>
          <div v-if="mostrarObservaciones(item)">
            <v-textarea
              :disabled="esDisabled(item)"
              dense
              hide-details
              outlined
              label="Observaciones"
              v-model="item.notaAlumnoCursado.observaciones"
              @change="changeItem(item.notaAlumnoCursado)"
            ></v-textarea>
          </div>
        </div>
      </template>
      <template v-slot:item.cerrado="{ item }">
        <v-switch
          @change="changeItem(item.notaAlumnoCursado)"
          :true-value="1"
          :false-value="0"
          v-model="item.notaAlumnoCursado.cerrado"
          class="mt-0"
          label="cerrar"
        ></v-switch>
      </template>
      <template v-slot:item.notificado="{ item }">
        <v-switch
          @change="changeItem(item.notaAlumnoCursado)"
          :true-value="1"
          :false-value="0"
          v-model="item.notaAlumnoCursado.notificado"
          class="mt-0"
          label="notificado"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PlanillaDigitalCursadoSecretaria",
  props: {
    cursado_id: [String, Number],
  },
  data() {
    return {
      sort: "user.apellido",
      cerrar: false,
      opcion: null,
      condicion: [
        { text: "Aprobó", value: "aprobado" },
        { text: "Desaprobó", value: "desaprobado" },
        { text: "Promocionó", value: "promocionado" },
        { text: "No inició", value: "no inicio" },
        { text: "Abandonó", value: "abandono" },
        { text: "Cursado abierto", value: "sin informar" },
        { text: "Oyente", value: "oyente" },
      ],
      notas: [
        { text: "8", value: "8.00" },
        { text: "8,50", value: "8.50" },
        { text: "9", value: "9.00" },
        { text: "9,50", value: "9.50" },
        { text: "10", value: "10.00" },
      ],
      selectedItems: [],
    };
  },
  watch: {
    cerrar() {
      this.cerrarPlanilla(this.cerrar);
    },
    cursado() {
      this.getAlumnoCursados({
        filters: { cursado_id: this.cursado_id },
        expand: [
          "userCampus",
          "alumno",
          "cursado",
          "user",
          "otrosCursados",
        ].toString(),
      });
    },
    cursado_id() {
      this.getCursado(this.cursado_id());
    },
  },
  computed: {
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados"]),
    ...mapState("NewCursadoModule", ["cursado"]),

    aprobados() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "aprobado"
      ).length;
    },
    desaprobados() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "desaprobado"
      ).length;
    },
    poromocionados() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "promocionado"
      ).length;
    },
    noIniciaron() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "no inicio"
      ).length;
    },
    abandonaron() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "abandono"
      ).length;
    },
    sinInformar() {
      return this.alumnoCursados.filter(
        (el) => el.notaAlumnoCursado.condicion === "sin informar"
      ).length;
    },

    headers() {
      return [
        // { text: "Sin Informar", value: "sin_informar", filterable: true },
        { text: "id", value: "notaAlumnoCursado.id", filterable: true },
        { text: "Apellido", value: "user.apellido", filterable: true },
        { text: "Nombre", value: "user.nombre", filterable: true },
        { text: "Email", value: "user.email", filterable: true },
        { text: "Condicion", value: "condicion", filterable: true },
        // { text: "Aprobado", value: "aprobado", filterable: true },
        // { text: "Promociono", value: "promociono", filterable: true },
        // { text: "No Inicio", value: "noinicio", filterable: true },
        // { text: "Abandono", value: "abandono", filterable: true },
        { text: "Observaciones", value: "observaciones", filterable: true },
        { text: "Cerrado", value: "cerrado", filterable: true },
        { text: "Notificado", value: "notificado", filterable: true },
        // { text: "Promociono", value: "nota_promocion", filterable: true },
        // { text: "Telefono", value: "user.telefono", filterable: true },
        // { text: "Roles", value: "user.roles", filterable: true },
        // {
        //   text: "Acciones",
        //   value: "actions",
        //   filterable: false,
        //   nosearch: true,
        // },
      ];
    },
  },
  methods: {
    ...mapActions("DocenteCursadoModule", ["getPlanillaDigital"]),
    ...mapActions("NewAlumnoCursadoModule", ["getAlumnoCursados"]),
    ...mapActions("NewCursadoModule", ["getCursado"]),
    ...mapActions("NotaAlumnoCursadoModule", [
      "updateNotaAlumnoCursado",
      "addNotaAlumnoCursado",
    ]),

    clearErrors() {
      this.alumnoCursados.map(function (el) {
        el.error = null;
        el.errorPromocion = null;
        el.errorAbandono = null;
      });
    },

    validarCompleto() {
      let error = false;
      this.alumnoCursados.map(function (el) {
        let notaAlumno = el.notaAlumnoCursado;
        if (!notaAlumno.condicion) {
          el.error = "Debe Completar una condición del alumno";
          error = true;
        }
        if (
          notaAlumno.condicion == "promocionado" &&
          !notaAlumno.nota_promocion
        ) {
          el.errorPromocion = "Debe Ingresar una nota para la promoción";
          error = true;
        }
        if (notaAlumno.condicion == "abandono" && !notaAlumno.fecha_abandono) {
          el.errorAbandono = "Debe Ingresar una Fecha de abandono";
          error = true;
        }
      });
      this.alumnoCursados.splice(0, 0);

      return !error;
    },

    esDisabled(item) {
      return item.notaAlumnoCursado.cerrado ? true : false;
    },

    async cerrarPlanilla(flag) {
      if (!this.validarCompleto()) {
        console.log(console.log());
        return false;
      }
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text:
          "Antes de cerrar asegurese de que la planilla que imprimió concuerda con los datos que figuran en la pantalla. Una vez cerrada no podra modificarla" +
          "<br> Aprobados: " +
          this.aprobados +
          "<br> Desaprobados: " +
          this.desaprobados +
          "<br> Promocionados: " +
          this.poromocionados +
          "<br> No Iniciaron: " +
          this.noIniciaron +
          "<br> Abandonaron: " +
          this.abandonaron +
          "<br> Sin Informar: " +
          this.sinInformar,
        title: "Cerrar Planilla",
      });
      if (res) {
        this.cerrarTodas(flag);
      }
    },

    cerrarTodas(flag) {
      for (var i in this.alumnoCursados) {
        let item = this.alumnoCursados[i].notaAlumnoCursado;
        item.cerrado = flag;
        this.changeItem(item);
      }
    },

    getPlanillas() {
      if (!this.validarCompleto()) {
        console.log(console.log());
        return false;
      }
      const ids = [this.cursado_id];
      this.getPlanillaDigital({
        ids: ids,
        cursado: this.cursado.espacio.searchTag,
      });
    },

    cambiarTodos() {
      for (var i in this.selectedItems) {
        let item = this.selectedItems[i].notaAlumnoCursado;
        item.condicion = this.opcion;
        this.changeItem(item);
      }
      this.opcion = null;
      this.selectedItems = [];
    },

    switchObservaciones(item) {
      if (item.notaAlumnoCursado.observaciones) {
        item.notaAlumnoCursado.observaciones = " ";
      } else {
        item.notaAlumnoCursado.observaciones = null;
      }
      this.changeItem(item.notaAlumnoCursado);
    },

    mostrarObservaciones(item) {
      return this.tieneObservaciones(item);
    },

    tieneObservaciones(item) {
      return item.notaAlumnoCursado.observaciones;
    },

    async changeItem(item) {
      this.clearErrors();
      if (item.condicion != "promocionado") {
        item.nota_promocion = null;
      }
      if (item.condicion != "abandono") {
        item.fecha_abandono = null;
      }
      this.updateNotaAlumnoCursado(item);
    },
  },
  created() {
    this.getCursado(this.cursado_id);
  },
};
</script>

<style scoped></style>
