var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('my-modal',{attrs:{"dialog":_vm.dialogResponse},on:{"close":_vm.close}},[_c('template',{slot:"body"},[(_vm.importData)?_c('div',{staticClass:"mt-3"},[(_vm.importData.duplicados)?_c('div',{staticClass:"text-warning"},[_c('h4',{},[_vm._v("Registros duplicados")]),_c('v-data-table',{staticClass:"error-summary",attrs:{"headers":_vm.iheaders,"items":_vm.importData.duplicados},scopedSlots:_vm._u([{key:"item.errors",fn:function(ref){
var item = ref.item;
return _vm._l((item.errors),function(error,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(error)+" ")])})}}],null,false,300982782)})],1):_vm._e(),(_vm.importData.errores)?_c('div',{staticClass:"text-danger"},[_c('h4',{},[_vm._v("Registros con Errores")]),_c('v-data-table',{staticClass:"error-summary",attrs:{"headers":_vm.iheaders,"items":_vm.importData.errores},scopedSlots:_vm._u([{key:"item.errors",fn:function(ref){
var item = ref.item;
return _vm._l((item.errors),function(error,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(error)+" ")])})}}],null,false,300982782)})],1):_vm._e(),(_vm.importData.saved)?_c('div',{staticClass:"text-success"},[_c('h4',{},[_vm._v("Registros Guardados")]),_c('v-data-table',{staticClass:"error-summary",attrs:{"headers":_vm.iheaders,"items":_vm.importData.saved}})],1):_vm._e()]):_vm._e()])],2),_vm._t("dialog",function(){return [_c('my-modal',{attrs:{"eager":false,"dialog":_vm.dialog},on:{"close":_vm.close},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"footer-btn",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSubmit}},[_vm._v(" Guardar ")])]},proxy:true}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Ingresantes a Carreras")])]),_c('template',{slot:"body"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},[_vm._t("other-form",function(){return [_c('ingresante-form-carrera',{ref:"form",attrs:{"ingresante-edit":_vm.editModel,"only":_vm.only},scopedSlots:_vm._u([{key:"carrera_id",fn:function(ref){
var ingresante = ref.ingresante;
return [_c('v-select',{attrs:{"label":"Elija una carrera","items":_vm.carreras,"item-text":"nombre","item-value":"id"},model:{value:(ingresante.carrera_id),callback:function ($$v) {_vm.$set(ingresante, "carrera_id", $$v)},expression:"ingresante.carrera_id"}})]}},{key:"matriculado",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"true-value":'carrera',"false-value":0,"label":"matriculado"},model:{value:(item.matriculado),callback:function ($$v) {_vm.$set(item, "matriculado", $$v)},expression:"item.matriculado"}})]}}])})]})],2)],1)],2)]}),_c('h3',[_vm._v("Ingresantes a Carrera")]),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"mr-3"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1),_c('import-export',{staticClass:"mr-3",attrs:{"model-class":"Ingresantes","path":_vm.uploadPath,"only":_vm.only,"model-fields":_vm.fields,"plantilla":[_vm.ingresante]},on:{"close":_vm.close}}),(!_vm.matriculados)?_c('div',{staticClass:"mr-3"},[_c('v-btn',{attrs:{"outlined":"","color":"warning"},on:{"click":_vm.matricularIngresantes}},[_vm._v("Matricular")])],1):_vm._e(),_c('div',{staticClass:"mr-3"},[_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.borrarTodo}},[_vm._v("Borrar Seleccionados")])],1),_c('div',{staticClass:"mr-3"},[_c('v-switch',{attrs:{"true-value":"carrera","false-value":0,"label":"Matriculados"},model:{value:(_vm.matriculados),callback:function ($$v) {_vm.matriculados=$$v},expression:"matriculados"}})],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{key:_vm.tabla,attrs:{"search":_vm.search,"options":_vm.options,"server-items-length":_vm.pagination.totalCount,"show-select":true,"items":_vm.filterIngresantes,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item, index)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }