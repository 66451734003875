<template>
  <ingresante-a-b-m-cursado :headers="headers">
    <template v-slot:item.cursados="{ item }">
      <div v-for="cursado in item.cursados" :key="cursado.id">
        {{ cursado.nombre }}
      </div>
    </template>
  </ingresante-a-b-m-cursado>
</template>

<script>
import { mapState } from "vuex";
import IngresanteABMCursado from "@/views/ingresante/cursado/IngresanteABMCursado";

export default {
  name: "ingresantesCursado",
  components: { IngresanteABMCursado },
  data() {
    return {
      headers: [
        // {text:'Id',value:'id'},
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "email", value: "email" },
        { text: "Dni", value: "dni" },
        { text: "Telefono", value: "telefono" },
        { text: "Cursado", value: "cursados" },
        { text: "Matriculado", value: "matriculado" },
        { text: "Usuario", value: "tiene_usuario" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState("IngresanteModule", ["fields"]),
    // headers(){
    //   var items = [];
    //   for (var i in this.fields){
    //     items.push({
    //       value:i,text:this.fields[i]
    //     })
    //   }
    //   items.push( {text:'Acciones',value:'actions'});
    //   return items;
    // }
  },
};
</script>

<style scoped></style>
