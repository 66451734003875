<template>
  <alumno-cursado-a-b-m
    :headers="headers"
    :cursada_id="cursada_id"
    :only="only"
  >
    <template v-slot:item.carreras="{ item }">
      <span v-for="carrera in item.alumno.carreras" :key="carrera.id">{{
        carrera.codigo
      }}</span>
    </template>

    <template v-slot:item.email_enviado="{ item }">
      <v-chip :color="item.mail_enviado > 0 ? 'success' : ''">{{
        item.mail_enviado
      }}</v-chip>
    </template>
  </alumno-cursado-a-b-m>
</template>

<script>
import AlumnoCursadoABM from "@/views/alumno-cursado/AlumnoCursadoABM";

export default {
  name: "alumnoCursados",
  components: { AlumnoCursadoABM },
  props: {
    cursada_id: [String, Number],
  },
  data() {
    return {
      only: ["fecha_abandono", "observaciones"],
      headers: [
        { text: "Id", value: "id" },
        { text: "Carrera", value: "carreras" },
        // { text: "Estado", value: "estadoNombre" },
        { text: "Apellido", value: "alumno.apellido" },
        { text: "Nombre", value: "alumno.nombre" },
        // {text: 'Telefono', value: 'alumno.telefono'},
        { text: "Email", value: "alumno.email" },
        { text: "TP 1", value: "tp1" },
        { text: "TP 2", value: "tp2" },
        { text: "TP 3", value: "tp3" },
        { text: "TP 4", value: "tp4" },
        // {text: 'TP 1', value: 'tp1'},
        // {text: 'Cursado Aprobado', value: 'cursado_aprobado'},
        { text: "Nota", value: "nota_alumno" },
        { text: "Cursado Aprobado", value: "cursado_aprobado" },
        { text: "Abandono Fecha", value: "fecha_abandono" },
        { text: "Observaciones", value: "observaciones" },
        // {text: 'Cursado Aprobado', value: 'cursado_aprobado'},
        { text: "Email Enviado", value: "email_enviado" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
};
</script>

<style scoped></style>
