<template>
  <div>
    <v-dialog eager v-model="dialog" max-width="600">
      <corte-inscripcion-carrera-form
        v-if="id"
        ref="form"
        :id="id"
        @close="cerrar"
      >
        <template v-slot:activo="{ model }">
          <v-switch
            :true-value="1"
            :false-value="null"
            label="Activar"
            v-model="model.activo"
          ></v-switch>
        </template>
      </corte-inscripcion-carrera-form>
    </v-dialog>
    <v-card>
      <v-card-title>CorteInscripcionCarreras</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :loading="loading.status"
          :items="corteInscripcionCarreras"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <tr>
              <td v-for="(filterCol, i) in headers" :key="i">
                <v-text-field
                  v-show="filterCol.filterable"
                  hide-details
                  filled
                  :label="'Buscar por ' + filterCol.text"
                  v-model="filters[filterCol.value]"
                  clearable
                  @click:clear="reload"
                ></v-text-field>
              </td>
            </tr>
          </template>

          <template v-slot:item.activo="{ item }">
            <v-switch
              :false-value="null"
              :true-value="1"
              @change="updateItem(item)"
              v-model="item.activo"
              label="activo"
            ></v-switch>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import CorteInscripcionCarreraForm from "@/views/corte-inscripcion-carrera/CorteInscripcionCarreraForm";

export default {
  name: "CorteInscripcionCarreraABM",
  components: { CorteInscripcionCarreraForm },
  data() {
    return {
      dialog: false,
      id: null,
      options: {},
      expand: [],
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", [
      "corteInscripcionCarreras",
      "pagination",
    ]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Fecha Desde", value: "desde", filterable: true },
        { text: "Fecha Hasta", value: "hasta", filterable: true },
        // {text:'Mes',value:'mes',filterable:true},
        // {text:'Anio',value:'anio',filterable:true},
        { text: "Tipo", value: "tipo", filterable: true },
        { text: "Activo", value: "activo", filterable: true },
        { text: "Acciones", value: "actions", filterable: false },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
      "deleteCorteInscripcionCarrera",
      "updateCorteInscripcionCarrera",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteCorteInscripcionCarrera(id);
      }
    },
    async updateItem(item) {
      await this.updateCorteInscripcionCarrera(item);
      this.reload();
    },

    async reload() {
      this.loading.status = true;
      await this.getCorteInscripcionCarreras({
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
