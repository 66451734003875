<template>
  <div>
    <historial-cursado-por-a-lumno-form
      ref="form"
      :id="id"
      :alumno_id="alumno_id"
      @close="cerrar"
    >
    </historial-cursado-por-a-lumno-form>
    <v-card>
      <v-card-title>
        <!--        <div>-->
        <!--          <div>-->
        <!--            Historial Académico de {{ user.apellido }}, {{ user.nombre }}-->
        <!--          </div>-->
        <!--          <div class="subtitle-2">DNi: {{ user.dni }}</div>-->
        <!--          <div class="subtitle-2">Email: {{ user.email }}</div>-->
        <!--          <div class="subtitle-2">Telefono: {{ user.telefono }}</div>-->
        <!--        </div>-->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="filteredCursadas"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="item.cursado.cursado_historico">
              <v-icon color="info" class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="info" @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </div>
          </template>

          <template v-slot:item.historico="{ item }">
            <div v-if="item.cursado.cursado_historico">Carga Historica</div>
            <div v-else>No</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
import HistorialCursadoPorALumnoForm from "@/viewsNew/historial-cursado-por-alumno/HistorialCursadoPorAlumnoForm";

export default {
  name: "HistorialCursadoPorALumnoABM",
  components: { HistorialCursadoPorALumnoForm, FilterRow },
  props: {
    alumno_id: [String, Number],
  },
  data() {
    return {
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        condicion: ["aprobado", "promocionado"],
        joins: ["alumnoCursado"],
      },
      expand: ["cursado"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["user"]),

    ...mapState("NotaAlumnoCursadoModule", [
      "notaAlumnoCursados",
      "pagination",
    ]),
    ...mapState(["search", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    filteredCursadas() {
      return this.notaAlumnoCursados.filter(
        (el) => el.condicion == "aprobado" || el.condicion == "promocionado"
      );
    },

    headers() {
      return [
        // { text: "Id", value: "id", filterable: true },
        { text: "Cursado", value: "cursado.espacio.nombre", filterable: true },
        { text: "Condicion", value: "condicion", filterable: true },
        // { text: "Nota Promoción", value: "nota_promocion", filterable: true },
        { text: "Fin cursado", value: "cursado.fecha_fin", filterable: true },
        {
          text: "Vencimiento",
          value: "cursado.fecha_vencimiento",
          filterable: true,
        },
        { text: "Carga Historica", value: "historico", filterable: true },

        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUser"]),
    ...mapActions("NotaAlumnoCursadoModule", [
      "getNotaAlumnoCursados",
      "deleteNotaAlumnoCursado",
      "deleteFichaAcademica",
      "getNotaAlumnoCursadosAprobados",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = { carrera: {}, cursado: {} };
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteFichaAcademica(id);
      }
    },

    async reload() {
      this.loading.status = true;
      this.filters.alumno_id = this.alumno_id;
      // this.options.condicion = ['aprobado','promocionado'];
      // this.filters.condicion = "aprobado";
      await this.getNotaAlumnoCursadosAprobados({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    // this.getUser(this.alumno_id);
    // this.reload();
  },
};
</script>

<style scoped></style>
