<template>
  <div>
    <historial-examen-por-alumno-form
      ref="form"
      :id="id"
      :alumno_id="alumno_id"
      @close="cerrar"
    >
    </historial-examen-por-alumno-form>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="filteredItems"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="esHistorico(item)">
              <v-icon color="info" class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="info" @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
import HistorialExamenPorAlumnoForm from "@/viewsNew/historial-examen-por-alumno/HistorialExamenPorAlumnoForm";

export default {
  name: "HistorialExamenPorALumnoABM",
  components: { HistorialExamenPorAlumnoForm, FilterRow },
  props: {
    alumno_id: [String, Number],
  },
  data() {
    return {
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        estado: ["aprobado", "promocionado"],
        joins: ["alumnoExamen"],
      },
      expand: ["alumnoExamen"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["user"]),

    ...mapState("NotaAlumnoExamenModule", [
      "notaAlumnoExamens",
      "notaAlumnoExamen",
      "pagination",
    ]),
    ...mapState(["search", "loading", "dialogs"]),

    filteredItems() {
      return this.notaAlumnoExamens.filter(
        (el) => el.estado == "aprobado" || el.estado == "promocionado"
      );
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        // { text: "Id", value: "id", filterable: true },
        {
          text: "Espacio",
          value: "alumnoExamen.materia.nombre",
          filterable: true,
        },
        { text: "Nota", value: "nota", filterable: true },
        // { text: "Estado", value: "estado", filterable: true },
        { text: "Fecha", value: "alumnoExamen.examen.fecha", filterable: true },
        { text: "Turno", value: "alumnoExamen.examen.turno", filterable: true },
        {
          text: "Condicion",
          value: "alumnoExamen.condicion",
          filterable: true,
        },
        {
          text: "Libro",
          value: "alumnoExamen.examen.libro_foja",
          filterable: true,
        },
        // { text: "Nota Promoción", value: "nota_promocion", filterable: true },
        // { text: "Fin cursado", value: "cursado.fecha_fin", filterable: true },
        // { text: "Vencimiento", value: "cursado.fecha_vencimiento", filterable: true },

        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUser"]),
    ...mapActions("NotaAlumnoExamenModule", [
      "deleteFichaAcademica",
      "getNotaAlumnoExamens",
      "getNotaAlumnoExamen",
      "deleteNotaAlumnoExamen",
      "updateNotaAlumnoExamen",
      "getNotaAlumnoExamensAprobados",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    esHistorico(item) {
      if (item?.alumnoExamen?.examen?.turno === "Carga Historico") {
        return true;
      }
      return false;
    },

    newItem() {
      this.$refs.form.model = { carrera: {}, examen: {}, alumnoExamen: {} };
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteFichaAcademica(id);
      }
    },

    async reload() {
      this.loading.status = true;
      this.filters.alumno_id = this.alumno_id;
      // this.filters.cerrado = 1;
      // this.option.estado = ['aprobado','promocionado'];

      await this.getNotaAlumnoExamensAprobados({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getUser(this.alumno_id);
  },
};
</script>

<style scoped></style>
