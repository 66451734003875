<template>
  <div>
    <!--    <vue-confirm-dialog></vue-confirm-dialog>-->
    <v-app-bar app color="dark" dark elevation="3">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>

      <v-app-bar-title>
        <v-alert v-if="test" color="light-green"
          >Esta Página es un Test No es la original</v-alert
        >

        <div v-else class="font-weight-normal">Secretaria</div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="logout">Logout</v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
    >
      <v-list dense color="secondary" dark>
        <v-list-item>
          <v-list-item-action>
            <v-icon @click.stop="sidebarMenu = !sidebarMenu"
              >mdi-chevron-left</v-icon
            >
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex align-items-center">
                <img
                  :src="require('@/assets/logoifdc3.png')"
                  height="50"
                  width="50"
                  class="mr-3"
                />
                <div class="text-center w-100">
                  <div>San Antonio</div>
                  <div>Oeste</div>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item class="px-2" @click="toggleMini = !toggleMini">
        <v-list-item-avatar>
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="text-truncate">
          <v-list-item-title>
            {{ usuario.nombre }} {{ usuario.apellido }}
          </v-list-item-title>
        </v-list-item-content>
        <v-btn icon small>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="min-height: 100vh">
      <div class="container-fluid">
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <router-view
                v-if="user.roles.length > 0"
                :user="user"
                :key="$route.fullPath"
              />
            </transition>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <v-footer class="py-3">
      <!--      <span class="ml-auto overline">Iatek &copy;2020</span>-->
    </v-footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import authService from "@/services/auth.service";
export default {
  name: "SideBarLayout",
  // components: {Navbar},
  computed: {
    ...mapState("UserModule", ["user"]),
    isLoggedIn() {
      return authService.isLoggedIn();
    },
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    buttonText() {
      return !this.$vuetify.theme.dark ? "Go Dark" : "Go Light";
    },
    usuario() {
      if (this.user) {
        return this.user;
      }
      return "";
    },
  },
  data() {
    return {
      test: parseInt(process.env.VUE_APP_TEST),
      // user: null,
      overlay: false,
      loading: {},
      sidebarMenu: true,
      toggleMini: false,
      items: [
        // {
        //   title: "Perfil",
        //   href: "/perfil",
        //   icon: "mdi-account",
        // },
        {
          title: "Carreras",
          href: "/vuex/carreras",
          icon: "mdi-school",
        },
        {
          title: "Espacios",
          href: "/espacios",
          icon: "mdi-school",
        },
        // {
        //   title: "UCurriculares",
        //   href: "/vuex/u-curricular-carrera",
        //   icon: "mdi-shield-account",
        // },
        // {
        //   title: "Cursada",
        //   href: "/vuex/cursadas",
        //   icon: "mdi-google-classroom",
        // },
        {
          title: "Examenes",
          href: "/examenes",
          icon: "mdi-note-text",
        },
        {
          title: "Cursadas",
          href: "/cursadas",
          icon: "mdi-note-text",
        },
        // {
        //   title: "Superpuestos",
        //   href: "/alumno-cursado-superpuesto",
        //   icon: "mdi-note-text",
        // },
        // {title:'Inscripciones',href:'/inscripciones-vigentes',icon:"mdi-shield-account"},
        {
          title: "Estudiantes",
          href: "/usuarios-por-rol",
          icon: "mdi-account-group",
        },
        {
          title: "Docentes",
          href: "/docentes",
          icon: "mdi-account-group",
        },

        {
          title: "Ingresantes",
          href: "/ingresantes",
          icon: "mdi-account-group",
        },
        // {
        //   title: "Ingresantes Presistema",
        //   href: "/ingresantes-presistema",
        //   icon: "mdi-account-group",
        // },
        {
          title: "Cohortes Inscripción",
          href: "/inscripciones",
          icon: "mdi-account-group",
        },
        {
          title: "Promocionados",
          href: "/promocionados",
          icon: "mdi-account-group",
        },
        {
          title: "Programas de Examen",
          href: "/programa-examenes",
          icon: "mdi-account-group",
        },
        {
          title: "Usuarios",
          href: "/usuarios",
          icon: "mdi-account-group",
        },
        {
          title: "Alumnos Por Carreras",
          href: "/alumnos-carrera",
          icon: "mdi-account-group",
        },
        {
          title: "Alumnos Por Materia",
          href: "/alumno-cursado",
          icon: "mdi-account-group",
        },
        {
          title: "LENS",
          target: "_blank",
          href: "https://lens.ifdcsao.edu.ar",
          icon: "mdi-account-group",
        },
      ],
    };
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  async created() {
    await this.getUser();
    if (this.user.verificado === null) {
      this.$router.push({ name: "Perfil" });
    }
  },
  methods: {
    ...mapActions("UserModule", ["getUser"]),
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    showLoader() {
      this.loading = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        // container: this.$refs.formContainer,
        color: "#017BFF",
        loader: "dots",
        width: 128,
        height: 128,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        canCancel: true,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loading.hide();
    },
    hideLoader2() {
      // console.log("hide loading");
      this.overlay = false;
    },
    showLoader2() {
      // console.log("show loading");
      this.overlay = true;
    },
    async logout() {
      await authService.logout();
      this.$router.push({ name: "login" });
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.theme--light.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--light.v-application ::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.theme--light.v-application ::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.theme--light.v-application ::-webkit-scrollbar-thumb:hover {
  background: black;
}

.theme--dark.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--dark.v-application ::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>
