<template>
  <v-card>
    <v-card-title>
      Agregar Usuarios
      <v-btn link icon absolute top right @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            label="Buscar por campo"
            v-model="search.tag"
            clearable
            @click:clear="reload"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        show-select
        v-model="selectedItems"
        :loading="loading.status"
        :items="users"
        :headers="headers"
        :server-items-length="pagination.totalCount"
        :options.sync="options"
      >
        <template v-slot:item.carrerasInscripto="{ item }">
          <div v-for="carrera in item.carrerasInscripto" :key="carrera.id">
            {{ carrera.codigo }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="agregarUsuarios">Agregar</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "AgregarUsuarios",
  data() {
    return {
      search: {},
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {},
      expand: ["roles", "carrerasInscripto"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users", "pagination"]),
    ...mapState(["loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Carreras",
          value: "carrerasInscripto",
          nosearch: true,
          filterable: true,
        },
        { text: "Roles", value: "roles", nosearch: true, filterable: true },
        // { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsers", "deleteUser"]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    agregarUsuarios() {
      this.$emit("usuarios", this.selectedItems);
    },

    newItem() {
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsers({
        like: this.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
