var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('carrera-a-b-m',{attrs:{"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.alumnos",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/alumnos-carrera/' + item.id)}}},[_vm._v(" Alumnos ")])]}},{key:"item.materias",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"warning"},on:{"click":function($event){return _vm.$router.push({
          name: 'materias-por-carrera',
          params: { carrera_id: item.id },
        })}}},[_vm._v(" Materias ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }