<template>
  <div>
    <v-dialog v-model="turnoDialog" max-width="600">
      <turno-form @close="turnoDialog = false"></turno-form>
    </v-dialog>
    <v-dialog persistent eager v-model="dialog" max-width="1200">
      <alumno-carrera-form
        :inscripcion_id="inscripcionId"
        ref="form"
        :id="id"
        :selected="alumno_id"
        @close="cerrar"
      >
        <template v-slot:estado="{ model }">
          <v-switch
            :true-value="1"
            :false-value="0"
            v-model="model.estado"
            label="Matriculado"
          ></v-switch>
        </template>
        <template v-slot:condicion="{ model }">
          <v-select
            clearable
            label="Condición"
            outlined
            v-model="model.condicion"
            :items="model.condiciones"
          ></v-select>
        </template>
        <template v-slot:turno_id="{ model }">
          <v-select
            clearable
            label="Turno"
            outlined
            v-model="model.turno_id"
            :items="turnos"
            item-text="nombre"
            item-value="id"
          ></v-select>
        </template>
      </alumno-carrera-form>
    </v-dialog>
    <v-card width="100%">
      <v-card-title>Inscripción a carrera de Alumnos Presistema</v-card-title>
      <v-card-text>
        <div class="d-flex mis-acciones pb-3">
          <!--          <div>-->
          <!--            <v-select dense outlined hide-details :items="corteInscripcionCarreras" item-value="id" item-text="nombre" v-model="inscripcion_id"></v-select>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-btn @click="matricular" outlined color="info">Matricular</v-btn>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-btn x-large @click="turnoDialog = true" outlined color="warning"-->
          <!--              >Nuevo turno</v-btn-->
          <!--            >-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-select-->
          <!--              hide-details-->
          <!--              :height="36"-->
          <!--              outlined-->
          <!--              placeholder="Seleccione un turno"-->
          <!--              clearable-->
          <!--              v-model="turno"-->
          <!--              item-text="searchTag"-->
          <!--              item-value="id"-->
          <!--              :items="turnos"-->
          <!--            ></v-select>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-btn x-large dark color="black" @click="asignarTurno"-->
          <!--              >Asignar Turno</v-btn-->
          <!--            >-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-btn x-large dark color="error" @click="borrarTurno"-->
          <!--              >Borrar Turno</v-btn-->
          <!--            >-->
          <!--          </div>-->
          <v-spacer></v-spacer>
          <div>
            <download-excel :data="excel">
              <v-btn x-large dark color="success">Exportar</v-btn>
            </download-excel>
          </div>
        </div>

        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCarreras"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:carrera.codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <!--              <template v-slot:u_curricular_carrera.nombre="{}">-->
              <!--                <v-text-field @change="reload" v-model="search.tag" ></v-text-field>-->
              <!--              </template>-->
            </filter-row>
            <!--            <tr>-->
            <!--              <td></td>-->
            <!--              <td v-for="(filterCol,i) in headers" :key="i">-->
            <!--                <v-text-field v-show="filterCol.filterable" hide-details  filled  :label="'Buscar por '+filterCol.text"  v-model="like[filterCol.table?filterCol.table:filterCol.value]" clearable @click:clear="reload"></v-text-field>-->
            <!--              </td>-->
            <!--            </tr>-->
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import FilterRow from "@/viewsNew/utils/FilterRow";
import TurnoForm from "@/views/turno/TurnoForm";
import AlumnoCarreraForm from "@/viewsNew/alumno-carrera/AlumnoCarreraForm";

export default {
  name: "PresistemaCarreraABM",
  components: { AlumnoCarreraForm, TurnoForm, FilterRow },
  data() {
    return {
      turnoDialog: false,
      turno: null,
      inscripcion_id: null,
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      alumno_id: null,
      options: {
        joins: ["carrera", "alumno"],
      },
      expand: ["carrera", "alumno", "turno", "user"],
    };
  },
  computed: {
    ...mapState("TurnoModule", ["turnos"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarrera"]),
    ...mapState("NewAlumnoCarreraModule", ["alumnoCarreras", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    inscripcionId() {
      return this.corteInscripcionCarrera.id
        ? this.corteInscripcionCarrera.id
        : null;
    },

    excel() {
      const data = [];
      for (var i in this.alumnoCarreras) {
        var registro = {
          nombre: this.alumnoCarreras[i].alumno.nombre,
          apellido: this.alumnoCarreras[i].alumno.apellido,
          email: this.alumnoCarreras[i].alumno.email,
          dni: this.alumnoCarreras[i].alumno.dni,
          telefono: this.alumnoCarreras[i].alumno.telefono,
          carrera: this.alumnoCarreras[i].carrera.codigo,
          rol: this.alumnoCarreras[i].alumno.roles,
          turno: this.alumnoCarreras[i].turno
            ? this.alumnoCarreras[i].turno.searchTag
            : "",
        };
        data.push(registro);
      }
      return data;
    },

    codigos() {
      return this.carreras.map((el) => el.codigo);
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        {
          text: "Id",
          value: "id",
          filterable: true,
          table: "alumno_carrera.id",
        },
        {
          text: "Nombre",
          value: "user.nombre",
          table: "user.nombre",
          filterable: true,
        },
        {
          text: "Apellido",
          value: "user.apellido",
          table: "user.apellido",
          filterable: true,
        },
        {
          text: "email",
          value: "user.email",
          table: "user.email",
          filterable: true,
        },
        { text: "Dni", value: "user.dni", table: "user.dni", filterable: true },
        {
          text: "Telefono",
          value: "user.telefono",
          table: "user.telefono",
          filterable: true,
        },
        {
          text: "Carrera",
          value: "carrera.codigo",
          table: "carrera.codigo",
          filterable: true,
          nosearch: true,
        },
        {
          text: "Rol",
          value: "user.roles",
          table: "user.roles",
          filterable: false,
          nosearch: true,
        },
        // { text: "Documentacion", value: "documentacion" },
        { text: "Condicion", value: "condicion", filterable: true },

        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      filters: {
        handler() {
          this.setFilters();
          this.reload();
        },
        deep: true,
      },
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("TurnoModule", ["getTurnos"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreraActivo",
    ]),
    ...mapActions("NewAlumnoCarreraModule", [
      "getAlumnoCarreras",
      "deleteAlumnoCarrera",
    ]),
    ...mapActions("AlumnoCarreraModule", [
      "sendMailTurno",
      "updateAlumnoCarrera",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    async borrarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = null;
        await this.updateAlumnoCarrera(this.selectedItems[i]);
        // this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    async asignarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = this.turno;
        var alumnoCarrera = await this.updateAlumnoCarrera(
          this.selectedItems[i]
        );
        this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    matricular() {},

    newItem() {
      this.$refs.form.model = {};
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.alumno_id = item.alumno_id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCarrera(id);
      }
    },

    async reload() {
      this.getTurnos();
      this.loading.status = true;
      await this.getAlumnoCarreras({
        like: this.$refs.filterRow.like,
        options: { ...this.options, roles: ["alumno-presistema"] },
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  created() {
    this.getCorteInscripcionCarreraActivo({
      filters: { activo: 1, tipo: "alumno-presistema" },
    });
    this.getCarreras();
    this.options.roles = ["alumno-presistema"];
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped>
.mis-acciones > div {
  margin: 0 0.5rem;
}
</style>
