import Vue from "vue";
import { httpClient } from "@/apis/Api";

const END_POINT = "new-alumno-cursados";

export default {
  superpuestos() {
    return Vue.prototype.$axios.get(END_POINT + "/custom/superpuestos");
  },

  misCursadas(bundle) {
    const params = {
      expand: bundle.expand,
    };
    return Vue.prototype.$axios.get(END_POINT + "/custom/poralumno", {
      params,
    });
  },

  sendMailInscripcion(id) {
    const params = {
      id: id,
    };
    return httpClient.get(END_POINT + "/custom/sendmailinscripcion", {
      params: params,
    });
  },

  customQuery() {
    const params = {};
    return Vue.prototype.$axios.get(END_POINT + "/custom/query", { params });
  },
};
