var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.turnoDialog),callback:function ($$v) {_vm.turnoDialog=$$v},expression:"turnoDialog"}},[_c('turno-form',{on:{"close":function($event){_vm.turnoDialog = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","eager":"","max-width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('alumno-carrera-form',{ref:"form",attrs:{"inscripcion_id":_vm.inscripcionId,"id":_vm.id,"selected":_vm.alumno_id},on:{"close":_vm.cerrar},scopedSlots:_vm._u([{key:"estado",fn:function(ref){
var model = ref.model;
return [_c('v-switch',{attrs:{"true-value":1,"false-value":0,"label":"Matriculado"},model:{value:(model.estado),callback:function ($$v) {_vm.$set(model, "estado", $$v)},expression:"model.estado"}})]}},{key:"condicion",fn:function(ref){
var model = ref.model;
return [_c('v-select',{attrs:{"clearable":"","label":"Condición","outlined":"","items":model.condiciones},model:{value:(model.condicion),callback:function ($$v) {_vm.$set(model, "condicion", $$v)},expression:"model.condicion"}})]}},{key:"turno_id",fn:function(ref){
var model = ref.model;
return [_c('v-select',{attrs:{"clearable":"","label":"Turno","outlined":"","items":_vm.turnos,"item-text":"nombre","item-value":"id"},model:{value:(model.turno_id),callback:function ($$v) {_vm.$set(model, "turno_id", $$v)},expression:"model.turno_id"}})]}}])})],1),_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v("Inscripción a carrera de Alumnos Presistema")]),_c('v-card-text',[_c('div',{staticClass:"d-flex mis-acciones pb-3"},[_c('v-spacer'),_c('div',[_c('download-excel',{attrs:{"data":_vm.excel}},[_c('v-btn',{attrs:{"x-large":"","dark":"","color":"success"}},[_vm._v("Exportar")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"show-select":"","loading":_vm.loading.status,"items":_vm.alumnoCarreras,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('filter-row',{ref:"filterRow",attrs:{"headers":_vm.headers},on:{"reload":_vm.reload},scopedSlots:_vm._u([{key:"carrera.codigo",fn:function(){return [_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","clearable":"","items":_vm.codigos},model:{value:(_vm.filters['carrera.codigo']),callback:function ($$v) {_vm.$set(_vm.filters, 'carrera.codigo', $$v)},expression:"filters['carrera.codigo']"}})]},proxy:true}])})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }