<template>
  <ingresante-a-b-m-carrera :headers="headers"> </ingresante-a-b-m-carrera>
</template>

<script>
import { mapState } from "vuex";
import IngresanteABMCarrera from "@/views/ingresante/carrera/IngresanteABMCarrera";

export default {
  name: "ingresantesCarrera",
  components: { IngresanteABMCarrera },
  data() {
    return {
      headers: [
        // {text:'Id',value:'id'},
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "email", value: "email" },
        { text: "Dni", value: "dni" },
        { text: "Telefono", value: "telefono" },
        { text: "Carrera", value: "carrera.codigo" },
        // { text: "Matriculado", value: "matriculado" },
        // { text: "Usuario", value: "tiene_usuario" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState("UserModule", ["fields"]),
  },
};
</script>

<style scoped></style>
