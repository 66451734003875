var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"eager":"","max-width":"600"},model:{value:(_vm.dialogDatos),callback:function ($$v) {_vm.dialogDatos=$$v},expression:"dialogDatos"}},[_c('DatosAuxiliaresExamen',{on:{"close":function($event){_vm.dialogDatos = false},"completado":_vm.getActaExamen}})],1),_c('h3',[_vm._v(_vm._s(_vm.examen.materia.searchTag))]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('v-select',{staticClass:"mr-1",attrs:{"dense":"","label":"Estado","hide-details":"","outlined":"","items":_vm.estado},model:{value:(_vm.opcion),callback:function ($$v) {_vm.opcion=$$v},expression:"opcion"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.cambiarTodos}},[_vm._v("ejecutar")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.cerrarPlanilla(1)}}},[_vm._v("Cerrar")])],1),_c('div',{staticClass:"mx-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"true-value":1,"false-value":0,"label":"cerrar"},model:{value:(_vm.cerrar),callback:function ($$v) {_vm.cerrar=$$v},expression:"cerrar"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.cargarProm}},[_vm._v("Cargar Promocionados")])],1)]),_c('v-data-table',{attrs:{"sort-by":_vm.sort,"show-select":"","items":_vm.alumnoExamens,"headers":_vm.headers,"items-per-page":-1},on:{"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event}},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"disabled":_vm.esDisabled(item),"error":!!item.error,"error-messages":item.error,"dense":"","outlined":"","clearable":"","items":_vm.estado},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoExamen)}},model:{value:(item.notaAlumnoExamen.estado),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "estado", $$v)},expression:"item.notaAlumnoExamen.estado"}})]}},{key:"item.nota_oral",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.esDisabled(item),"clearable":"","placeholder":"Ingrese nota Oral","outlined":"","dense":""},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoExamen)}},model:{value:(item.notaAlumnoExamen.nota_oral),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "nota_oral", $$v)},expression:"item.notaAlumnoExamen.nota_oral"}})]}},{key:"item.nota_escrito",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.esDisabled(item),"clearable":"","placeholder":"Ingrese nota Escrito","outlined":"","dense":""},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoExamen)}},model:{value:(item.notaAlumnoExamen.nota_escrito),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "nota_escrito", $$v)},expression:"item.notaAlumnoExamen.nota_escrito"}})]}},{key:"item.nota",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.esDisabled(item),"clearable":"","placeholder":"Ingrese nota Final","outlined":"","dense":""},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoExamen)}},model:{value:(item.notaAlumnoExamen.nota),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "nota", $$v)},expression:"item.notaAlumnoExamen.nota"}})]}},{key:"item.observaciones",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('v-switch',{attrs:{"disabled":_vm.esDisabled(item),"dense":""},on:{"change":function($event){return _vm.switchObservaciones(item)}},model:{value:(item.notaAlumnoExamen.observaciones),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "observaciones", $$v)},expression:"item.notaAlumnoExamen.observaciones"}})],1),(_vm.mostrarObservaciones(item))?_c('div',[_c('v-textarea',{attrs:{"disabled":_vm.esDisabled(item),"dense":"","hide-details":"","outlined":"","label":"Observaciones"},on:{"change":function($event){return _vm.changeItem(item.notaAlumnoExamen)}},model:{value:(item.notaAlumnoExamen.observaciones),callback:function ($$v) {_vm.$set(item.notaAlumnoExamen, "observaciones", $$v)},expression:"item.notaAlumnoExamen.observaciones"}})],1):_vm._e()])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }