<template>
  <div>
    <por-rol-user-form :id="id" ref="form"></por-rol-user-form>
    <v-card>
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div class="">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </div>
          <div class="mx-3">
            <v-switch
              v-model="multicarrera"
              class="mt-0"
              dense
              hide-details
              label="multi Carrera"
            ></v-switch>
          </div>
          <div class="mx-3">
            <v-switch
              v-model="sinUserCampus"
              class="mt-0"
              dense
              hide-details
              label="sin User en Campus"
            ></v-switch>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex mx-2">
            <v-select
              label="Ejecutar en todos"
              dense
              outlined
              hide-details
              :items="acciones"
              v-model="accion"
            ></v-select>
            <v-btn color="primary" @click="ejecutarAccion">Ejecutar</v-btn>
          </div>
          <!--          <div>-->
          <!--            <v-select-->
          <!--              dense-->
          <!--              hide-details-->
          <!--              @change="reload"-->
          <!--              v-model="rolesModel"-->
          <!--              multiple-->
          <!--              label="Ingrese rol"-->
          <!--              outlined-->
          <!--              :items="roles"-->
          <!--            ></v-select>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-autocomplete-->
          <!--                dense-->
          <!--                hide-details-->
          <!--                @change="reload"-->
          <!--                v-model="carreraModel"-->
          <!--                label="Ingrese Carrera"-->
          <!--                outlined-->
          <!--                :items="carreras"-->
          <!--                item-value="id"-->
          <!--                item-text="nombre"-->
          <!--            ></v-autocomplete>-->
          <!--          </div>-->
          <div>
            <download-excel :data="excel">
              <v-btn dark color="success">Exportar</v-btn>
            </download-excel>
          </div>
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [
              10,
              20,
              100,
              200,
              500,
              { value: -1, text: 'All' },
            ],
          }"
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="usuarios"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:roles="{}">
                <div>
                  <v-select
                    clearable
                    dense
                    hide-details
                    @change="reload"
                    v-model="rolesModel"
                    multiple
                    label="Ingrese rol"
                    outlined
                    :items="roles"
                  ></v-select>
                </div>
              </template>
              <template v-slot:carrera.codigo="{}">
                <v-autocomplete
                  clearable
                  dense
                  hide-details
                  multiple
                  v-model="options.carrera"
                  label="Ingrese Carrera"
                  outlined
                  :items="carreras"
                  item-value="id"
                  item-text="nombre"
                ></v-autocomplete>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.telefono"></whatsapp-button>
          </template>
          <template v-slot:item.carrera.codigo="{ item }">
            <v-btn dark target="_blank" :href="/carreras-por-alumno/ + item.id">
              <div
                v-for="(carrera, i) in item.preinscripcion"
                :key="carrera.id"
              >
                <span v-if="i > 0">-</span>
                {{ carrera.codigo }}
              </div>
            </v-btn>
          </template>
          <template v-slot:item.user.created_at="{ item }">
            {{ item.creado }}
          </template>
          <template v-slot:item.cursados="{ item }">
            <v-btn
              dark
              small
              target="_blank"
              :href="'/por-alumno-cursada/' + item.id"
              color="purple"
              >Cursados</v-btn
            >
          </template>
          <template v-slot:item.examenes="{ item }">
            <v-btn
              dark
              small
              target="_blank"
              :href="'/por-alumno-examen/' + item.id"
              color="teal"
              >Examenes</v-btn
            >
          </template>
          <template v-slot:item.historial="{ item }">
            <v-btn
              dark
              small
              target="_blank"
              :href="'/historial-academico/' + item.id"
              color="info"
              >Historial</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import PorRolUserForm from "@/viewsNew/user/PorRolUser/PorRolUserForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";

export default {
  name: "PorRolUserABM",
  components: { WhatsappButton, FilterRow, PorRolUserForm },
  data() {
    return {
      selectedItems: [],
      acciones: [{ value: 3, text: "Eliminar Seleccionados" }],
      accion: null,
      sinUserCampus: false,
      multicarrera: false,
      rolesModel: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: ["carreras"],
      },
      expand: [
        // "userCampus",
        // "roles",
        "preinscripcion",
        // "carrerasInscripto"
      ],
    };
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewUserModule", ["users", "pagination", "roles"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    usuarios() {
      // return this.users;
      return this.users.filter(
        (el) =>
          (!this.multicarrera || (el.carreras && el.carreras.length >= 2)) &&
          (!this.sinUserCampus || !el.userCampus)
      );
    },

    excel() {
      let items = [];
      for (var i in this.usuarios) {
        let item = { ...this.usuarios[i] };

        items.push(item);
      }
      return items;
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true, table: "user.id" },
        // {
        //   text: "Campus",
        //   value: "userCampus",
        //   filterable: false,
        //   nosearch: true,
        // },
        { text: "Registro", value: "user.created_at", filterable: true },
        {
          text: "Nombre",
          value: "nombre",
          filterable: true,
          table: "user.nombre",
        },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Carreras Inscripto",
          value: "carrera.codigo",
          nosearch: true,
          filterable: true,
        },
        // { text: "Roles", value: "roles", nosearch: true, filterable: true },
        { text: "Cursados", value: "cursados", nosearch: true },
        { text: "Examenes", value: "examenes", nosearch: true },
        { text: "Historial", value: "historial", nosearch: true },

        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    multicarrera() {
      if (this.multicarrera) {
        this.options.itemsPerPage = -1;
      } else {
        this.options.itemsPerPage = 10;
      }
      this.reload();
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewUserModule", [
      "getUsers",
      "deleteUser",
      "updateUser",
      "getUsersPorRoles",
      "getRoles",
      "deleteAll",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    ejecutarAccion() {
      switch (this.accion) {
        case 3:
          this.borrarSeleccionados();
          break;
        default:
          return;
      }
      // this.selectedItems = [];
      // this.action = null;
    },

    async borrarSeleccionados() {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar todos los registros?",
        title: "Eliminar Registro",
      });
      if (res) {
        await this.deleteAll(this.selectedItems);
        this.reload();
        this.selectedItems = [];
        this.accion = null;
      }
    },

    async cambiarEstado(flag) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea cambiar el estado del usuario?",
        title: "Cambiar Estado Usuario",
      });
      if (res) {
        for (var i in this.selectedItems) {
          let model = this.selectedItems[i];
          model.estado = flag;
          this.updateUser(model);
        }
        this.selectedItems = [];
        this.accion = null;
      }
    },

    newItem() {
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsersPorRoles({
        roles: this.rolesModel,
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.getRoles();
    this.clearFilters();
  },
  created() {
    this.getCarreras();
  },
};
</script>

<style scoped></style>
