<template>
  <v-dialog persistent eager v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Cursado Alumno
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carrera.id"
            :items="filteredCarreras"
            item-text="nombre"
            item-value="id"
            @change="getMaterias"
          />
          <v-autocomplete
            placeholder="Seleccione Materia"
            outlined
            v-model="model.examen.materia_id"
            :items="filteredMaterias"
            item-text="searchTag"
            item-value="id"
          ></v-autocomplete>

          <v-text-field
            clearable
            outlined
            label="Ingrese Fecha del Examen"
            type="date"
            v-model="model.date"
            :onemptied="null"
          ></v-text-field>
          <v-text-field
            outlined
            v-model="model.nota"
            label="ingrese Nota Examen"
          ></v-text-field>
          <v-select
            clearable
            :items="['regular', 'libre', 'promocion', 'equivalencia']"
            outlined
            v-model="model.alumnoExamen.condicion"
            label="ingrese Condicion de examen"
          ></v-select>
          <v-text-field
            outlined
            v-model="model.examen.libro_foja"
            label="ingrese Libro / Foja"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "HistorialExamenPorALumnoForm",
  props: {
    id: [String, Number],
    alumno_id: [String, Number],
  },
  data() {
    return {
      dialog: false,
      only: [],
      exclude: [],
      model: {
        alumnoExamen: {},
        examen: {},
        carrera: {},
      },
    };
  },
  computed: {
    ...mapState("NewUserModule", ["user"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("NotaAlumnoExamenModule", ["errors"]),
    ...mapState("NotaAlumnoExamenModule", [
      "fields",
      "notaAlumnoExamen",
      "record",
    ]),

    filteredMaterias() {
      return this.uCurricularCarreras.filter(
        (el) => el.carrera_id == this.model.carrera.id
      );
    },

    filteredCarreras() {
      return this.user.carreras ? this.user.carreras : [];
    },

    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NotaAlumnoExamenModule", [
      "addFichaAcademica",
      "updateFichaAcademica",
      "deleteFichaAcademica",
      "getFields",
      "getNotaAlumnoExamen",
      "getNotaAlumnoExamenWithParams",
      "updateNotaAlumnoExamen",
      "addNotaAlumnoExamen",
    ]),
    async getModel() {
      await this.getNotaAlumnoExamenWithParams({
        id: this.id,
        expand: ["carrera", "examen", "alumnoExamen"].toString(),
      });
      this.model = this.notaAlumnoExamen;
    },
    async guardar() {
      this.model.alumno_id = this.alumno_id;
      this.model.id
        ? await this.updateFichaAcademica(this.model)
        : await this.addFichaAcademica(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.model.carrera.id },
      });
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
    "model.date": function () {
      if (this.model.date) {
        this.model.examen.fecha = this.model.date + " 00:00:00";
      } else {
        this.model.examen.fecha = null;
      }
    },
  },
  created() {
    this.getCarreras();
    this.getMaterias();
    // this.getFields();
    // if (this.id) {
    //   this.getModel();
    // }
  },
};
</script>

<style scoped></style>
