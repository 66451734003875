<template>
  <div>
    <horarios-superpuestos ref="superpuestos"></horarios-superpuestos>

    <calendario-horario ref="calendario"></calendario-horario>

    <!--    <alumno-cursado-form-->
    <!--      ref="form"-->
    <!--      :id="id"-->
    <!--      @close="cerrar"-->
    <!--    ></alumno-cursado-form>-->
    <v-card>
      <v-card-title>AlumnoCursados</v-card-title>
      <v-card-text>
        <!--        <v-row>-->
        <!--          <v-col align="left">-->
        <!--            <v-btn @click="newItem" color="primary">Nuevo</v-btn>-->
        <!--          </v-col>-->
        <!--          <v-col>-->
        <!--            <div>-->
        <!--&lt;!&ndash;              <download-excel :data="excel">&ndash;&gt;-->
        <!--&lt;!&ndash;                <v-btn x-large dark color="success">Exportar</v-btn>&ndash;&gt;-->
        <!--&lt;!&ndash;              </download-excel>&ndash;&gt;-->
        <!--            </div>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :search="search.tag"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 500],
          }"
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCursados"
          :headers="headers"
          :options="options"
        >
          <!--          <template v-slot:body.prepend>-->
          <!--            <filter-row ref="filterRow" :headers="headers" @reload="reload">-->
          <!--            </filter-row>-->
          <!--          </template>-->

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.telefono"></whatsapp-button>
          </template>

          <template v-slot:item.superpuesto="{ item }">
            <div>
              <v-btn @click="verHorarios(item)" color="error"
                >ver superposición</v-btn
              >
            </div>
          </template>
          <template v-slot:item.alumno_cursado.created_at="{ item }">
            {{ item.creado }}
          </template>
          <template v-slot:item.alumno_cursado.id="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:item.u_curricular_carrera.nombre="{ item }">
            {{ item.cursado.espacio.searchTag }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
// import AlumnoCursadoForm from "@/viewsNew/alumno-cursado/AlumnoCursadoForm";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";
import HorariosSuperpuestos from "@/viewsNew/alumno-cursado/HorariosSuperpuestos";
import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";

export default {
  name: "SuperpuestosAlumnoCursadoABM",
  props: {
    cursado_id: [String, Number],
  },
  components: {
    CalendarioHorario,
    HorariosSuperpuestos,
    WhatsappButton,
    // AlumnoCursadoForm,
    // FilterRow,
  },
  data() {
    return {
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        itemsPerPage: -1,
      },
      expand: [
        "alumno",
        "cursado",
        "cursadosSuperpuestos",
        "user",
        "otrosCursados",
      ],
      filters: {},
    };
  },
  computed: {
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    superpuestos() {
      return this.alumnoCursados.filter(
        (el) => el.cursadosSuperpuestos.length > 0
      );
    },

    // excel() {
    //   const data = [];
    //   for (var i in this.alumnoCursados) {
    //     let alumno = this.alumnoCursados[i];
    //     var registro = {
    //       id: alumno.id,
    //       creado: alumno.creado,
    //       nombre: alumno.alumno.nombre,
    //       apellido: alumno.alumno.apellido,
    //       email: alumno.alumno.email,
    //       dni: alumno.alumno.dni,
    //       telefono: alumno.alumno.telefono,
    //       superpuestos: alumno.cursadosSuperpuestos.length,
    //       // carrera: alumno.carrera.codigo,
    //       rol: alumno.alumno.roles,
    //       cursado: alumno.cursado.espacio.searchTag,
    //     };
    //     data.push(registro);
    //   }
    //   return data;
    // },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        // {
        //   text: "Creado",
        //   value: "alumno_cursado.created_at",
        //   filterable: true,
        // },
        { text: "Carrera", value: "codigo", filterable: true },

        // {
        //   text: "Espacio",
        //   value: "materia",
        //   filterable: false,
        //   nosearch: true,
        // },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Email", value: "email", filterable: true },
        // { text: "Telefono", value: "user.telefono", filterable: true },
        {
          text: "Superpuesto",
          value: "superpuesto",
          filterable: false,
          nosearch: true,
        },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewAlumnoCursadoModule", [
      "getAlumnoCursadoWithParams",
      "getAlumnoCursados",
      "deleteAlumnoCursado",
      "getSuperpuestos",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCursado(id);
      }
    },

    async reload() {
      this.loading.status = true;
      this.getSuperpuestos();
      // this.filters.cursado_id = this.cursado_id;
      // await this.getAlumnoCursados({
      //   like: this.$refs.filterRow.like,
      //   options: this.options,
      //   filters: this.filters,
      //   expand: this.expand.toString(),
      //   search: this.search.tag,
      //   headers: this.headers,
      // });
      this.loading.status = false;
    },
    verSuperpuestos(item) {
      // this.$refs.superpuestos.dialog = true;
      // this.$refs.superpuestos.cursados = item.cursadosSuperpuestos;
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = [
        item.cursado,
        ...item.cursadosSuperpuestos,
      ];
    },
    async verHorarios(item) {
      const alumnoCursado = await this.getAlumnoCursadoWithParams({
        id: item.id,
        expand: ["otrosCursados"].toString(),
      });
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = alumnoCursado.otrosCursados;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getSuperpuestos();
    this.loading.status = false;
  },
};
</script>

<style scoped></style>
