var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"horarios",attrs:{"scrollable":"","max-width":"900"},model:{value:(_vm.dialogCorrelativas),callback:function ($$v) {_vm.dialogCorrelativas=$$v},expression:"dialogCorrelativas"}},[_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"link":"","icon":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.dialogCorrelativas = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',[_c('correlativas-form',{ref:"form",attrs:{"id":_vm.id,"espacio":_vm.espacio},on:{"close":_vm.cerrar}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Correlativas "+_vm._s(_vm.espacio.searchTag))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("Nuevo")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"show-select":"","loading":_vm.loading.status,"items":_vm.correlativass,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_vm._l((_vm.headers),function(filterCol,i){return _c('td',{key:i},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(filterCol.filterable),expression:"filterCol.filterable"}],attrs:{"hide-details":"","filled":"","label":'Buscar por ' + filterCol.text,"clearable":""},on:{"click:clear":_vm.reload},model:{value:(
                        _vm.like[
                          filterCol.table ? filterCol.table : filterCol.value
                        ]
                      ),callback:function ($$v) {_vm.$set(_vm.like, 
                          filterCol.table ? filterCol.table : filterCol.value
                        , $$v)},expression:"\n                        like[\n                          filterCol.table ? filterCol.table : filterCol.value\n                        ]\n                      "}})],1)})],2)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('v-btn',{on:{"click":function($event){_vm.dialogCorrelativas = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }