<template>
  <u-curricular-carrera-a-b-m :headers="headers" :carrera_id="this.carrera_id">
    <template v-slot:item.cuatrimestre="{ item }">
      {{ item.nombreCuatrimestre }}
    </template>
    <template v-slot:item.campo.codigo="{ item }">
      {{ item.campo.codigo }}
    </template>
    <template v-slot:item.cuatrimestre="{ item }">
      {{ item.nombreCuatrimestre }}
    </template>
    <template v-slot:item.docentes="{ item }">
      <div v-for="docente in item.docentes" :key="docente.id">
        {{ docente.apellido }}
      </div>
    </template>
  </u-curricular-carrera-a-b-m>
</template>

<script>
import UCurricularCarreraABM from "@/views/u-curricular-carrera/UCurricularCarreraABM";

import { mapState } from "vuex";

export default {
  name: "uCurricularCarreras",
  props: {
    carrera_id: [String, Number],
  },
  components: { UCurricularCarreraABM },
  data() {
    return {
      headers: [
        // { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Carrera", value: "carrera.codigo" },
        { text: "Campo", value: "campo.codigo" },
        { text: "Año", value: "anio" },
        // { text: "Cuatrimestre", value: "cuatrimestre" },
        { text: "Cuatrimestre", value: "cuatrimestre" },
        { text: "Docentes", value: "docentes" },
        { text: "Correlativas", value: "correlativas", sortable: false },
        { text: "Add Correlativa", value: "addcorrelativas", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("UCurricularCarreraModule", ["fields"]),
  },
};
</script>

<style scoped></style>
