<template>
  <div>
    <v-dialog :eager="true" v-model="turnoDialog" max-width="900" scrollable>
      <!--      <turno-form @close="turnoDialog = false"></turno-form>-->
      <v-card>
        <v-card-title>
          <div>
            <v-btn link icon absolute top right @click="turnoDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <turno-a-b-m @close="turnoDialog = false"> </turno-a-b-m>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn @click="turnoDialog = false" color="primary">Cerrar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent eager v-model="dialog" max-width="1200">
      <alumno-carrera-form
        :inscripcion_id="corteInscripcionCarrera.id"
        ref="form"
        :id="id"
        :selected="alumno_id"
        @close="cerrar"
      >
        <template v-slot:inscripcion_id="{ model }">
          <v-select
            outlined
            :items="corteInscripcionCarreras"
            item-text="nombre"
            item-value="id"
            v-model="model.inscripcion_id"
          ></v-select>
        </template>
        <template v-slot:carrera_id="{ model }">
          <v-select
            outlined
            :items="carreras"
            item-text="nombre"
            item-value="id"
            v-model="model.carrera_id"
          >
          </v-select>
        </template>

        <template v-slot:estado="{ model }">
          <v-switch
            :true-value="1"
            :false-value="0"
            v-model="model.estado"
            label="Matriculado"
          ></v-switch>
        </template>
        <template v-slot:condicion="{ model }">
          <v-select
            clearable
            label="Condición"
            outlined
            v-model="model.condicion"
            :items="model.condiciones"
          ></v-select>
        </template>
        <template v-slot:turno_id="{ model }">
          <v-select
            clearable
            label="Turno"
            outlined
            v-model="model.turno_id"
            :items="turnos"
            item-text="nombre"
            item-value="id"
          ></v-select>
        </template>
      </alumno-carrera-form>
    </v-dialog>
    <v-card width="100%">
      <v-card-title>AlumnoCarreras</v-card-title>
      <v-card-text>
        <div class="d-flex mis-acciones pb-3">
          <!--          <div>-->
          <!--            <v-select dense outlined hide-details :items="corteInscripcionCarreras" item-value="id" item-text="nombre" v-model="inscripcion_id"></v-select>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-btn @click="matricular" outlined color="info">Matricular</v-btn>-->
          <!--          </div>-->
          <div>
            <v-btn x-large @click="turnoDialog = true" outlined color="warning"
              >Nuevo turno</v-btn
            >
          </div>
          <div>
            <v-select
              hide-details
              :height="36"
              outlined
              placeholder="Seleccione un turno"
              clearable
              v-model="turno"
              item-text="searchTag"
              item-value="id"
              :items="turnos"
            ></v-select>
          </div>
          <div>
            <v-btn x-large dark color="black" @click="asignarTurno"
              >Asignar Turno</v-btn
            >
          </div>
          <div>
            <v-btn x-large dark color="error" @click="borrarTurno"
              >Borrar Turno</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <!--          <div>-->
          <!--            <v-select outlined label="Eliminar Columna" v-model="skipHeaders" multiple :items="headers" item-text="text" item-value="value"></v-select>-->
          <!--          </div>-->
          <div>
            <download-excel :data="excel">
              <v-btn x-large dark color="success">Exportar</v-btn>
            </download-excel>
          </div>
        </div>

        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="alumnoCarreras"
          :headers="visibleHeaders"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row
              ref="filterRow"
              :headers="visibleHeaders"
              @reload="reload"
            >
              <template v-slot:carrera.codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <template v-slot:turno.searchTag="">
                <v-select
                  multiple
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['turno_id']"
                  :items="turnos"
                  item-value="id"
                  item-text="nombre"
                ></v-select>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.creado }}
          </template>

          <template v-slot:item.actions="{ item }">
            <whatsapp-button :telefono="item.alumno.telefono"></whatsapp-button>
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.estado="{ item }">
            <v-chip v-if="item.estado" color="success">Si</v-chip>
            <v-chip v-else color="error">No</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import AlumnoCarreraForm from "@/viewsNew/alumno-carrera/AlumnoCarreraForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import TurnoABM from "@/viewsNew/turno/TurnoABM";
import moment from "moment";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";

export default {
  name: "PorAlumnoCarreraABM",
  props: {
    user_id: [String, Number],
  },
  components: { WhatsappButton, TurnoABM, FilterRow, AlumnoCarreraForm },
  data() {
    return {
      skipHeaders: [],
      filters: {},
      turnoDialog: false,
      turno: null,
      inscripcion_id: null,
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      alumno_id: null,
      options: {
        joins: ["carrera", "alumno"],
      },
      expand: ["carrera", "alumno", "turno", "user"],
    };
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("TurnoModule", ["turnos"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("CorteInscripcionCarreraModule", [
      "corteInscripcionCarreras",
      "corteInscripcionCarrera",
    ]),
    ...mapState("NewAlumnoCarreraModule", ["alumnoCarreras", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    excel() {
      const data = [];
      for (var i in this.alumnoCarreras) {
        var registro = {
          nombre: this.alumnoCarreras[i].alumno.nombre,
          apellido: this.alumnoCarreras[i].alumno.apellido,
          email: this.alumnoCarreras[i].alumno.email,
          dni: this.alumnoCarreras[i].alumno.dni,
          telefono: this.alumnoCarreras[i].alumno.telefono,
          carrera: this.alumnoCarreras[i].carrera.codigo,
          rol: this.alumnoCarreras[i].alumno.roles,
          turno: this.alumnoCarreras[i].turno
            ? this.alumnoCarreras[i].turno.searchTag
            : "",
        };
        data.push(registro);
      }
      return data;
    },

    codigos() {
      return this.carreras.map((el) => el.codigo);
    },

    filterHeaders() {
      return this.visibleHeaders.filter((el) => el.filterable);
    },

    headers() {
      const headers = [
        {
          text: "Id",
          value: "id",
          filterable: true,
          table: "alumno_carrera.id",
          invisible: false,
        },

        {
          text: "Registro",
          value: "created_at",
          filterable: true,
          table: "alumno_carrera.created_at",
          invisible: false,
        },
        {
          text: "Matriculado",
          value: "estado",
          filterable: true,
          invisible: false,
        },
        {
          text: "Nombre",
          value: "user.nombre",
          table: "user.nombre",
          filterable: true,
          invisible: false,
        },
        {
          text: "Apellido",
          value: "user.apellido",
          table: "user.apellido",
          filterable: true,
          invisible: false,
        },
        {
          text: "email",
          value: "user.email",
          table: "user.email",
          filterable: true,
          invisible: false,
        },
        {
          text: "Dni",
          value: "user.dni",
          table: "user.dni",
          filterable: true,
          invisible: false,
        },
        {
          text: "Telefono",
          value: "user.telefono",
          table: "user.telefono",
          filterable: true,
          invisible: false,
        },
        {
          text: "Carrera",
          value: "carrera.codigo",
          table: "carrera.codigo",
          filterable: true,
          nosearch: true,
          invisible: false,
        },
        {
          text: "Rol",
          value: "user.roles",
          table: "user.roles",
          filterable: true,
          nosearch: true,
          invisible: false,
        },
        // { text: "Documentacion", value: "documentacion" },
        {
          text: "Condicion",
          value: "condicion",
          filterable: true,
          invisible: false,
        },
        {
          text: "Turno",
          value: "turno.searchTag",
          filterable: true,
          nosearch: true,
          invisible: false,
        },
        {
          text: "Acciones",
          value: "actions",
          nosearch: true,
          invisible: false,
        },
      ];
      return headers;
    },
    visibleHeaders() {
      return this.headers.filter((el) => !this.skipHeaders.includes(el.value));
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = { carrera: {} };
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      filters: {
        handler() {
          this.setFilters();
          this.reload();
        },
        deep: true,
      },
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("TurnoModule", ["getTurnosVigentes"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreraActivo",
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("NewAlumnoCarreraModule", [
      "getAlumnoCarreras",
      "deleteAlumnoCarrera",
    ]),
    ...mapActions("AlumnoCarreraModule", [
      "sendMailTurno",
      "updateAlumnoCarrera",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    getWithTimeZone(time) {
      if (!time) return "";
      return moment.unix(time).format("DD-MM-YYYY HH:mm");
    },

    async borrarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = null;
        await this.updateAlumnoCarrera(this.selectedItems[i]);
        // this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    async asignarTurno() {
      for (var i in this.selectedItems) {
        this.selectedItems[i].turno_id = this.turno;
        var alumnoCarrera = await this.updateAlumnoCarrera(
          this.selectedItems[i]
        );
        this.sendMailTurno(alumnoCarrera.id);
        // console.log(alumnoCarrera)
      }
      this.selectedItems = [];
      this.reload();
    },

    matricular() {},

    newItem() {
      this.$refs.form.model = {};
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.alumno_id = item.alumno_id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCarrera(id);
      }
    },

    async reload() {
      this.getTurnosVigentes();
      this.loading.status = true;
      this.filters.alumno_id = this.alumno_id;
      await this.getAlumnoCarreras({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: { ...this.filters },
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  created() {
    this.alumno_id = this.user_id;
    this.getCarreras();
    this.getCorteInscripcionCarreras({});
    this.getCorteInscripcionCarreraActivo({
      filters: { activo: 1, tipo: "ingresantes" },
    });
    this.getCarreras();
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped>
.mis-acciones > div {
  margin: 0 0.5rem;
}
</style>
