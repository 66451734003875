<template>
  <div>
    <v-select
      :disabled="cursado.id > 0"
      label="Seleccionar Carrera"
      v-model="cursado.carreraId"
      :items="carreras"
      item-value="id"
      item-text="nombre"
    />
    <v-select
      :error-messages="errors.espacio_id"
      :disabled="cursado.id > 0"
      label="Seleccionar Asignatura"
      v-model="cursado.espacio_id"
      :items="cursado.id > 0 ? espacios : filteredEspacios"
      item-value="id"
      item-text="searchTag"
    >
      <template v-slot:prepend-item>
        <div class="p-3">
          <v-text-field label="Search" v-model="filterEspacio" />
        </div>
      </template>
    </v-select>

    <v-text-field
      :error-messages="errors.anio"
      v-model="cursado.anio"
      label="Seleccione Año Lectivo"
    ></v-text-field>
    <v-select
      :error-messages="errors.cuatrimestre"
      label="Seleccione Cuatrimestre"
      v-model="cursado.cuatrimestre"
      :items="cuatrimestres"
      item-text="nombre"
      item-value="id"
    ></v-select>
    <v-text-field
      :error-messages="errors.comision"
      v-model="cursado.comision"
      label="Seleccione Nro de Comision"
    ></v-text-field>
    <vc-date-picker
      class=""
      v-model="cursado.fecha_inicio"
      :model-config="modelConfig"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <v-text-field
          label="Seleccione Fecha de Inicio"
          :error-messages="errors.fecha_inicio"
          :value="inputValue"
          @click="togglePopover"
        />
      </template>
    </vc-date-picker>
    <vc-date-picker
      class=""
      v-model="cursado.fecha_fin"
      :model-config="modelConfig"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <v-text-field
          label="Seleccione Fecha De Fin"
          :value="inputValue"
          @click="togglePopover"
          :error-messages="errors.fecha_fin"
        />
      </template>
    </vc-date-picker>
    <vc-date-picker
      class=""
      v-model="cursado.fecha_vencimiento"
      :model-config="modelConfig"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <v-text-field
          label="Seleccione Fecha De Vencimiento"
          :value="inputValue"
          @click="togglePopover"
          :error-messages="errors.fecha_vencimiento"
        />
      </template>
    </vc-date-picker>
    <div :key="key">
      <!--              horarios-->
      <div>
        <div class="d-flex">
          <h5 class="">Horarios</h5>
          <div class="pl-3">
            <v-btn outlined @click="addHorario" color="primary">Agregar</v-btn>
          </div>
        </div>
        <div v-for="(horario, i) in cursado.horarios" :key="i">
          <div class="d-flex pt-3 w-100">
            <div class="p-1 flex-fill">
              <v-select
                label="Ingrese día de la semana"
                :items="dias"
                v-model="horario.dia"
              />
              <!--              <select class="form-control" v-model="horario.dia">-->
              <!--                <option v-for="(dia,i) in dias" :key="dia" :value="i">{{dia}}</option>-->
              <!--              </select>-->
            </div>
            <div class="p-1">
              <v-text-field
                label="hora de inicio"
                type="time"
                v-model="horario.hora_inicio"
              />
            </div>
            <div class="pl-3 pr-3 pt-1 pb-1">
              <v-text-field
                label="hora fin"
                v-model="horario.hora_fin"
                type="time"
              />
            </div>
            <!--        <div><button @click="updateHorario(horario)" type="button" class="btn btn-success">G</button> </div>-->
            <div class="p-1 d-flex align-content-center">
              <v-icon color="error" @click="delHorario(i)"> mdi-delete </v-icon>
              <!--              <v-btn @click="delHorario(i)" type="button" class="btn btn-danger">X</v-btn> -->
            </div>
          </div>
        </div>
      </div>
      <!--              fin horarios-->
      <hr />
      <!--              docentes-->
      <div>
        <h5>Docentes</h5>
        <div class="d-flex">
          <v-select
            v-model="docenteSelect"
            :items="filteredDocentes"
            multiple
            label="Ingrese Nombre del Docente"
            item-text="searchTag"
            return-object
            @click="filterDocentes"
          />
          <div>
            <v-btn
              outlined
              color="primary"
              @click="agregarDocentes"
              class="ml-2"
              >Agregar</v-btn
            >
          </div>
        </div>
        <div
          class="mt-1 bg-light"
          v-for="(docentePerfil, i) in cursado.docentes"
          :key="i"
        >
          <div class="form-group d-flex p-2 justify-content-between">
            <!--            <div class="p-1"><label>{{docentePerfil.apellido}}, {{docentePerfil.nombre}}</label></div>-->
            <div class="">
              <label>{{ docentePerfil.searchTag }}</label>
            </div>
            <!--        <div><button @click="updateHorario(horario)" type="button" class="btn btn-success">G</button> </div>-->
            <div class="ml-2">
              <!--              <button @click="delDocente(i)" type="button" class="btn btn-danger">X</button>-->

              <v-icon @click="delDocente(i)" color="error" dark>
                mdi-delete
              </v-icon>
            </div>
          </div>
        </div>
      </div>
      <!--              fin docentes-->
    </div>
  </div>
</template>

<script>
import modelService from "@/services/model.service";
import { mapGetters, mapState } from "vuex";

export default {
  name: "CursadaForm",
  props: {
    item: Object,
    // errors:Object,
    // cursado_id: [String, Number],
  },
  data() {
    return {
      // docentesAgregados:[],
      filteredDocentes: [],
      docentes: [],
      carrera: {},
      carreras: [],
      dias: [
        { text: "Domingo", value: 0 },
        { text: "Lunes", value: 1 },
        { text: "Martes", value: 2 },
        { text: "Miercoles", value: 3 },
        { text: "Jueves", value: 4 },
        { text: "Viernes", value: 5 },
        { text: "Sabado", value: 6 },
      ],
      key: 0,
      filterEspacio: "",
      espacios: [],
      docenteSelect: [],
      cuatrimestres: [
        { id: 0, nombre: "Anual" },
        { id: 1, nombre: "Primer Cuatrimestre" },
        { id: 2, nombre: "Segundo Cuatrimestre" },
      ],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  mounted() {
    this.getCarreras();
    // this.getCursada();
    this.getMaterias();
    this.getDocentes();
  },
  computed: {
    ...mapState("CursadoModule", ["cursado", "emptyModel", "fields"]),
    ...mapGetters("CursadoModule", ["errors", "docentesAgregados"]),
    docentesNoAgregados() {
      // if(this.model.docentes) {
      return this.docentes.filter(
        (a) => !this.docentesAgregados.map((b) => b.id).includes(a.id)
      );
      // }
      // return this.docentes;
    },

    filteredEspacios() {
      const porCarrera = this.espacios.filter(
        (el) => el.carrera_id === this.cursado.carreraId
      );
      return porCarrera.filter(
        (el) =>
          this.removeAccents(el.searchTag.toLowerCase()).indexOf(
            this.filterEspacio
          ) !== -1
      );
    },
  },
  methods: {
    filterDocentes() {
      if (this.cursado.docentes) {
        this.filteredDocentes = this.docentes.filter(
          (a) => !this.cursado.docentes.map((b) => b.id).includes(a.id)
        );
      } else {
        this.filteredDocentes = this.docentes;
      }
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    async getCarreras() {
      const params = {};
      const { status, data } = await modelService.get(params, "carreras");
      if (status == 200) {
        this.carreras = data;
      }
    },

    // async getCursada(){
    //   if(!this.cursado_id){
    //     return;
    //   }
    //   const params={
    //     // nombre: query,
    //     expand: 'docentes,docenteCursados',
    //   }
    //   const {status, data} = await modelService.get(params,'cursados/'+this.cursado_id);
    //   if(status==200) {
    //     // this.cursado = data;
    //     this.model = data;
    //     // this.getCarrera();
    //   }
    // },

    async getDocentes() {
      const params = {
        // nombre: query,
        rol: "docente",
        // expand: 'usuarios',
      };
      const { status, data } = await modelService.get(
        params,
        "user/custom/porrol"
      );
      if (status == 200) {
        this.docentes = data;
      }
    },

    delDocente(i) {
      this.cursado.docenteCursados.splice(i, 1);
      this.cursado.docentes.splice(i, 1);
      // this.docentesAgregados.splice(i,1);
      this.key++;
    },

    agregarDocentes() {
      for (var i in this.docenteSelect) {
        this.addDocente(this.docenteSelect[i]);
      }
      this.docenteSelect = [];
    },

    addDocente(docente) {
      if (!this.cursado.docenteCursados || !this.cursado.docentes) {
        // this.model.docentes = [];
        this.cursado.docenteCursados = [];
        this.cursado.docentes = [];
      }
      // this.docentesAgregados.push(docente);
      this.cursado.docenteCursados.push({ docente_id: docente.id });
      this.cursado.docentes.push(docente);
      // this.key++;
      // this.docenteSelect = [];
    },

    guardar() {
      this.$emit("guardar");
      // !this.model.id?this.createCursada():this.updateCursada();
    },
    async getMaterias() {
      const params = {
        carrera_id: this.cursado.carreraId,
      };
      const { status, data } = await modelService.get(
        params,
        "u-curricular-carreras"
      );
      if (status === 200) {
        this.espacios = data;
      }
    },

    async createCursada() {
      const { status } = await modelService.create(this.model, "cursados");
      if (status === 201) {
        this.$emit("close");
      }
    },

    async updateCursada() {
      const { status } = await modelService.update(this.model, "cursados");
      if (status === 200) {
        this.$emit("close");
      }
    },

    addHorario() {
      var self = this;
      var horario = { cursado_id: self.cursado_id };
      if (!this.cursado.horarios) {
        this.cursado.horarios = [];
      }
      this.cursado.horarios.push(horario);
      this.key++;
    },

    delHorario(i) {
      this.cursado.horarios.splice(i, 1);
      this.key++;
    },

    delCursada() {},
  },
};
</script>

<style scoped></style>
