<template>
  <div>
    <periodo-examen-form
      ref="form"
      :id="id"
      @close="cerrar"
    ></periodo-examen-form>
    <v-card>
      <v-card-title>PeriodoExamens</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="periodoExamens"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilterRow from "@/viewsNew/utils/FilterRow";
import { mapMutations, mapActions, mapState } from "vuex";
import PeriodoExamenForm from "@/viewsNew/periodo-examen/PeriodoExamenForm";

export default {
  name: "PeriodoExamenABM",
  components: { PeriodoExamenForm, FilterRow },
  data() {
    return {
      filters: {},
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: [],
      },
      expand: [],
    };
  },
  computed: {
    ...mapState("PeriodoExamenModule", ["periodoExamens", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Tipo", value: "type", filterable: true },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("PeriodoExamenModule", [
      "getPeriodoExamens",
      "deletePeriodoExamen",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deletePeriodoExamen(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getPeriodoExamens({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
