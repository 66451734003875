<template>
  <v-container fluid>
    <my-modal :eager="false" :dialog="dialogCorrelativas" @close="close">
      <template v-slot:body>
        <correlativass :materia_id="uCurricularCarrera.id"></correlativass>
      </template>
    </my-modal>

    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"><h3>UCurricularCarreras</h3></template>
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <uCurricularCarrera-form :only="only">
                <template v-slot:carrera_id>
                  <v-select
                    v-model="uCurricularCarrera.carrera_id"
                    :items="carreras"
                    item-value="id"
                    item-text="nombre"
                  />
                </template>
                <template v-slot:campo_id>
                  <v-select
                    v-model="uCurricularCarrera.campo_id"
                    :items="campos"
                    item-value="id"
                    item-text="nombre"
                  />
                </template>
              </uCurricularCarrera-form>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h3>Unidades Curriculares de {{ carrera.nombre }}</h3>

    <v-row no-gutters align="center">
      <div>
        <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>
      </div>
      <import-export
        model-class="UCurricularCarreras"
        :path="uploadPath"
        :only="only"
        :model-fields="fields"
        :plantilla="[uCurricularCarrera]"
        @close="close"
      ></import-export>

      <div class="pl-3">
        <v-select
          @change="setCarrera($event)"
          label="Seleccione carrera"
          :value="carrera"
          :items="carreras"
          item-text="nombre"
          return-object
        ></v-select>
        <!--        <v-select  clearable @click:clear="setCarrera({})" v-model="compCarrera"  label="Seleccione carrera"    :items="carreras" item-text="nombre" item-value="id"></v-select>-->
      </div>
      <div class="pl-3">
        <v-select
          multiple
          label="Ingrese Campo Formación"
          clearable
          v-model="filters.campo_id"
          :items="campos"
          item-value="id"
          item-text="nombre"
        ></v-select>
      </div>
      <div class="pl-3">
        <v-select
          multiple
          label="Ingrese Año"
          clearable
          v-model="filters.anio"
          :items="aniosCarrera"
          item-value="id"
          item-text="nombre"
        ></v-select>
      </div>
      <div class="pl-3">
        <v-select
          multiple
          label="Ingrese Cuatrimestre"
          clearable
          v-model="filters.cuatrimestre"
          :items="cuatrimestres"
        ></v-select>
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      @change="reload"
    ></v-text-field>
    <v-data-table
      multi-sort
      :loading="loading"
      :server-items-length="pagination.totalCount"
      :options.sync="options"
      v-model="selectedItems"
      :show-select="true"
      :items="uCurricularCarreras"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.correlativas="{ item }">
        <div v-for="data in item.correlativas" :key="data.id">
          {{ data.correlativa.nombre }},
        </div>
      </template>

      <template v-slot:item.addcorrelativas="{ item }">
        <v-btn outlined color="purple" @click="showCorrelativas(item)"
          >Correl</v-btn
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
import UCurricularCarreraForm from "@/views/u-curricular-carrera/UCurricularCarreraForm";
import MyModal from "@/components/templates/MyModal";
import ImportExport from "@/views/layouts/ImportExport";
import Correlativass from "@/views/correlativas/Correlativass";

export default {
  name: "UCurricularCarreraABM",
  components: {
    Correlativass,
    ImportExport,
    MyModal,
    UCurricularCarreraForm,
  },
  props: {
    uploadPath: { type: String, default: "u-curricular-carreras" },
    headers: Array,
    only: Array,
    carrera_id: { type: [String, Number], default: null },
  },
  data() {
    return {
      cuatrimestres: [
        { value: 0, text: "Anual" },
        { value: 1, text: "Primero" },
        { value: 2, text: "Segundo" },
      ],
      filters: {},
      compCarrera: null,
      loading: false,
      options: {
        itemsPerPage: -1,
      },
      dialogCorrelativas: false,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("UCurricularCarreraModule", [
      "getAllUCurricularCarreras",
      "getUCurricularCarreras",
      "getFields",
      "addUCurricularCarrera",
      "updateUCurricularCarrera",
      "deleteUCurricularCarrera",
      "editUCurricularCarrera",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("CarreraModule", ["setCarrera", "getCarreras", "getCarrera"]),
    ...mapActions("CampoModule", ["getCampos"]),
    close() {
      this.hideDialog();
      this.dialogCorrelativas = false;
      this.reload();
    },
    async onSubmit() {
      // this.addUCurricularCarrera(this.uCurricularCarrera);
      this.uCurricularCarrera.id
        ? await this.updateUCurricularCarrera(this.uCurricularCarrera)
        : await this.addUCurricularCarrera(this.uCurricularCarrera);
      this.close();
    },
    newItem() {
      // this.fetchUCurricularCarrera();
      this.editUCurricularCarrera({});
      this.showDialog();
    },
    showCorrelativas(item) {
      this.editUCurricularCarrera(item);
      this.dialogCorrelativas = true;
    },
    editItem(item) {
      this.editUCurricularCarrera(item);
      this.showDialog();
      // this.showUCurricularCarreraDialog();
    },
    deleteItem(id) {
      this.deleteUCurricularCarrera(id);
    },

    reload() {
      this.filters.carrera_id = this.carrera.id;
      this.getAllUCurricularCarreras({
        search: this.search,
        carrera_id: this.carrera.id,
        options: this.options,
        filters: this.filters,
      });
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
      }
      return result;
    },
  },
  computed: {
    ...mapState("UCurricularCarreraModule", [
      "pagination",
      "uCurricularCarreras",
      "dialog",
      "uCurricularCarrera",
      "fields",
      // "filters",
    ]),
    ...mapState("CarreraModule", ["carreras", "carrera"]),
    ...mapState("CampoModule", ["campos"]),
    aniosCarrera() {
      var anios = this.uCurricularCarreras.map((el) => el.anio);

      return anios;
    },
  },
  watch: {
    carrera() {
      this.reload();
    },
    options: {
      handler() {
        if (this.carrera.id) {
          this.reload();
        }
      },
      deep: true,
    },
    filters: {
      handler() {
        localStorage.setItem("filters", JSON.stringify(this.filters));
        this.reload();
      },
      deep: true,
    },
  },
  created() {
    if (localStorage.getItem("filters")) {
      this.filters = JSON.parse(localStorage.getItem("filters"));
    }
    this.getCarreras();
    this.getCampos();
    this.getFields();
    this.getCarrera(this.carrera_id);
  },
  mounted() {
    // const params = new URLSearchParams(window.location.search)
    // this.filters = this.paramsToObject(params);
  },
  destroyed() {
    localStorage.removeItem("filters");
  },
};
</script>

<style scoped></style>
