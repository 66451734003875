<template>
  <carrera-a-b-m :headers="headers">
    <template v-slot:item.alumnos="{ item }">
      <v-btn
        @click="$router.push('/alumnos-carrera/' + item.id)"
        outlined
        rounded
        color="primary"
      >
        <!--        <v-btn @click="showAlumnos(item)" outlined rounded color="primary">-->
        Alumnos
      </v-btn>
    </template>

    <template v-slot:item.materias="{ item }">
      <v-btn
        @click="
          $router.push({
            name: 'materias-por-carrera',
            params: { carrera_id: item.id },
          })
        "
        outlined
        rounded
        color="warning"
      >
        <!--                  <v-btn @click="showMaterias(item)" outlined rounded color="warning">-->
        Materias
      </v-btn>
    </template>
  </carrera-a-b-m>
</template>

<script>
import CarreraABM from "@/views/carrera/CarreraABM";

export default {
  name: "Carreras",
  components: { CarreraABM },
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Codigo", value: "codigo" },
        { text: "Nombre", value: "nombre" },
        { text: "Años", value: "cant_anios" },
        { text: "Total Horas", value: "total_horas" },
        { text: "Alumnos", value: "alumnos" },
        { text: "Materias", value: "materias" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
};
</script>

<style scoped></style>
