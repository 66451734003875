<template>
  <v-container fluid>
    <my-modal :dialog="dialogResponse" @close="close">
      <template slot="body">
        <div v-if="importData" class="mt-3">
          <div class="text-warning" v-if="importData.duplicados">
            <h4 class="">Registros duplicados</h4>
            <v-data-table
              class="error-summary"
              :headers="iheaders"
              :items="importData.duplicados"
            >
              <template v-slot:item.errors="{ item }">
                <div v-for="(error, i) in item.errors" :key="i">
                  {{ error }}
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="text-danger" v-if="importData.errores">
            <h4 class="">Registros con Errores</h4>
            <v-data-table
              class="error-summary"
              :headers="iheaders"
              :items="importData.errores"
            >
              <template v-slot:item.errors="{ item }">
                <div v-for="(error, i) in item.errors" :key="i">
                  {{ error }}
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="text-success" v-if="importData.saved">
            <h4 class="">Registros Guardados</h4>
            <v-data-table
              class="error-summary"
              :headers="iheaders"
              :items="importData.saved"
            ></v-data-table>
          </div>
        </div>
      </template>
    </my-modal>

    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"><h3>Ingresantes</h3></template>
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <ingresante-form-docente :only="only">
                <template v-slot:carrera_id>
                  <v-select
                    label="Elija una carrera"
                    v-model="ingresante.carrera_id"
                    :items="carreras"
                    item-text="nombre"
                    item-value="id"
                  ></v-select>
                </template>

                <template v-slot:matriculado>
                  <v-switch
                    :true-value="1"
                    :false-value="0"
                    v-model="ingresante.matriculado"
                    label="matriculado"
                  ></v-switch>
                </template>
                <template v-slot:fecha_ingreso>
                  <div></div>
                </template>
              </ingresante-form-docente>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h3>Ingresantes</h3>

    <v-row no-gutters align="center">
      <div class="mr-3">
        <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>
      </div>

      <import-export
        class="mr-3"
        model-class="Ingresantes"
        :path="uploadPath"
        :only="only"
        :model-fields="fields"
        :plantilla="[ingresante]"
        @close="close"
      ></import-export>

      <div class="mr-3" v-if="!matriculados">
        <v-btn @click="matricularIngresantes" outlined color="warning"
          >Matricular</v-btn
        >
      </div>
      <div class="mr-3">
        <v-btn @click="borrarTodo" outlined color="error"
          >Borrar Seleccionados</v-btn
        >
      </div>
      <div class="mr-3">
        <v-switch
          :true-value="1"
          :false-value="0"
          label="Matriculados"
          v-model="matriculados"
        ></v-switch>
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      :options.sync="options"
      :server-items-length="pagination.totalCount"
      v-model="selectedItems"
      :show-select="true"
      :items="filterIngresantes"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
import MyModal from "@/components/templates/MyModal";
import ImportExport from "@/views/layouts/ImportExport";
import Ingresante from "@/apis/Ingresante";
import IngresanteFormDocente from "@/views/ingresante/IngresanteFormDocente";

export default {
  name: "IngresanteABMDocente",
  components: {
    IngresanteFormDocente,
    ImportExport,
    MyModal,
  },
  props: {
    uploadPath: { type: String, default: "ingresantes" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      filters: {},
      options: { itemsPerPage: -1 },
      dialogResponse: false,
      iheaders: [
        { text: "Username", value: "username" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "email", value: "email" },
        { text: "Errores", value: "errors" },
      ],
      importData: null,
      matriculados: 0,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("IngresanteModule", [
      "deleteAll",
      "getMatriculados",
      "getIngresantes",
      "getFields",
      "addIngresante",
      "updateIngresante",
      "deleteIngresante",
      "editIngresante",
      "showDialog",
      "hideDialog",
    ]),
    close() {
      this.selectedItems = [];
      this.hideDialog();
      this.dialogResponse = false;
      this.getMatriculados(this.matriculados);
    },
    onSubmit() {
      // this.addIngresante(this.ingresante);
      this.ingresante.id
        ? this.updateIngresante(this.ingresante)
        : this.addIngresante(this.ingresante);
    },
    newItem() {
      // this.fetchIngresante();
      this.editIngresante({});
      this.showDialog();
    },
    editItem(item) {
      this.editIngresante(item);
      this.showDialog();
      // this.showIngresanteDialog();
    },
    deleteItem(id) {
      this.deleteIngresante(id);
    },

    async matricularIngresantes() {
      const { data } = await Ingresante.matricular({
        ingresantes: this.selectedItems,
      });
      this.importData = data;
      this.dialogResponse = true;
    },
    async borrarTodo() {
      await this.deleteAll(this.selectedItems);
      this.reload();
    },

    reload() {
      this.getIngresantes({ options: this.options, filters: this.filters });
    },
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("IngresanteModule", [
      "pagination",
      "ingresantes",
      "dialog",
      "ingresante",
      "fields",
    ]),
    filterIngresantes() {
      return this.ingresantes.filter(
        (el) => el.matriculado == this.matriculados
      );
    },
    // ...mapGetters("IngresanteModule",["ingresanteList","ingresanteDialog","newIngresanteRecord","ingresante","ingresanteFields","ingresanteErrors"]),
    // ...mapGetters("IngresanteModule",["errors"]),
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    filters: {
      handler() {
        localStorage.setItem("filters", JSON.stringify(this.filters));
        this.reload();
      },
      deep: true,
    },
  },
  created() {
    this.filters.rol = ["docente", "secretario", "directivo"];
    // this.filters.matriculado = this.matriculados;
    // this.getMatriculados(this.matriculados);
    this.getFields();
    // this.getCarreras();
  },
};
</script>

<style scoped></style>
