<template>
  <div>
    <v-dialog persistent eager v-model="dialog" max-width="600">
      <user-form ref="form" :id="id" @close="cerrar">
        <template v-slot:password="{ model }">
          <v-text-field
            outlined
            v-model="model.password"
            label="Ingrese una contraseña"
            type="password"
          ></v-text-field>
        </template>
      </user-form>
    </v-dialog>
    <v-card>
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          show-select
          :loading="loading.status"
          :items="users"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <tr>
              <td></td>
              <td v-for="(filterCol, i) in headers" :key="i">
                <v-text-field
                  v-show="filterCol.filterable"
                  hide-details
                  filled
                  :label="'Buscar por ' + filterCol.text"
                  v-model="
                    like[filterCol.table ? filterCol.table : filterCol.value]
                  "
                  clearable
                  @click:clear="reload"
                ></v-text-field>
              </td>
            </tr>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.carrerasInscripto="{ item }">
            <div v-for="carrera in item.carrerasInscripto" :key="carrera.id">
              {{ carrera.codigo }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import UserForm from "@/viewsNew/user/UserForm";

export default {
  name: "UserABM",
  components: { UserForm },
  data() {
    return {
      like: {},
      dialog: false,
      id: null,
      options: {},
      expand: ["roles", "carrerasInscripto"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users", "pagination"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Carreras",
          value: "carrerasInscripto",
          nosearch: true,
          filterable: true,
        },
        { text: "Roles", value: "roles", nosearch: true, filterable: true },
        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsers", "deleteUser"]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsers({
        like: this.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
