<template>
  <div>
    <div v-for="(label, field) in filterFields" :key="field">
      <slot :name="field">
        <v-text-field
          :label="'Ingrese ' + label"
          v-model="uCurricularCarrera[field]"
          :error-messages="errors[field]"
        ></v-text-field>
      </slot>
    </div>
    <slot name="field"></slot>

    <agregar-docente-espacio></agregar-docente-espacio>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AgregarDocenteEspacio from "@/views/u-curricular-carrera/AgregarDocenteEspacio";

export default {
  name: "UCurricularCarreraForm",
  components: { AgregarDocenteEspacio },
  props: {
    only: {
      type: Array,
      default: () => {
        return [];
      },
    },
    exclude: {
      type: Array,
      default: () => {
        return ["id"];
      },
    },
  },
  computed: {
    ...mapState("UCurricularCarreraModule", [
      "uCurricularCarrera",
      "emptyModel",
      "fields",
    ]),
    ...mapGetters("UCurricularCarreraModule", ["errors"]),
    // ...mapGetters(["uCurricularCarrera","uCurricularCarreraFields","uCurricularCarreraErrors"]),
    filterFields() {
      let fields = {};
      if (this.only.length > 0) {
        for (var j in this.only) {
          let attr = this.only[j] in this.fields;
          if (attr) {
            fields[this.only[j]] = this.fields[this.only[j]];
          }
        }
        return fields;
      }
      if (this.exclude.length > 0) {
        for (var i in this.fields) {
          let attr = this.exclude.find((el) => el == i);
          if (!attr) {
            fields[i] = this.fields[i];
          }
        }
        return fields;
      }
      return this.fields;
    },
  },
};
</script>

<style scoped></style>
