<template>
  <div>
    <correlativas-a-b-m
      ref="correlativas"
      :espacio_id="id"
      :espacio="this.espacio"
    ></correlativas-a-b-m>

    <u-curricular-carrera-form ref="form" :id="id" @close="cerrar">
      <template v-slot:carrera_id="{ model }">
        <v-autocomplete
          outlined
          :items="carreras"
          item-value="id"
          item-text="nombre"
          v-model="model.carrera_id"
          label="Ingrese Carrera"
        />
      </template>
      <template v-slot:campo_id="{ model }">
        <v-autocomplete
          outlined
          :items="campos"
          item-value="id"
          item-text="nombre"
          v-model="model.campo_id"
          label="Ingrese Carrera"
        />
      </template>
    </u-curricular-carrera-form>
    <v-card>
      <v-card-title>UCurricularCarreras</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </div>
          <v-spacer></v-spacer>
          <download-excel :data="excel">
            <v-btn x-large dark color="success">Exportar</v-btn>
          </download-excel>
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="uCurricularCarreras"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row
              ref="filterRow"
              :headers="filterHeaders"
              @reload="reload"
            >
              <template v-slot:cuatrimestre>
                <v-select
                  clearable
                  v-model="filters['cuatrimestre']"
                  dense
                  outlined
                  hide-details
                  :items="cuatrimestres"
                ></v-select>
              </template>
              <template v-slot:carrera.codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <template v-slot:campo.codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['campo_id']"
                  :items="campos"
                  item-value="id"
                  item-text="codigo"
                ></v-select>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.cuatrimestre="{ item }">
            {{ item.nombreCuatrimestre }}
          </template>
          <template v-slot:item.user.apellido="{ item }">
            {{ item.docentes.map((el) => el.apellido) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <!--          <template v-slot:item.correlativas="{ item }">-->
          <!--            <v-card-->
          <!--              elevation="3"-->
          <!--              class="p-2"-->
          <!--              dark-->
          <!--              color="purple"-->
          <!--              @click="verCorrelativas(item)"-->
          <!--            >-->
          <!--              <span v-if="item.correlativas.length > 0">Correlativas</span>-->
          <!--            </v-card>-->
          <!--          </template>-->
          <template v-slot:item.correlativasCursado="{ item }">
            <v-card
              elevation="3"
              class="p-2"
              dark
              color="purple"
              @click="verCorrelativas(item)"
            >
              <div v-for="corre in item.correlativasCursado" :key="corre.id">
                {{ corre.correlativa.nombre }}
              </div>
            </v-card>
          </template>
          <template v-slot:item.correlativas="{ item }">
            <v-card
              elevation="3"
              class="p-2"
              dark
              color="purple"
              @click="verCorrelativas(item)"
            >
              <div v-for="corre in item.correlativas" :key="corre.id">
                {{ corre.correlativa.nombre }}
              </div>
            </v-card>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import UCurricularCarreraForm from "@/viewsNew/u-curricular-carrera/UCurricularCarreraForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import CorrelativasABM from "@/viewsNew/correlativas/CorrelativasABM";

export default {
  name: "UCurricularCarreraABM",
  components: { CorrelativasABM, FilterRow, UCurricularCarreraForm },
  data() {
    return {
      filters: {},
      espacio: {},
      cuatrimestres: [
        { text: "Anual", value: 0 },
        { text: "Primero", value: 1 },
        { text: "Segundo", value: 2 },
      ],
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: [
          "carrera",
          // "docenteEspacios",
          // "docentes",
          "campo",
        ],
      },
      expand: [
        "carrera",
        "docentes",
        "docenteEspacios",
        "correlativas",
        "correlativasCursado",
      ],
    };
  },
  computed: {
    ...mapState("CampoModule", ["campos"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewUCurricularCarreraModule", [
      "uCurricularCarreras",
      "pagination",
    ]),
    ...mapState(["search", "loading", "dialogs"]),

    excel() {
      let items = [];
      for (var i in this.uCurricularCarreras) {
        let item = { ...this.uCurricularCarreras[i] };
        item.carrera = item.carrera.codigo;
        item.campo = item.campo.nombre;
        item.docentes = item.docentes.map((el) => el.apellido);
        // item.inscripcionCarrera = item.inscripcionCarrera.map(
        //     (el) => el.nombre
        // );
        items.push(item);
      }
      return items;
    },
    codigos() {
      return this.carreras.map((el) => el.codigo);
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Carrera", value: "carrera.codigo", filterable: true },
        { text: "Campo", value: "campo.codigo", filterable: true },
        {
          text: "Nombre",
          value: "nombre",
          table: "u_curricular_carrera.nombre",
          filterable: true,
        },
        { text: "Año", value: "anio", filterable: true },
        { text: "Categoria", value: "categoria", filterable: true },
        { text: "Cuatrimestre", value: "cuatrimestre", filterable: true },
        {
          text: "Docentes",
          value: "user.apellido",
          filterable: true,
          sortable: false,
        },
        { text: "Correlativas Final", value: "correlativas", filterable: true },
        {
          text: "Correlativas Cursada",
          value: "correlativasCursado",
          filterable: true,
        },
        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CampoModule", ["getCampos"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewUCurricularCarreraModule", [
      "getUCurricularCarreras",
      "deleteUCurricularCarrera",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    verCorrelativas(item) {
      this.espacio = item;
      this.$refs.correlativas.dialogCorrelativas = true;
    },

    newItem() {
      this.$refs.form.model = {};
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUCurricularCarrera(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUCurricularCarreras({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getCarreras();
    this.getCampos();
  },
};
</script>

<style scoped></style>
