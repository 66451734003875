<template>
  <div class="pl-5 pr-5">
    <ABMCorrelatividad
      url-api="correlativas"
      :tipo="tipo"
      @get-carrera="getCarrera"
      @close="closeModal"
      v-if="altaCorre"
      :materias="carrera.materiasCarrera"
      :materia_id="selectedMateria.id"
    ></ABMCorrelatividad>
    <!--    <AltaCorrelatividadAprobar url-api="correlativa-aprobars" :correlativas="selectedMateria.correlativasAprobar" @get-carrera="getCarrera" @close="closeModal"  v-if="altaCorreAprob"  :materias="carrera.materiasCarrera" :materia-cursar="selectedMateria"></AltaCorrelatividadAprobar>-->

    <div class="p-3">
      <h3>{{ carrera.nombre }}</h3>
    </div>

    <table
      ref="formContainer"
      class="table table-striped table-inverse table-bordered"
    >
      <thead class="thead-inverse">
        <tr>
          <th>Campo</th>
          <th>Año</th>
          <th>Id</th>
          <th>Materia</th>
          <th>Estar Cursando</th>
          <th></th>
          <th>Cursado Aprobado</th>
          <th></th>
          <th>Materia Aprobada</th>
          <th></th>
        </tr>
        <tr>
          <th>
            <input
              class="form-control"
              @change="filtrar"
              v-model="filter.campo"
            />
          </th>
          <th>
            <input
              class="form-control"
              @change="filtrar"
              v-model="filter.anio"
            />
          </th>
          <th>
            <input
              class="form-control"
              @change="filtrar"
              v-model="filter.materia_id"
            />
          </th>
          <th>
            <input
              class="form-control"
              @change="filtrar"
              v-model="filter.materia"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="materia_carrera in materiasCarrera"
          :key="materia_carrera.id"
        >
          <td scope="row">{{ materia_carrera.campo.codigo }}</td>
          <td scope="row">{{ materia_carrera.anio }}</td>
          <td scope="row">{{ materia_carrera.id }}</td>
          <td>{{ materia_carrera.nombre }}</td>
          <td>
            <div
              class="border-secondary border-bottom"
              v-for="correlativa in materia_carrera.correlativasCursando"
              :key="correlativa.id"
            >
              <span>{{ correlativa.searchTag }}</span>
            </div>
          </td>
          <td>
            <button
              @click="showModal(materia_carrera, 0)"
              class="btn btn-success"
            >
              E
            </button>
          </td>
          <td>
            <div
              class="border-secondary border-bottom"
              v-for="correlativa in materia_carrera.correlativasCursadaAprobada"
              :key="correlativa.id"
            >
              <span>{{ correlativa.searchTag }}</span>
            </div>
          </td>
          <td>
            <button
              @click="showModal(materia_carrera, 1)"
              class="btn btn-success"
            >
              E
            </button>
          </td>
          <td>
            <div
              class="border-secondary border-bottom"
              v-for="correlativa in materia_carrera.correlativasAprobar"
              :key="correlativa.id"
            >
              <span>{{ correlativa.searchTag }}</span>
            </div>
          </td>
          <td>
            <button
              @click="showModal(materia_carrera, 2)"
              class="btn btn-success"
            >
              E
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import materiaService from "@/services/materia.service";
import ABMCorrelatividad from "@/components/ABMCorrelatividad";
import modelService from "@/services/model.service";

const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export default {
  name: "MateriaCorrelativas",
  components: {
    ABMCorrelatividad,
    // Loading,
  },
  mounted() {
    this.getCarrera();
  },
  data() {
    return {
      loader: {},
      fullPage: false,
      tipo: null,
      altaCorreAprob: false,
      altaCorre: false,
      selectedMateria: {},
      correlativas: [],
      carrera: {},
      materias: [],
      filter: {},
      materiasCarrera: [],
    };
  },

  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        // container: this.$refs.formContainer,
        color: "#017BFF",
        loader: "spinner",
        width: 128,
        height: 128,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        canCancel: true,
        onCancel: this.onCancel,
      });
    },

    onCancel() {
      console.log("User cancelled the loader.");
    },

    filtrar() {
      // var materiasFiltradas = [];
      this.materiasCarrera = this.carrera.materiasCarrera;
      var materias = this.carrera.materiasCarrera;
      if (this.filter.anio) {
        materias = materias.filter(
          (materia) => materia.anio == this.filter.anio
        );
      }
      if (this.filter.campo) {
        materias = materias.filter(
          (materia) => materia.campo.codigo == this.filter.campo
        );
      }
      if (this.filter.materia_id) {
        materias = materias.filter(
          (materia) => materia.id == this.filter.materia_id
        );
      }
      if (this.filter.materia) {
        materias = materias.filter(
          (materia) =>
            removeAccents(materia.nombre).search(
              removeAccents(this.filter.materia)
            ) > -1
        );
      }
      if (
        this.filter.materia ||
        this.filter.materia_id ||
        this.filter.anio ||
        this.filter.campo
      ) {
        // materiasFiltradas = materias.filter(materia => materia.anio == this.filter.anio ||  materia.id == this.filter.materia_id || materia.nombre.search(this.filter.materia)>-1);
        this.materiasCarrera = materias;
      }
    },
    showModal(materia, tipo) {
      this.tipo = tipo;
      this.selectedMateria = materia;
      this.altaCorre = true;
    },
    showModalAprob(materia) {
      this.selectedMateria = materia;
      this.altaCorreAprob = true;
    },

    closeModal() {
      this.getCarrera();
      this.altaCorre = false;
      this.altaCorreAprob = false;
    },
    getCarrera() {
      const carrera_id = 1;

      self.showLoader();

      const params = {
        anio: this.anio,
      };
      const { status, data } = modelService.get(
        params,
        "carreras/" + carrera_id
      );
      if (status === 200) {
        this.carrera = data;
        this.materiasCarrera = this.carrera.materiasCarrera;
      }
    },
    getMaterias() {
      var self = this;
      materiaService
        .get()
        .then(function (response) {
          // console.log(response);
          self.materiasTodas = response.data;
          console.log(response);
          self.materias = response.data;
          self.filtrar();
          // self.getMateriasNoAgregadas();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
          self.loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
}
table {
  text-align: left;
  font-size: 0.9rem;
}
</style>
