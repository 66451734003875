<template>
  <div>
    <v-dialog v-model="check" max-width="600px">
      <v-card>
        <v-card-title>Actualice sus Datos</v-card-title>
        <v-card-text>
          Hace mucho tiempo que no actualiza la Información de su Perfil, le
          pedimos que se tome un tiempo para corroborar sus datos. Revisar que
          esten bien escritos Nombre, Apellido, email y telefono. En caso que el
          dni no sea el correcto porfavor escribir un correo a
          <strong>pacho@ifdcsao.edu.ar</strong>
        </v-card-text>
        <v-footer>
          <v-btn text @click="cerrar = true">Cerrar</v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    <v-container>
      <h3>Mis Datos</h3>
      <v-divider></v-divider>
      <v-form @submit.prevent="actualizar">
        <v-text-field
          :error-messages="errors.apellido"
          outlined
          label="Apellido"
          v-model="user.apellido"
        ></v-text-field>
        <v-text-field
          :error-messages="errors.nombre"
          outlined
          label="Nombre"
          v-model="user.nombre"
        ></v-text-field>
        <v-text-field
          :error-messages="errors.email"
          outlined
          label="Email"
          v-model="user.email"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="DNI"
          v-model="user.dni"
        ></v-text-field>
        <small>Teléfono</small>
        <v-text-field
          :error-messages="errors.telefono"
          outlined
          placeholder="Ingrese Teléfono"
          v-model="user.telefono"
        ></v-text-field>
        <!--      <v-text-field type="password" outlined  v-model="user.password" placeholder="Actualice su password"></v-text-field>-->
        <div>
          <v-checkbox
            class="my-checkbox"
            :error-messages="user.errors"
            dense
            v-model="user.verificado"
            label="Los Datos estan actualizados"
          ></v-checkbox>
        </div>

        <v-btn type="submbit" outlined color="primary">Guardar</v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Perfil",
  data() {
    return {
      cerrar: false,
      verificado: null,
    };
  },
  computed: {
    ...mapGetters("UserModule", ["errors"]),
    ...mapState("UserModule", ["user"]),
    check() {
      return this.user.verificado === null && !this.cerrar;
    },
  },
  methods: {
    ...mapActions("UserModule", ["updateUser"]),
    actualizar() {
      this.updateUser(this.user);
    },
  },
  watch: {
    user: {
      handler() {
        if (this.user.verificado) {
          this.user.verificado = "2021-09-27";
        } else {
          this.user.verificado = null;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.my-checkbox .v-label {
  top: 4px;
}
</style>
