<template>
  <div>
    <alumno-form :id="id" ref="form"></alumno-form>
    <v-card>
      <v-card-title>Alumnos IFDC SAO</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </div>
          <v-spacer></v-spacer>
          <download-excel :data="usersExcel">
            <v-btn x-large dark color="success">Exportar</v-btn>
          </download-excel>

          <!--          <v-col>-->
          <!--            <v-select @change="reload"  v-model="rolesModel" multiple label="Ingrese rol" outlined :items="roles" ></v-select>-->
          <!--          </v-col>-->
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          show-select
          :loading="loading.status"
          :items="users"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row
              ref="filterRow"
              :headers="visibleHeaders"
              @reload="reload"
            >
              <template v-slot:codigo="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['carrera.codigo']"
                  :items="codigos"
                ></v-select>
              </template>
              <template v-slot:corte_inscripcion_carrera.nombre="">
                <v-select
                  dense
                  outlined
                  hide-details
                  clearable
                  v-model="filters['corte_inscripcion_carrera.nombre']"
                  :items="corteInscripcionCarreras"
                  item-text="nombre"
                  item-value="nombre"
                ></v-select>
              </template>
              <template v-slot:item_name="">
                <v-select
                  dense
                  hide-details
                  @change="reload"
                  v-model="rolesModel"
                  label="Ingrese rol"
                  outlined
                  :items="['alumno', 'alumno-presistema']"
                ></v-select>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <whatsapp-button :telefono="item.telefono"></whatsapp-button>
          </template>
          <template v-slot:item.codigo="{ item }">
            <div v-for="carrera in item.carreras" :key="carrera.id">
              {{ carrera.codigo }}
            </div>
          </template>
          <template v-slot:item.corte_inscripcion_carrera.nombre="{ item }">
            <div
              v-for="inscripcion in item.inscripcionCarrera"
              :key="inscripcion.id"
            >
              {{ inscripcion.nombre }}
            </div>
          </template>
          <template v-slot:item.item_name="{ item }">
            <div v-for="rol in item.roles" :key="rol">
              {{ rol }}
            </div>
          </template>
          <template v-slot:item.user.created_at="{ item }">
            {{ item.creado }}
          </template>
          <template v-slot:item.cursados="{ item }">
            <v-btn
              dark
              small
              target="_blank"
              :href="'/por-alumno-cursada/' + item.id"
              color="purple"
              >Cursados</v-btn
            >
          </template>
          <template v-slot:item.examenes="{ item }">
            <v-btn
              dark
              small
              target="_blank"
              :href="'/por-alumno-examen/' + item.id"
              color="teal"
              >Examenes</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import WhatsappButton from "@/viewsNew/utils/WhatsappButton";
import AlumnoForm from "@/viewsNew/user/alumnos/AlumnoForm";
import FilterRow from "@/viewsNew/utils/FilterRow";

export default {
  name: "DocenteABM",
  components: { FilterRow, AlumnoForm, WhatsappButton },
  data() {
    return {
      skipHeaders: [],
      rolesModel: ["alumno", "alumno-presistema"],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: ["carreras", "inscripcionCarrera"],
      },
      expand: ["roles", "carreras", "inscripcionCarrera"],
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarreras"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewUserModule", ["users", "pagination", "roles"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),
    usersExcel() {
      let items = [];
      for (var i in this.users) {
        let item = { ...this.users[i] };
        item.carreras = item.carreras.map((el) => el.codigo);
        item.inscripcionCarrera = item.inscripcionCarrera.map(
          (el) => el.nombre
        );
        items.push(item);
      }
      return items;
    },
    visibleHeaders() {
      return this.headers.filter((el) => !this.skipHeaders.includes(el.value));
    },
    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },
    codigos() {
      return this.carreras.map((el) => el.codigo);
    },

    headers() {
      return [
        // { text: "Id", value: "id", filterable: true },
        { text: "Registro", value: "user.created_at", filterable: true },

        {
          text: "Inscripcion",
          value: "corte_inscripcion_carrera.nombre",
          filterable: true,
        },
        {
          text: "Nombre",
          value: "nombre",
          table: "user.nombre",
          filterable: true,
        },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Carreras",
          value: "codigo",
          table: "carrera.codigo",
          nosearch: true,
          filterable: true,
        },
        { text: "Roles", value: "item_name", nosearch: true, filterable: true },
        { text: "Cursados", value: "cursados", nosearch: true },
        { text: "Examenes", value: "examenes", nosearch: true },

        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewUserModule", [
      "getUsers",
      "deleteUser",
      "getUsersPorRoles",
      "getRoles",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    newItem() {
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsersPorRoles({
        roles: this.rolesModel,
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.getRoles();
    this.clearFilters();
  },
  created() {
    this.getCorteInscripcionCarreras({ activo: true });
    this.getCarreras();
  },
};
</script>

<style scoped></style>
