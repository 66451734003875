<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title text-light">Agregar Correlativas</h5>
          <button
            @click="$emit('close')"
            type="button"
            class="close text-light"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <div class="col-8">
              <multiselect
                v-model="correlativasSelect"
                :options="materiasNoAgregadas"
                :multiple="true"
                placeholder="Type to search"
                label="searchTag"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                track-by="searchTag"
              >
              </multiselect>
              <!--              <model-list-select-->
              <!--                            option-value="nombre"-->
              <!--                            option-text="nombre"-->
              <!--                            :custom-text="yearAndMateria"-->
              <!--                            :list="materiasNoAgregadas"-->
              <!--                            v-model="correlativa"-->
              <!--                            placeholder="Elegir materia">-->

              <!--              </model-list-select>-->
              <!--              <select class="form-control" v-model="correlativa">-->
              <!--                <option v-for="materia in materiasNoAgregadas" :key="materia.id" :value="materia">{{materia.nombre}}</option>-->
              <!--              </select>-->
            </div>
            <div class="col-4">
              <button @click="addCorrelativas" class="btn btn-primary">
                Agregar
              </button>
            </div>
          </div>
          <div>
            <h5>Correlativas:</h5>

            <div v-for="correlativa in correlativas" :key="correlativa.id">
              <hr />
              <div class="d-flex justify-content-between">
                <span>{{ correlativa.searchTag }}</span>
                <button
                  @click="delCorrelativa(correlativa.id)"
                  class="btn btn-danger"
                >
                  X
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="$emit('close')"
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
          >
            Guardar
          </button>
          <button
            @click="$emit('close')"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import modelService from "@/services/model.service";

export default {
  name: "ABMCorrelatividad",
  components: {
    // ModelSelect,
    // ModelListSelect,
    Multiselect,
  },
  props: {
    tipo: Number,
    urlApi: String,
    materia_id: Number,
    materias: Array,
    // correlativas: Array,
  },
  data() {
    return {
      correlativasSelect: [],
      correlativa: {},
      correlativas: [],
      materia: {},
      materiasNoAgregadas: [],
    };
  },

  watch: {
    materia() {
      this.getMateriasNoAgregadas();
    },
  },
  mounted() {
    this.getMateria();
  },
  methods: {
    yearAndMateria(item) {
      return `${item.anio}° ${item.campo.codigo} ${item.nombre}`;
    },
    getMateriasNoAgregadas() {
      console.log("getMateriasNoAgregadas");
      this.materiasNoAgregadas = [];

      for (var i in this.materias) {
        var flag = false;
        for (var j in this.correlativas) {
          if (this.materias[i].id == this.correlativas[j].correlativa_id) {
            flag = true;
            console.log(this.materias[i].nombre);
          }
        }
        if (!flag) {
          this.materiasNoAgregadas.push(this.materias[i]);
        }
      }
    },
    getCorrelativas() {
      if (this.tipo == 0) {
        this.correlativas = this.materia.correlativasCursando;
      } else if (this.tipo == 1) {
        this.correlativas = this.materia.correlativasCursadaAprobada;
      } else if (this.tipo == 2) {
        this.correlativas = this.materia.correlativasAprobar;
      }

      return [];
    },

    addCorrelativas() {
      for (var i in this.correlativasSelect) {
        this.addCorrelativa(this.correlativasSelect[i].id);
      }
    },

    async addCorrelativa(id) {
      const correlativa = {
        u_curricular_id: this.materia.id,
        correlativa_id: id,
        tipo: self.tipo,
      };
      const { status } = await modelService.create(correlativa, "correlativas");
      if (status === 201) {
        this.getMateria();
      }
    },
    async delCorrelativa(id) {
      const { status } = await modelService.delete(id, "correlativas");
      if (status === 204) {
        this.getMateria();
      }
    },
    async getMateria() {
      const { status, data } = await modelService.get(
        {},
        "u-curricular-carreras/" + self.materia_id
      );
      if (status === 200) {
        this.materia = data;
        this.getCorrelativas();
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.modal {
  display: block;
}

.modal-dialog {
  min-height: 800px;
}
</style>
