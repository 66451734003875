<template>
  <div>
    <v-dialog persistent eager v-model="dialog" max-width="600">
      <user-form ref="form" :id="id" @close="cerrar">
        <template v-slot:password="{ model }">
          <v-text-field
            outlined
            v-model="model.password"
            label="Ingrese una contraseña"
            type="password"
          ></v-text-field>
        </template>
      </user-form>
    </v-dialog>
    <v-card>
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align="left">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </v-col>
          <div class="mx-3">
            <v-switch
              v-model="sinCarrera"
              class="mt-0"
              dense
              hide-details
              label="Sin Carrera"
            ></v-switch>
          </div>
          <div class="d-flex mx-2">
            <v-select
              label="Ejecutar en todos"
              dense
              outlined
              hide-details
              :items="acciones"
              v-model="accion"
            ></v-select>
            <v-btn color="primary" @click="ejecutarAccion">Ejecutar</v-btn>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="usuarios"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <template v-slot:roles="{}">
                <div>
                  <v-select
                    clearable
                    dense
                    hide-details
                    @change="reload"
                    v-model="rolesModel"
                    multiple
                    label="Ingrese rol"
                    outlined
                    :items="roles"
                  ></v-select>
                </div>
              </template>
              <template v-slot:carrera.codigo="{}">
                <v-autocomplete
                  clearable
                  dense
                  hide-details
                  multiple
                  v-model="options.carrera"
                  label="Ingrese Carrera"
                  outlined
                  :items="carreras"
                  item-value="id"
                  item-text="nombre"
                ></v-autocomplete>
              </template>
            </filter-row>
          </template>

          <!--          <template v-slot:body.prepend>-->
          <!--            <tr>-->
          <!--              <td></td>-->
          <!--              <td v-for="(filterCol, i) in headers" :key="i">-->
          <!--                <v-text-field-->
          <!--                    v-show="filterCol.filterable"-->
          <!--                    hide-details-->
          <!--                    filled-->
          <!--                    :label="'Buscar por ' + filterCol.text"-->
          <!--                    v-model="-->
          <!--                    like[filterCol.table ? filterCol.table : filterCol.value]-->
          <!--                  "-->
          <!--                    clearable-->
          <!--                    @click:clear="reload"-->
          <!--                ></v-text-field>-->
          <!--              </td>-->
          <!--            </tr>-->
          <!--          </template>-->

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.carrerasInscripto="{ item }">
            <div v-for="carrera in item.carrerasInscripto" :key="carrera.id">
              {{ carrera.codigo }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import UserForm from "@/viewsNew/user/UserForm";
import FilterRow from "@/viewsNew/utils/FilterRow.vue";

export default {
  name: "AllUserABM",
  components: { FilterRow, UserForm },
  data() {
    return {
      selectedItems: [],
      accion: {},
      acciones: [{ value: 3, text: "Eliminar Seleccionados" }],
      sinCarrera: false,
      rolesModel: [],
      like: {},
      dialog: false,
      id: null,
      options: {},
      expand: ["roles", "carrerasInscripto"],
    };
  },
  computed: {
    ...mapState("NewUserModule", ["users", "pagination", "roles"]),
    ...mapState(["search", "filters", "loading", "dialogs"]),

    usuarios() {
      return this.users.filter((el) =>
        this.sinCarrera ? el.carrerasInscripto.length == 0 : el
      );
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Creado", value: "creado", filterable: true },
        { text: "Nombre", value: "nombre", filterable: true },
        { text: "Apellido", value: "apellido", filterable: true },
        { text: "DNI", value: "dni", filterable: true },
        { text: "Teléfono", value: "telefono", filterable: true },
        { text: "email", value: "email", filterable: true },
        {
          text: "Carreras",
          value: "carrerasInscripto",
          nosearch: true,
          filterable: true,
        },
        { text: "Roles", value: "roles", nosearch: true, filterable: true },
        { text: "Acciones", value: "actions", nosearch: true },
      ];
    },
  },
  watch: {
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", [
      "getUsers",
      "deleteUser",
      "getUsersPorRoles",
      "deleteAll",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    ejecutarAccion() {
      switch (this.accion) {
        case 3:
          this.borrarSeleccionados();
          break;
        default:
          return;
      }
      // this.selectedItems = [];
      // this.action = null;
    },

    async borrarSeleccionados() {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar todos los registros?",
        title: "Eliminar Registro",
      });
      if (res) {
        await this.deleteAll(this.selectedItems);
        this.reload();
        this.selectedItems = [];
        this.accion = null;
      }
    },

    newItem() {
      this.dialog = true;
    },

    cerrar() {
      this.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.dialog = true;
    },
    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteUser(id);
      }
    },

    async reload() {
      this.loading.status = true;
      await this.getUsersPorRoles({
        roles: this.rolesModel,
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },
  },
  destroyed() {
    this.clearFilters();
  },
};
</script>

<style scoped></style>
