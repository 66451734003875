var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',[_vm._v("Promocionados")])])]),_c('v-card-text',[_c('v-row',[_c('v-btn',{attrs:{"link":""}},[_vm._v("cargar promocionados")])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar por campo","clearable":""},on:{"click:clear":_vm.reload},model:{value:(_vm.search.tag),callback:function ($$v) {_vm.$set(_vm.search, "tag", $$v)},expression:"search.tag"}})],1)],1),_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': [10, 20, 50, 100, 200, 500],
        },"show-select":"","loading":_vm.loading.status,"items":_vm.alumnoCursados,"headers":_vm.headers,"server-items-length":_vm.pagination.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('filter-row',{ref:"filterRow",attrs:{"headers":_vm.headers},on:{"reload":_vm.reload},scopedSlots:_vm._u([{key:"u_curricular_carrera.nombre",fn:function(ref){return [_c('v-autocomplete',{attrs:{"clearable":"","hide-details":"","outlined":"","dense":"","multiple":"","items":_vm.uCurricularCarreras,"item-value":"id","item-text":"searchTag"},model:{value:(_vm.filters['cursado.espacio_id']),callback:function ($$v) {_vm.$set(_vm.filters, 'cursado.espacio_id', $$v)},expression:"filters['cursado.espacio_id']"}})]}}])})]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]),_c('whatsapp-button',{attrs:{"telefono":item.alumno.telefono}})]}},{key:"item.finales",fn:function(ref){
        var item = ref.item;
return _vm._l((item.finales),function(final){return _c('div',{key:final.id},[_c('span',[_vm._v(_vm._s(final.examen.turno))]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(final.examen.fechaFormateada))])],1)})}},{key:"item.u_curricular_carrera.nombre",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cursado.espacio.searchTag)+" ")]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }