<template>
  <div>
    <h5>Docentes</h5>
    <v-row>
      <v-col>
        <v-select
          @focus="search = ''"
          multiple
          :items="docentes"
          item-text="searchTag"
          return-object
          label="Seleccione Docente"
          v-model="docenteSelected"
        >
          <template v-slot:prepend-item>
            <div class="pl-3 pr-3">
              <v-text-field autofocus v-model="search" label="buscar docente" />
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-btn outlined color="primary" @click="agregarDocentes" class="ml-2"
          >Agregar</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="uCurricularCarrera.docentes">
      <template v-slot:item.actions="{ index }">
        <v-icon color="info" @click="deleteItem(index)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AgregarDocenteEspacio",
  data() {
    return {
      docenteSelected: [],
      filters: {},
      search: "",
      awaitingSearch: null,
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "Email", value: "email" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState("UCurricularCarreraModule", ["uCurricularCarrera"]),
    ...mapState("UserModule", ["docentes"]),
  },
  watch: {
    search: function () {
      this.filters.search = this.search;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getDocentes({ filters: this.filters });
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    ...mapActions("UserModule", ["getDocentes"]),
    deleteItem(i) {
      console.log(i);
      this.uCurricularCarrera.docentes.splice(i, 1);
    },
    traerDocentes() {},
    agregarDocentes() {
      if (!this.uCurricularCarrera.docentes) {
        this.uCurricularCarrera.docentes = [];
      }
      this.uCurricularCarrera.docentes = [
        ...this.uCurricularCarrera.docentes,
        ...this.docenteSelected,
      ];
      this.docenteSelected = [];
      this.search = "";
    },
  },
  created() {
    this.getDocentes({});
  },
};
</script>

<style scoped></style>
{
