<template>
  <v-dialog v-model="dialog" max-width="600" scrollable>
    <v-card>
      <!--      <v-alert v-if="record.actualizado" dark color="warning">Registro Actualizado</v-alert>-->
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title></v-card-title>
      <v-card-text>
        <v-form @submit.prevent="crear">
          <v-text-field
            label="Ciclo Lectivo"
            hint="Ej. 2019"
            v-model="form.anio_lectivo"
          ></v-text-field>
          <v-select
            clearable
            v-model="form.carrera_id"
            label="Ingrese carrera"
            :items="carreras"
            item-text="nombre"
            item-value="id"
          ></v-select>
          <v-select
            v-model="form.cuatrimestre"
            label="Ingrese Cuatrimestre"
            multiple
            :items="cuatrimestres"
          ></v-select>
          <v-text-field
            label="Ingrese inicio de cursada"
            type="date"
            class=""
            v-model="form.fecha_inicio"
          />
          <v-text-field
            label="Ingrese finalización de cursada"
            type="date"
            class=""
            v-model="form.fecha_fin"
          />

          <v-text-field
            label="Ingrese inicio de inscripción"
            type="date"
            class=""
            v-model="form.inicio_inscripcion"
          />
          <v-text-field
            label="Ingrese fin de inscripción"
            type="date"
            class=""
            v-model="form.fin_inscripcion"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="crear">Crear</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "CicloLectivoForm",
  data() {
    return {
      form: {},
      dialog: false,
      cuatrimestres: [
        { value: 0, text: "anual" },
        { value: 1, text: "primero" },
        { value: 2, text: "segundo" },
      ],
    };
  },
  computed: {
    ...mapGetters("NewCursadoModule", ["errors"]),
    ...mapState("NewCursadoModule", ["errors", "record"]),
    ...mapState("CarreraModule", ["carreras"]),
  },
  methods: {
    ...mapActions("NewCursadoModule", ["crearCicloLectivo"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    crear() {
      this.crearCicloLectivo(this.form);
      this.$emit("close");
    },
  },
  created() {
    this.getCarreras();
  },
};
</script>

<style scoped></style>
