<template>
  <v-container fluid>
    <slot name="dialog">
      <my-modal :eager="false" :dialog="dialog" @close="close">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template slot="header"><h3>Carreras</h3></template>
        <template slot="body">
          <v-form @submit.prevent="onSubmit">
            <slot name="other-form">
              <carrera-form :only="only"> </carrera-form>
            </slot>
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-form>
        </template>
        <template v-slot:footer-btn>
          <v-btn color="blue darken-1" text @click="onSubmit"> Guardar </v-btn>
        </template>
      </my-modal>
    </slot>
    <h3>Carreras</h3>

    <v-row no-gutters>
      <div>
        <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>
      </div>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="carreras"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  // mapGetters,
  mapActions,
  mapState,
} from "vuex";
import CarreraForm from "@/views/carrera/CarreraForm";
import MyModal from "@/components/templates/MyModal";

export default {
  name: "CarreraABM",
  components: {
    MyModal,
    CarreraForm,
  },
  props: {
    uploadPath: { type: String, default: "carreras" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("CarreraModule", [
      "getCarreras",
      "getFields",
      "addCarrera",
      "updateCarrera",
      "deleteCarrera",
      "editCarrera",
      "showDialog",
      "hideDialog",
    ]),
    close() {
      this.hideDialog();
    },
    onSubmit() {
      // this.addCarrera(this.carrera);
      this.carrera.id
        ? this.updateCarrera(this.carrera)
        : this.addCarrera(this.carrera);
    },
    newItem() {
      // this.fetchCarrera();
      this.editCarrera({});
      this.showDialog();
    },
    editItem(item) {
      this.editCarrera(item);
      this.showDialog();
      // this.showCarreraDialog();
    },
    deleteItem(id) {
      this.deleteCarrera(id);
    },
  },
  computed: {
    ...mapState("CarreraModule", ["carreras", "dialog", "carrera", "fields"]),
    // ...mapGetters("CarreraModule",["carreraList","carreraDialog","newCarreraRecord","carrera","carreraFields","carreraErrors"]),
    // ...mapGetters("CarreraModule",["errors"]),
  },
  created() {
    this.getCarreras();
    this.getFields();
  },
};
</script>

<style scoped></style>
