<template>
  <v-card>
    <v-card-title>
      <div class="w-100">
        <v-btn link icon absolute top right @click="$emit('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-alert class="w-100" dark v-if="nuevo" color="success"
          >Turno Creado</v-alert
        >
        Turno
      </div>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          outlined
          label="Ingrese Descripción Turno"
          v-model="turno.nombre"
        ></v-text-field>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateFormatted"
              placeholder="Ingrese Fecha"
              hint="DD/MM/YYYY format"
              persistent-hint
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>

        <!--        <v-menu-->
        <!--          ref="menu"-->
        <!--          v-model="menu2"-->
        <!--          :close-on-content-click="false"-->
        <!--          :nudge-right="40"-->
        <!--          :return-value.sync="time"-->
        <!--          transition="scale-transition"-->
        <!--          offset-y-->
        <!--          max-width="290px"-->
        <!--          min-width="290px"-->
        <!--        >-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-text-field-->
        <!--              outlined-->
        <!--              v-model="time"-->
        <!--              placeholder="Ingrese hora"-->
        <!--              prepend-inner-icon="mdi-clock-time-four-outline"-->
        <!--              readonly-->
        <!--              v-bind="attrs"-->
        <!--              v-on="on"-->
        <!--            ></v-text-field>-->
        <!--          </template>-->
        <!--          <v-time-picker-->
        <!--            v-if="menu2"-->
        <!--            v-model="time"-->
        <!--            full-width-->
        <!--            @click:minute="$refs.menu.save(time)"-->
        <!--          ></v-time-picker>-->
        <!--        </v-menu>-->
        <v-text-field outlined type="time" v-model="time"></v-text-field>
      </v-form>

      <turno-a-b-m>
        <template v-slot:form> </template>
        <template v-slot:buttons> </template>
      </turno-a-b-m>
    </v-card-text>
    <v-card-actions>
      <div class="w-100 d-flex justify-content-between">
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <div>
          <v-btn @click="$emit('close')" color="dark">Cerrar</v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TurnoABM from "@/viewsNew/turno/TurnoABM";

export default {
  name: "TurnoForm",
  components: { TurnoABM },
  data(vm) {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      menu2: false,
      time: null,
      turno: {},
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    ...mapState("TurnoModule", ["nuevo", "actualizado"]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    ...mapActions("TurnoModule", ["addTurno"]),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    guardar() {
      this.turno.fecha = this.date;
      this.turno.hora = this.time;
      this.addTurno(this.turno);
    },
  },
};
</script>

<style scoped></style>
