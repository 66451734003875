<template>
  <v-dialog persistent v-model="dialog" max-width="600">
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        AlumnoCursados
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <slot name="cursado_id" :model="model">
            <v-autocomplete
              :items="cursados"
              item-text="espacio.searchTag"
              item-value="id"
              v-model="model.cursado_id"
              label="Seleccione Unidad Curricular"
            >
              <template v-slot:item="{ item }">
                {{ item.espacio.searchTag }} (COMISION:{{ item.comision }})
                HORARIO:{{ item.horariosFormateados.corto }}
              </template>
            </v-autocomplete>
          </slot>
          <slot name="alumno_id" :model="model">
            <v-text-field
              v-model="model.alumno_id"
              label="Ingrese Estudiante"
            ></v-text-field>
          </slot>

          <!--          <div v-for="(label, field) in filteredFields" :key="field">-->
          <!--            <slot :name="field" :model="model">-->
          <!--              <v-text-field-->
          <!--                outlined-->
          <!--                v-model="model[field]"-->
          <!--                :label="label"-->
          <!--                :error-messages="errors[field]"-->
          <!--              ></v-text-field>-->
          <!--            </slot>-->
          <!--          </div>-->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "AlumnoCursadoForm",
  props: {
    id: [String, Number],
    carreras: [],
    // alumno_id:[String,Number],
  },
  data() {
    return {
      dialog: false,
      only: ["alumno_id", "cursado_id"],
      exclude: [],
      model: {},
    };
  },
  computed: {
    ...mapState("UserModule", ["user"]),
    ...mapState("NewCursadoModule", ["cursados"]),
    ...mapGetters("NewAlumnoCursadoModule", ["errors"]),
    ...mapState("NewAlumnoCursadoModule", [
      "fields",
      "alumnoCursado",
      "record",
    ]),

    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewCursadoModule", ["getCursadosActivos", "getCursados"]),
    ...mapActions("NewAlumnoCursadoModule", [
      "getFields",
      "getAlumnoCursado",
      "updateAlumnoCursado",
      "addAlumnoCursado",
    ]),
    async getModel() {
      await this.getAlumnoCursado(this.id);
      this.model = this.alumnoCursado;
    },
    async guardar() {
      this.model.id
        ? await this.updateAlumnoCursado(this.model)
        : await this.addAlumnoCursado(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
    reloadCursadas() {
      this.getCursados({
        expand: ["espacio"].toString(),
        filters: { estado: 1, carrera_id: this.carreras },
        options: {
          itemsPerPage: -1,
          joins: ["espacio"],
        },
      });
    },
  },
  watch: {
    // alumno_id(){
    //   this.model.alumno_id = this.alumno_id;
    // },
    dialog() {
      if (this.dialog) {
        this.reloadCursadas();
      }
    },
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  async created() {
    this.getFields();
    if (this.id) {
      this.getModel();
    }
  },
};
</script>

<style scoped></style>
